import React, { createContext, useContext, useState, useEffect } from 'react';
import api from './api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [organisations, setOrganisations] = useState([]);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [suborganisations, setSuborganisations] = useState([]);
    const [spreadsheets, setSpreadsheets] = useState([]);
    const [uploadedData, setUploadedData] = useState({ headers: [], rows: [] });
    const [currentSpreadsheet, setCurrentSpreadsheet] = useState(null);
    const [editedRows, setEditedRows] = useState([]);
    const [models, setModels] = useState([]);
    const [supermodels, setSupermodels] = useState([]);
    const [currentModel, setCurrentModel] = useState(null);
    const [pivottables, setPivottables] = useState([]);
    const [activityLogs, setActivityLogs] = useState([]);
    const [expandedFolders, setExpandedFolders] = useState({});
    const [expandedSuperModels, setExpandedSuperModels] = useState({});
    const [user, setUser] = useState({});

    // Custom setter for currentModel with logging
    const updateCurrentModel = (model) => {
        console.log('Updating current model to:', model);
        setCurrentModel(model);
    };

    // Helper function to safely parse JSON
    const safeParseJSON = (value, fallback) => {
        try {
            return JSON.parse(value);
        } catch (e) {
            console.warn(`Error parsing JSON: ${value}, falling back to ${fallback}`);
            return fallback;
        }
    };

    // Load currentOrg, currentModel, expandedFolders, and expandedSuperModels from localStorage on mount
    useEffect(() => {
        const storedCurrentOrg = localStorage.getItem('currentOrg');
        if (storedCurrentOrg) {
            const parsedOrg = JSON.parse(storedCurrentOrg);
            setCurrentOrg(parsedOrg);
        }

        const storedCurrentModel = localStorage.getItem('currentModel');
        if (storedCurrentModel) {
            const parsedModel = JSON.parse(storedCurrentModel);
            setCurrentModel(parsedModel);
        }

        const storedExpandedFolders = localStorage.getItem('expandedFolders');
        if (storedExpandedFolders) {
            setExpandedFolders(JSON.parse(storedExpandedFolders));
        }

        const storedExpandedSuperModels = localStorage.getItem('expandedSuperModels');
        if (storedExpandedSuperModels) {
            setExpandedSuperModels(JSON.parse(storedExpandedSuperModels));
        }
    }, []);

    // Save currentOrg, currentModel, expandedFolders, and expandedSuperModels to localStorage whenever they change
    useEffect(() => {
        if (currentOrg) {
            localStorage.setItem('currentOrg', JSON.stringify(currentOrg));
        } else {
            localStorage.removeItem('currentOrg');
        }
    }, [currentOrg]);

    useEffect(() => {
        if (currentModel) {
            localStorage.setItem('currentModel', JSON.stringify(currentModel));
        } else {
            localStorage.removeItem('currentModel');
        }
    }, [currentModel]);

    useEffect(() => {
        localStorage.setItem('expandedFolders', JSON.stringify(expandedFolders));
    }, [expandedFolders]);

    useEffect(() => {
        localStorage.setItem('expandedSuperModels', JSON.stringify(expandedSuperModels));
    }, [expandedSuperModels]);

    // Consolidated Data Fetching
    useEffect(() => {
        const fetchAndInitialize = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoggedIn(false);
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            try {
                const response = await api.get('/fetchuserdata', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                
                const fetchedUser = response.data.user;
                setUser(fetchedUser); // Store all user fields

                const fetchedSpreadsheets = response.data.spreadsheets.map(sheet => ({
                    ...sheet,
                    originalHeaders: Array.isArray(sheet.originalHeaders) ? sheet.originalHeaders : JSON.parse(sheet.originalHeaders || '[]'),
                    editedHeaders: Array.isArray(sheet.editedHeaders) ? sheet.editedHeaders : JSON.parse(sheet.editedHeaders || '[]'),
                    columnStatus: Array.isArray(sheet.columnStatus) ? sheet.columnStatus : JSON.parse(sheet.columnStatus || '[]'),
                    customColumns: Array.isArray(sheet.customColumns) ? sheet.customColumns : JSON.parse(sheet.customColumns || '[]')
                }));

                const fetchedModels = response.data.models.map(model => ({
                    ...model,
                    rowNames: JSON.parse(model.rowNames || '[]'),
                    rowVariables: JSON.parse(model.rowVariables || '[]'),
                    categories: JSON.parse(model.categories || '[]'),
                    groupedOver: JSON.parse(model.groupedOver || '[]'),
                    dateColumn: JSON.parse(model.dateColumn || '[]'),
                    sourceFile: JSON.parse(model.sourceFile || '[]'),
                    drivers: JSON.parse(model.drivers || '[]'),
                    assumptions: JSON.parse(model.assumptions || '[]')
                }));

                const fetchedSuperModels = response.data.supermodels.map(superModel => ({
                    ...superModel,
                    // Further parsing if needed
                }));

                const fetchedPivotTables = response.data.pivottables.map(pivot => ({
                    ...pivot,
                }));

                console.log('fetched pivot tables:', fetchedPivotTables)

                setOrganisations(response.data.organisations);
                setSuborganisations(response.data.suborganisations);
                setSpreadsheets(fetchedSpreadsheets);
                setEditedRows(response.data.edited_rows);
                setModels(fetchedModels);
                setSupermodels(fetchedSuperModels);
                setPivottables(fetchedPivotTables);
                setActivityLogs(response.data.activity_logs);

                setIsLoggedIn(true);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIsLoggedIn(false);
                logout();
            } finally {
                setIsLoading(false);
            }
        };

        fetchAndInitialize();
    }, []);

    const login = async (token, refreshToken) => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        setIsLoading(true);

        try {
            const response = await api.get('/fetchuserdata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchedUser = response.data.user;
            setUser(fetchedUser); // Store all user fields

            const fetchedSpreadsheets = response.data.spreadsheets.map(sheet => ({
                ...sheet,
                originalHeaders: Array.isArray(sheet.originalHeaders) ? sheet.originalHeaders : JSON.parse(sheet.originalHeaders || '[]'),
                editedHeaders: Array.isArray(sheet.editedHeaders) ? sheet.editedHeaders : JSON.parse(sheet.editedHeaders || '[]'),
                columnStatus: Array.isArray(sheet.columnStatus) ? sheet.columnStatus : JSON.parse(sheet.columnStatus || '[]'),
                customColumns: Array.isArray(sheet.customColumns) ? sheet.customColumns : JSON.parse(sheet.customColumns || '[]')
            }));

            const fetchedModels = response.data.models.map(model => ({
                ...model,
                rowNames: JSON.parse(model.rowNames || '[]'),
                rowVariables: JSON.parse(model.rowVariables || '[]'),
                categories: JSON.parse(model.categories || '[]'),
                groupedOver: JSON.parse(model.groupedOver || '[]'),
                dateColumn: JSON.parse(model.dateColumn || '[]'),
                sourceFile: JSON.parse(model.sourceFile || '[]'),
                drivers: JSON.parse(model.drivers || '[]'),
                assumptions: JSON.parse(model.assumptions || '[]')
            }));

            const fetchedSuperModels = response.data.supermodels.map(superModel => ({
                ...superModel,
                // Further parsing if needed
            }));

            const fetchedPivotTables = response.data.pivottables.map(pivot => ({
                ...pivot,
                selectedFields: safeParseJSON(pivot.selectedFields, []),
                aggregatedData: safeParseJSON(pivot.aggregatedData, {}),
                uniquePeriods: safeParseJSON(pivot.uniquePeriods, []),
                restrictedData: safeParseJSON(pivot.restrictedData, {}),
            
                // Parsing the historical and forecast data fields
                annualHistData: safeParseJSON(pivot.annualHistData, []),
                quarterlyHistData: safeParseJSON(pivot.quarterlyHistData, []),
                monthlyHistData: safeParseJSON(pivot.monthlyHistData, []),
                annualForeData: safeParseJSON(pivot.annualForeData, []),
                quarterlyForeData: safeParseJSON(pivot.quarterlyForeData, []),
                monthlyForeData: safeParseJSON(pivot.monthlyForeData, [])
            }));

            setOrganisations(response.data.organisations);
            setSuborganisations(response.data.suborganisations);
            setSpreadsheets(fetchedSpreadsheets);
            setEditedRows(response.data.edited_rows);
            setModels(fetchedModels);
            setSupermodels(fetchedSuperModels);
            setPivottables(fetchedPivotTables);
            setActivityLogs(response.data.activity_logs);

            setIsLoggedIn(true);
        } catch (error) {
            console.error('Error fetching user data on login:', error);
            setIsLoggedIn(false);
        } finally {
            setIsLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('currentOrg');
        localStorage.removeItem('currentModel');
        localStorage.removeItem('expandedFolders');
        localStorage.removeItem('expandedSuperModels');
        setIsLoggedIn(false);
        setOrganisations([]);
        setCurrentOrg(null);
        setSuborganisations([]);
        setSpreadsheets([]);
        setUploadedData({ headers: [], rows: [] });
        setEditedRows([]);
        setModels([]);
        setSupermodels([]);
        setCurrentModel(null);
        setPivottables([]);
        setActivityLogs([]);
        setUser({});
        setExpandedFolders({});
        setExpandedSuperModels({});
    };

    return (
        <AuthContext.Provider value={{
            isLoggedIn,
            isLoading,
            organisations,
            setOrganisations,
            currentOrg,
            setCurrentOrg,
            suborganisations,
            spreadsheets,
            setUploadedData,
            uploadedData,
            currentSpreadsheet,
            setCurrentSpreadsheet,
            editedRows,
            models,
            setModels,
            supermodels,
            setSupermodels,
            currentModel,
            setCurrentModel: updateCurrentModel,
            activityLogs,
            expandedFolders,
            setExpandedFolders,
            pivottables, 
            setPivottables,
            expandedSuperModels,
            setExpandedSuperModels,
            login,
            logout,
            user,
            setUser
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
