// src/components/ForecastingSidebar.js

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../authcontext';

// Define excluded model types
const EXCLUDED_MODEL_TYPES = ['Formula Build', 'Custom Driver'];

function ForecastingSidebar() {
  const { currentOrg, supermodels, setCurrentModel, models } = useAuth();
  const [expandedFolders, setExpandedFolders] = useState({});
  const [expandedSuperModels, setExpandedSuperModels] = useState({});
  const [expandedModels, setExpandedModels] = useState({}); // Track expanded models with parent info

  // Filter supermodels belonging to the current organisation
  const orgSupermodels =
    currentOrg && Array.isArray(supermodels) && supermodels.length > 0
      ? supermodels.filter(
          (supermodel) => supermodel.organisationId === currentOrg.id
        )
      : [];

  // Filter models belonging to the current organisation
  const orgModels =
    currentOrg && Array.isArray(models) && models.length > 0
      ? models.filter((model) => model.organisationId === currentOrg.id)
      : [];

  useEffect(() => {
    if (!Array.isArray(orgModels)) {
      console.error("Models is not an array or is undefined:", models);
    }
  }, [orgModels, models]);

  const toggleFolder = (folderName) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  const toggleSuperModel = (superModelId) => {
    setExpandedSuperModels((prev) => ({
      ...prev,
      [superModelId]: !prev[superModelId], // Toggle supermodel expansion
    }));
  };

  const toggleModel = (modelId, parentModelId) => {
    setExpandedModels((prev) => {
      const updatedModels = { ...prev };

      // Collapse all sibling models of the same supermodel, excluding the clicked model
      Object.keys(updatedModels).forEach((id) => {
        if (
          updatedModels[id]?.parentModelId === parentModelId &&
          id !== modelId.toString()
        ) {
          updatedModels[id] = { isExpanded: false, parentModelId };
        }
      });

      // Toggle the clicked model, but prevent collapsing the same model if it's already expanded
      updatedModels[modelId] = {
        isExpanded: prev[modelId]?.isExpanded ? prev[modelId].isExpanded : true,
        parentModelId,
      };

      return updatedModels;
    });
  };

  const handleModelClick = (model, parentModelId = null) => {
    setCurrentModel(model);

    // Only toggle the model without collapsing for P&L, BS, CF models
    if (
      ['Profit and Loss', 'Balance Sheet', 'Cash Flow'].includes(model.modelType)
    ) {
      toggleModel(model.id, parentModelId);
    } else {
      // For other models or supermodels, allow regular expansion/collapse
      toggleSuperModel(model.id);
    }
  };

  // Ensure the supportingAssumptionModelIds is properly parsed as an array and filter invalid values
  const parseSupportingIds = (supportingAssumptionModelIds) => {
    try {
      const parsedIds =
        typeof supportingAssumptionModelIds === 'string'
          ? JSON.parse(supportingAssumptionModelIds)
          : supportingAssumptionModelIds;
      return Array.isArray(parsedIds) ? parsedIds.filter((id) => id) : [];
    } catch (error) {
      console.error('Error parsing supportingAssumptionModelIds:', error);
      return [];
    }
  };

  // Recursive function to render nested supporting assumption models
  const renderSupportingModels = (model, level = 1, visited = new Set()) => {
    const supportingIds = parseSupportingIds(model.supportingAssumptionModelIds);

    if (!supportingIds.length) {
      return null; // No supporting models to render
    }

    console.log('supportingIds: ', supportingIds);
    console.log('level: ', level);

    // Add current model to the visited set to prevent circular references
    visited.add(model.id);

    return (
      <>
        {expandedModels[model.id]?.isExpanded && (
          <div className="sidebar-submenu">
            {supportingIds
              .map((supportingId) =>
                orgModels.find(
                  (supportingModel) => supportingModel.id === parseInt(supportingId)
                )
              )
              .filter(
                (supportingModel) =>
                  supportingModel &&
                  !EXCLUDED_MODEL_TYPES.includes(supportingModel.modelType) &&
                  !visited.has(supportingModel.id) // Prevent circular references
              )
              .map((supportingModel) => (
                <div key={supportingModel.id}>
                  <NavLink
                    to={`/forecasting/${supportingModel.id}/sa`}
                    className="sidebar-button indent" // Added 'indent' class
                    style={{ '--indent-level': level + 1 }} // Set CSS variable
                    onClick={() => handleModelClick(supportingModel, model.id)}
                  >
                    {supportingModel.modelName}
                  </NavLink>

                  {/* Recursively render supporting assumptions of supporting assumptions */}
                  {renderSupportingModels(
                    supportingModel,
                    level + 1,
                    new Set(visited)
                  )}
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  // Grouping supermodels by folder
  const groupedSupermodels = orgSupermodels.reduce((acc, supermodel) => {
    const folder = supermodel.modelFolder || 'Uncategorized'; // Handle models without a folder
    if (!acc[folder]) {
      acc[folder] = [];
    }
    acc[folder].push(supermodel);
    return acc;
  }, {});

  return (
    <div className="sidebar forecasting-sidebar"> {/* Added 'forecasting-sidebar' class */}
      <NavLink to="/forecastinglanding" className="sidebar-button">
        Base Case Modelling
      </NavLink>
      {Object.entries(groupedSupermodels).map(([folderName, superModels]) => (
        <div key={folderName}>
          <button onClick={() => toggleFolder(folderName)} className="sidebar-button">
            {folderName}
          </button>
          {expandedFolders[folderName] && (
            <div className="sidebar-submenu">
              {superModels.map((superModel) => (
                <div key={superModel.id}>
                  <button onClick={() => toggleSuperModel(superModel.id)} className="sidebar-button">
                    {superModel.superModelName}
                  </button>
                  {expandedSuperModels[superModel.id] && (
                    <div className="sidebar-submenu">
                      {orgModels
                        .filter(
                          (m) =>
                            m.superModelId === superModel.id &&
                            ['Profit and Loss', 'Balance Sheet', 'Cash Flow'].includes(m.modelType) &&
                            !EXCLUDED_MODEL_TYPES.includes(m.modelType) // Exclude specific model types
                        )
                        .map((model) => (
                          <div key={model.id}>
                            {/* Primary models (P&L, BS, CF) */}
                            <NavLink
                              to={`/forecasting/${model.id}/${
                                model.modelType === 'Profit and Loss'
                                  ? 'pl'
                                  : model.modelType === 'Balance Sheet'
                                  ? 'bs'
                                  : model.modelType === 'Cash Flow'
                                  ? 'cf'
                                  : ''
                              }`}
                              className="sidebar-button"
                              style={{ paddingLeft: '20px' }} // Base indentation for primary models
                              onClick={() => handleModelClick(model)}
                            >
                              {model.modelType === 'Profit and Loss'
                                ? 'Profit & Loss'
                                : model.modelType === 'Balance Sheet'
                                ? 'Balance Sheet'
                                : model.modelType === 'Cash Flow'
                                ? 'Cash Flow'
                                : model.modelName}
                            </NavLink>

                            {/* Supporting Assumptions */}
                            {renderSupportingModels(model)}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <div className="sidebar-spacer"></div> {/* Spacer added at the bottom */}
    </div>
  );
}

export default ForecastingSidebar;
