import { useEffect } from 'react';

// Helper to remove leading '=' if present
const removeLeadingEquals = (formula) => {
    if (formula.startsWith('=')) {
      return { formula: formula.slice(1).trim(), hasLeadingEquals: true };
    }
    return { formula, hasLeadingEquals: false };
};

// Helper to convert all double quotes to single quotes
const convertToSingleQuotes = (formula) => {
  return formula.replace(/"/g, "'");
};

// Helper to remove whitespace outside quotes
const removeWhitespaceOutsideQuotes = (formula) => {
    let result = '';
    let insideQuotes = false;
  
    for (let i = 0; i < formula.length; i++) {
      const char = formula[i];
  
      // Toggle inside/outside quotes state
      if (char === "'" || char === '"') {
        insideQuotes = !insideQuotes;
        result += char;
        continue;
      }
  
      // Only add characters if they are non-whitespace, or if we're inside quotes
      if (insideQuotes || !/\s/.test(char)) {
        result += char;
      }
    }
  
    return result;
  };

// Helper to check if quotes are balanced and ensure no adjacent quotes
const checkQuotes = (formula) => {
    const totalQuoteCount = (formula.match(/['"]/g) || []).length;

    // Check if the total number of quotes (both ' and ") is odd
    if (totalQuoteCount % 2 !== 0) {
        throw new Error("Error: Unmatched quotes in the formula.");
    }

    let insideQuotes = false;

    // Ensure no two quotes are adjacent when outside quotes
    for (let i = 0; i < formula.length - 1; i++) {
        const currentChar = formula[i];
        const nextChar = formula[i + 1];

        // Toggle inside/outside quotes
        if (currentChar === "'" || currentChar === '"') {
            insideQuotes = !insideQuotes;
        }

        // Check for adjacent quotes of either type, but only when outside quotes
        if (!insideQuotes && (currentChar === "'" || currentChar === '"') && (nextChar === "'" || nextChar === '"')) {
            throw new Error("Error: Adjacent quotes are not allowed outside of quotes.");
        }
    }
};

// Helper to validate characters outside of quotes
const validateCharactersOutsideQuotes = (formula) => {
  const validCharsPattern = /^[a-zA-Z0-9+\-*/^(),.<>=\s]*$/;

  let insideQuotes = false;

  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];

    // Handle quotes (toggle inside/outside quote mode)
    if (char === "'" || char === '"') {
      insideQuotes = !insideQuotes;
      continue;
    }

    // Validate characters outside quotes
    if (!insideQuotes && !validCharsPattern.test(char)) {
      throw new Error(`Error: Invalid character '${char}' found outside of quotes.`);
    }
  }
};

// Helper to validate column names and quoted values in formula
const validateColumnNamesAndQuotedValues = (formula, columnData = []) => {
  if (!Array.isArray(columnData) || columnData.length === 0) {
    throw new Error("Error: No column data provided for validation.");
  }

  const columnPattern = /['"]([^'"]+)['"]/g;
  let match;
  const columnsInFormula = [];

  // Find all quoted values in the formula
  while ((match = columnPattern.exec(formula)) !== null) {
    columnsInFormula.push(match[1]); // The value inside quotes
  }

  // Process each quoted value found in the formula
  const invalidColumns = columnsInFormula.filter(col => !columnData.map(colData => colData.name).includes(col));
  if (invalidColumns.length > 0) {
    invalidColumns.forEach(invalidValue => {
      // Create a regex pattern to detect valid column name + = + value pattern
      const valuePattern = new RegExp(`['"]([^'"]+)['"]\\s*=\\s*['"]${invalidValue}['"]`);
      const match = formula.match(valuePattern);

      if (match) {
        // Ensure that the column before = is of type Categorical, CategoricalEditable, or Date
        const precedingColumnName = match[1]; // Extract the valid column name before =
        const precedingColumn = columnData.find(col => col.name === precedingColumnName);

        if (!precedingColumn) {
          throw new Error(`Error: Column '${precedingColumnName}' not found in column data.`);
        }

        if (!['Categorical', 'CategoricalEditable', 'PrimaryDate', 'SecondaryDate'].includes(precedingColumn.status)) {
          throw new Error(`Error: Invalid use of a non-column name: '${invalidValue}'.`);
        }

        // If it's a Date column, validate the value format and ensure it's a real date
        if (precedingColumn.status === 'PrimaryDate' || precedingColumn.status === 'SecondaryDate') {
          const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/; // DD/MM/YYYY format
          const dateMatch = invalidValue.match(dateRegex);

          if (!dateMatch) {
            throw new Error(`Error: Invalid date format. Expected DD/MM/YYYY, but found '${invalidValue}'.`);
          }

          // Check if the date is valid using the Date object
          const [_, day, month, year] = dateMatch;
          const date = new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format for Date constructor

          // Check that the parsed date matches the input values (e.g., 31/02/2022 should fail)
          if (date.getDate() !== parseInt(day, 10) || 
              date.getMonth() + 1 !== parseInt(month, 10) || 
              date.getFullYear() !== parseInt(year, 10)) {
            throw new Error(`Error: Invalid date. '${invalidValue}' is not a valid date.`);
          }
        }
      } else {
        // If the invalid value is not preceded by a valid column and `=`, throw an error
        throw new Error(`Error: Invalid column name or value inside quotes: '${invalidValue}'.`);
      }
    });
  }
};

// Helper to validate and fix brackets
const validateAndFixBrackets = (formula) => {
  let correctedFormula = '';
  let insideQuotes = false;
  let openBrackets = 0;

  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];

    // Handle quotes
    if (char === "'" || char === '"') {
      correctedFormula += char;
      insideQuotes = !insideQuotes;
      continue;
    }

    if (insideQuotes) {
      correctedFormula += char;
      continue;
    }

    if (char === '(') {
      openBrackets++;
    } else if (char === ')') {
      if (openBrackets > 0) {
        openBrackets--;
      } else {
        throw new Error("Error: Mismatched closing bracket found.");
      }
    }

    correctedFormula += char;
  }

  // Add missing closing brackets
  if (openBrackets > 0) {
    correctedFormula += ')'.repeat(openBrackets);
  }

  return correctedFormula;
};

// Helper to capitalize keywords
const capitalizeKeywords = (formula) => {
    let correctedFormula = '';
    let insideQuotes = false;
  
    let i = 0;
    while (i < formula.length) {
      const char = formula[i];
  
      // Handle quotes
      if (char === "'" || char === '"') {
        correctedFormula += char;
        insideQuotes = !insideQuotes;
        i++;
        continue;
      }
  
      if (insideQuotes) {
        correctedFormula += char;
        i++;
        continue;
      }
  
      if (/[a-zA-Z]/.test(char)) {
        let letterGroup = '';
        while (i < formula.length && /[a-zA-Z]/.test(formula[i])) {
          letterGroup += formula[i];
          i++;
        }
  
        // Allow AND, OR, IF, ANDIF, and ORIF
        const upperGroup = letterGroup.toUpperCase();
        if (['AND', 'OR', 'IF', 'ANDIF', 'ORIF'].includes(upperGroup)) {
          correctedFormula += upperGroup;
        } else {
          throw new Error(`Error: Invalid keyword '${letterGroup}' found in the formula.`);
        }
        continue;
      }
  
      correctedFormula += char;
      i++;
    }
  
    return correctedFormula;
  };  

// Helper to validate non-alphanumeric character combinations
const validateNonAlphanumericCombinations = (formula) => {
    let insideQuotes = false;
  
    for (let i = 0; i < formula.length; i++) {
      const char = formula[i];
      const nextChar = formula[i + 1] || '';
      const prevChar = formula[i - 1] || '';
  
      // Handle quotes (toggle inside/outside quote mode)
      if (char === "'" || char === '"') {
        insideQuotes = !insideQuotes;
        continue;
      }
  
      // Skip checking inside quotes
      if (insideQuotes) {
        continue;
      }

      // Disallow numbers or decimal points immediately before an opening quote or after a closing quote
      if ((/[0-9.)]/.test(char) && (nextChar === '"' || nextChar === "'")) ||  // Before opening quote
          ((/[0-9.]/.test(char)) && (prevChar === '"' || prevChar === "'"))) {      // Immediately after closing quote
          throw new Error(`Error: Invalid use of number, decimal point or bracket next to a quote.`);
      }
      

      if (nextChar === "'" || nextChar === '"') {
        continue; // Skip this combination check because we're reaching quotes
      }
  
      // Allowed pairs: <>, <=, >=
      if (
        (char === '<' && nextChar === '>') ||
        (char === '<' && nextChar === '=') ||
        (char === '>' && nextChar === '=') ||
        (char === '>' && nextChar === '-') ||
        (char === '<' && nextChar === '-') ||
        (char === '=' && nextChar === '-') ||
        (char === '*' && nextChar === '-') ||
        (char === '/' && nextChar === '-') ||
        (char === '^' && nextChar === '-') ||
        (char === ',' && nextChar === '-')
      ) {
        continue;
      }
  
      // Skip checking parentheses at this stage
      if (char === '(' || char === ')') {
        continue;
      }
  
      // Ensure decimal point is followed by a number
      if (char === '.' && !/[0-9]/.test(nextChar)) {
        throw new Error(`Error: Decimal point '.' must be followed by a number, but '${nextChar}' found.`);
      }
  
      // Check if two invalid non-alphanumeric characters are adjacent (excluding parentheses and decimal checks)
      if (/[^a-zA-Z0-9\s().]/.test(char) && /[^a-zA-Z0-9\s().]/.test(nextChar)) {
        throw new Error(`Error: Invalid combination '${char}${nextChar}' found.`);
      }
    }
};
  
// Helper to validate symbol placement rules, treating quotes like numbers or letters
const validateSymbolPlacement = (formula) => {
    let insideQuotes = false;
  
    // Remove the leading equals sign if it exists
    if (formula.startsWith('=')) {
      formula = formula.slice(1).trim();
    }
  
    for (let i = 0; i < formula.length; i++) {
      const char = formula[i];
      const nextChar = formula[i + 1] || '';
      const prevChar = formula[i - 1] || '';
  
      // Handle quotes (toggle inside/outside quote mode)
      if (char === "'" || char === '"') {
        insideQuotes = !insideQuotes;
        continue;
      }
  
      // Skip checking inside quotes
      if (insideQuotes) {
        continue;
      }
  
      // Rule 1: Open bracket placement
      if (char === '(') {
        // Disallow a number or decimal point immediately before an opening bracket
        if (/[0-9.]/.test(prevChar)) {
          throw new Error(`Error: Number or decimal point '${prevChar}' found before '('.`);
        }
        // It can only be followed by a number, letter, another open bracket, a decimal point, or a quote
        if (/[^a-zA-Z0-9.(\'\"]/.test(nextChar)) {
          throw new Error(`Error: Invalid symbol '${nextChar}' following '('.`);
        }
      }

      // Rule 2: Closed bracket placement
      if (char === ')') {
        // Disallow a number or decimal point immediately after a closing bracket
        if (/[0-9.]/.test(nextChar)) {
          throw new Error(`Error: Number or decimal point '${nextChar}' found after ')'.`);
        }
        // It cannot be preceded by a symbol (except numbers, letters, another closed bracket, or a quote)
        if (/[^a-zA-Z0-9)\'\"]/.test(prevChar)) {
          throw new Error(`Error: Invalid symbol '${prevChar}' before ')'.`);
        }
      }

  
      // Rule 3: Formula can't start with symbols (except open bracket '(' or quotes)
      if (i === 0 && /[^a-zA-Z0-9('"()]/.test(char)) {
        throw new Error(`Error: Formula cannot start with '${char}'.`);
      }
  
      // Rule 4: Formula can't end with symbols (except closed bracket ')' or quotes)
      if (i === formula.length - 1 && /[^a-zA-Z0-9'")]/.test(char)) {
        throw new Error(`Error: Formula cannot end with '${char}'.`);
      }
    }
};  

// Helper to find the matching closing bracket for an opening bracket
const findClosingBracket = (formula, startIdx) => {
    let balance = 1;
    for (let i = startIdx + 1; i < formula.length; i++) {
      if (formula[i] === '(') balance++;
      if (formula[i] === ')') balance--;
      if (balance === 0) return i; // Found the matching closing bracket
    }
    throw new Error("Error: Mismatched parentheses in IF statement.");
  };
  
  const validateIFFirstPart = (ifPart) => {
    const comparisonOperators = ['=', '<', '>', '<>', '<=', '>='];
    const logicalOperators = ['AND', 'OR'];
  
    let foundComparison = false;  // Flag to track if we've found a comparison operator
    let foundLogical = false;     // Flag to track if we find AND/OR
    let insideQuotes = false;
    
    let i = 0;
    while (i < ifPart.length) {
      const char = ifPart[i];
  
      // Handle quotes (toggle inside/outside quote mode)
      if (char === "'" || char === '"') {
        insideQuotes = !insideQuotes;
        i++;
        continue;
      }
  
      // Ignore content inside quotes
      if (insideQuotes) {
        i++;
        continue;
      }
  
      // Check for nested IF statement
      if (ifPart.slice(i, i + 2).toUpperCase() === 'IF') {
        const ifStartIdx = i;
        const closingIdx = findClosingBracket(ifPart, ifStartIdx + 2);
        const nestedIf = ifPart.slice(i, closingIdx + 1);
  
        // Recursively validate the nested IF statement
        validateIFParts(nestedIf);
  
        // Move the index past the nested IF statement
        i = closingIdx + 1;
        continue;
      }
  
      // Check for comparison operators (=, <, >, <=, >=, <>)
      const twoCharCombo = ifPart.slice(i, i + 2);
      if (comparisonOperators.includes(char) || comparisonOperators.includes(twoCharCombo)) {
        if (foundComparison) {
          throw new Error("Error: Two consecutive comparison operators found in the IF condition.");
        }
        foundComparison = true;  // Set flag for comparison found
  
        // If a two-character operator like <=, >=, <>, skip the next character
        if (twoCharCombo === '<=' || twoCharCombo === '>=' || twoCharCombo === '<>') {
          i += 2;
          continue;
        }
        i++;  // Move past single character operator (=, <, >)
        continue;
      }
  
      // Check for logical operators (AND/OR)
      const threeCharCombo = ifPart.slice(i, i + 3);
      if (threeCharCombo.toUpperCase() === 'AND' || twoCharCombo.toUpperCase() === 'OR') {
        if (!foundComparison) {
          throw new Error("Error: Logical operators AND/OR must follow a valid comparison.");
        }
  
        foundComparison = false;  // Reset comparison flag for the next comparison group
        foundLogical = true;      // Set logical operator found flag
  
        // Skip past AND/OR
        i += (threeCharCombo.toUpperCase() === 'AND') ? 3 : 2;
        continue;
      }
  
      // Ensure that logical operators are followed by another valid comparison
      if (foundLogical && (comparisonOperators.includes(char) || comparisonOperators.includes(twoCharCombo))) {
        foundComparison = true;
        foundLogical = false;
        i++;
        continue;
      }
  
      // Any invalid characters between value-comparison-value should throw an error
      if (/[<>=]/.test(char)) {
        throw new Error(`Error: Invalid character '${char}' found before completing a comparison group.`);
      }
  
      // Move to the next character
      i++;
    }
  
    // Ensure the first part ends on a comparison operator
    if (!foundComparison) {
      throw new Error("Error: The IF statement must end with a valid comparison, not a logical operator.");
    }
  };
  
// Recursive helper to validate the IF statement, including nested IFs
const validateIFParts = (ifBody, globalContext) => {
    let insideQuotes = false;
    let parenthesesBalance = 0; // Track parentheses balance
    let commaCount = 0;
    let part1EndIdx = -1;

    // Ensure processedIndices is initialized in globalContext
    globalContext.processedIndices = globalContext.processedIndices || []; // Initialize once

    let i = 0;
    while (i < ifBody.length) {
        const char = ifBody[i];
        const globalIndex = globalContext.globalIndex; // Track the global index throughout

        // Skip already processed sections (ranges from nested IFs)
        if (globalContext.processedIndices && isInsideProcessedRange(globalIndex, globalContext.processedIndices)) {
            console.log(`Skipping index ${globalIndex}, already processed.`);
            i++;
            globalContext.globalIndex++; // Ensure we update the global index here as well
            continue;
        }

        // Handle quotes (toggle inside/outside quote mode)
        if (char === "'" || char === '"') {
            insideQuotes = !insideQuotes;
            i++;
            globalContext.globalIndex++;
            continue;
        }

        // Skip content inside quotes
        if (insideQuotes) {
            i++;
            globalContext.globalIndex++;
            continue;
        }

        // Track parentheses balance outside of quotes
        if (char === '(') {
            parenthesesBalance++;
        } else if (char === ')') {
            parenthesesBalance--;
        }

        // Handle nested IF statements
        if (ifBody.slice(i, i + 2).toUpperCase() === 'IF' && parenthesesBalance === 0) {
            console.log(`Nested IF detected at global index: ${globalIndex}`);

            const nestedIfStartIdx = i + 2;
            const nestedClosingIdx = findClosingBracket(ifBody, nestedIfStartIdx);
            const nestedIfBody = ifBody.slice(nestedIfStartIdx + 1, nestedClosingIdx);

            console.log(`Validating nested IF part: ${nestedIfBody}`);
            // Recursively validate the nested IF statement
            try {
                validateIFParts(nestedIfBody, globalContext);
            } catch (err) {
                console.log("Gracefully ignoring error in nested IF: ", err.message);
            }

            // Store the processed range of this nested IF statement
            globalContext.processedIndices.push({ start: globalIndex, end: globalContext.globalIndex });

            // Move the index past the nested IF statement to avoid double-checking
            i = nestedClosingIdx + 1;
            globalContext.globalIndex = i; // Correctly set the global index to resume after the nested IF
            console.log(`Skipping to global index after nested IF: ${globalContext.globalIndex}`);

            continue;
        }

        // Handle commas, but only when outside of parentheses and quotes
        if (char === ',' && parenthesesBalance === 0) {
            console.log(`Comma detected at global index ${globalIndex}, current commaCount: ${commaCount}`);
            if (commaCount === 0) {
                // This is the first comma, so the first part ends here
                part1EndIdx = i;
            }
            commaCount++;
        }

        // Logging to check if the code resumes properly after the nested IF
        console.log(`At global index ${globalIndex}, character: '${char}', commaCount: ${commaCount}`);
        i++;
        globalContext.globalIndex++;
    }

    console.log('Final ifBody: ', ifBody);
    console.log('Final commaCount: ', commaCount);

    // Ensure exactly two commas in the immediate IF statement
    if (commaCount !== 2) {
        throw new Error("Error: IF statement must contain exactly two commas.");
    }

    // Validate the first part of the IF statement
    const part1 = ifBody.slice(0, part1EndIdx).trim(); // Get everything before the first comma
    console.log('First part of the IF: ', part1);

    validateIFFirstPart(part1);
};

// Helper to check if the current index is inside any processed range
const isInsideProcessedRange = (index, processedIndices) => {
    // Safeguard against undefined or empty processedIndices
    if (!processedIndices || processedIndices.length === 0) return false;
    return processedIndices.some((range) => index >= range.start && index <= range.end);
};

// Main function to validate IF conditions, including nested IFs
const validateIFConditions = (formula) => {
    const globalContext = { globalIndex: 0, processedIndices: [] }; // Initialize global context with globalIndex and processedIndices
    let insideQuotes = false;
    
    for (let i = 0; i < formula.length; i++) {
        const char = formula[i];
        const nextChar = formula[i + 1] || '';

        // Handle quotes
        if (char === "'" || char === '"') {
            insideQuotes = !insideQuotes;
            continue;
        }

        // Ignore content inside quotes
        if (insideQuotes) continue;

        // Check for each occurrence of "IF" followed by "("
        if (formula.slice(i, i + 2).toUpperCase() === 'IF') {
            // Ensure IF is followed by a (
            if (formula[i + 2] !== '(') {
                throw new Error(`Error: "IF" must be immediately followed by "(".`);
            }

            const ifStartIdx = i + 2; // Position of the opening '('
            const closingIdx = findClosingBracket(formula, ifStartIdx); // Find matching closing bracket
            const ifBody = formula.slice(ifStartIdx + 1, closingIdx); // Extract content inside the IF()

            // Validate the IF body, including any nested IF statements
            try {
                validateIFParts(ifBody, globalContext);
            } catch (err) {
                console.log("Gracefully ignoring error in main IF: ", err.message);
            }

            // Continue checking the formula from the closing IF bracket
            i = closingIdx;
        }
    }
};


// Controller function to execute validation and adjustments in sequence
const validateAndAdjustFormula = (formula, columnData) => {
  // Remove leading '=' if present
  const { formula: adjustedFormula, hasLeadingEquals } = removeLeadingEquals(formula);

  // Remove whitespace outside quotes
  let whitespaceStrippedFormula = removeWhitespaceOutsideQuotes(adjustedFormula);

  // Convert all double quotes to single quotes
  whitespaceStrippedFormula = convertToSingleQuotes(whitespaceStrippedFormula);

  checkQuotes(whitespaceStrippedFormula); // Check for matching quotes

  validateCharactersOutsideQuotes(whitespaceStrippedFormula); // Validate characters outside of quotes

  validateColumnNamesAndQuotedValues(whitespaceStrippedFormula, columnData); // Validate column names

  let finalFormula = validateAndFixBrackets(whitespaceStrippedFormula); // Fix brackets

  validateNonAlphanumericCombinations(finalFormula); // Validate non-alphanumeric combinations

  validateSymbolPlacement(finalFormula); // Validate symbol placement rules

  finalFormula = capitalizeKeywords(finalFormula); // Capitalize keywords

  validateIFConditions(finalFormula); // Validate IF-specific conditions

  // Re-add the leading '=' if it was present
  if (hasLeadingEquals) {
    finalFormula = '=' + finalFormula;
  }

  return finalFormula;
};

// FormulaValidator component
const FormulaValidator = ({ formula, columnData, onValidationResult }) => {
  useEffect(() => {
    console.log(columnData)
    try {
      const correctedFormula = validateAndAdjustFormula(formula, columnData);
      console.log("Validation successful. Corrected formula:", correctedFormula);
      onValidationResult(null, correctedFormula); // No errors, pass the corrected formula
    } catch (error) {
      console.log("Validation failed. Error message:", error.message);
      onValidationResult(error.message); // Pass the error message
    }
  }, [formula, columnData, onValidationResult]);

  return null; // No UI needed for this component
};

export default FormulaValidator;
