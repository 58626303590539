import moment from 'moment';

// Helper to replace column references in the formula with actual row values
const replaceColumnNames = (formula, rowValues, columnTypes) => {
  let updatedFormula = formula;

  Object.keys(rowValues).forEach((columnName) => {
    let columnValue = rowValues[columnName];

    // Handle different types: Dates and Categorical
    const columnType = columnTypes[columnName];
    if (columnType === 'PrimaryDate' || columnType === 'SecondaryDate') {
      // Ensure date is formatted correctly as a string in 'DD/MM/YYYY' format
      if (moment(columnValue, ["DD/MM/YYYY", "YYYY-MM-DD"], true).isValid()) {
        columnValue = `'${moment(columnValue).format('DD/MM/YYYY')}'`;
      }
    } else if (columnType === 'Categorical') {
      // Treat categorical values as strings in the formula
      columnValue = `'${columnValue}'`;
    }

    // Replace the column reference with its value, ensuring the columnName is found in the formula
    const regex = new RegExp(`'${columnName}'`, 'g');
    if (regex.test(updatedFormula)) {
      updatedFormula = updatedFormula.replace(regex, columnValue);
    }
  });

  return updatedFormula;
};

export const parseFormula = (formula, rowValues, columnTypes) => {
  let parsedFormula = replaceColumnNames(formula, rowValues, columnTypes);

  // Replace Excel-style IF with JavaScript ternary operator (case-insensitive match for IF)
  // Match IF(condition, trueValue, falseValue)
  parsedFormula = parsedFormula.replace(/IF\s*\(([^,]+),([^,]+),([^)]+)\)/gi, (match, condition, valueIfTrue, valueIfFalse) => {
    // First, replace Excel-style `<>`, `=` and other comparison operators with JavaScript equivalents
    let jsCondition = condition.trim();
    jsCondition = jsCondition.replace(/([^<>=]+)=([^=]+)/g, '$1 === $2');   // Handle '=' to '==='
    jsCondition = jsCondition.replace(/<>/g, '!==');                         // Handle '<>' to '!=='

    return `(${jsCondition} ? ${valueIfTrue.trim()} : ${valueIfFalse.trim()})`;
  });

  // Replace logical operators AND, OR with their JavaScript equivalents
  parsedFormula = parsedFormula.replace(/AND/gi, '&&');
  parsedFormula = parsedFormula.replace(/OR/gi, '||');

  // Replace ^ (exponentiation) with ** for JavaScript compatibility
  parsedFormula = parsedFormula.replace(/\^/g, '**');

  try {
    // Use Function constructor to safely evaluate the formula
    return new Function(`return (${parsedFormula});`)();
  } catch (error) {
    console.error('Error evaluating formula:', parsedFormula, error);
    return 'Error: Formula could not be evaluated.';
  }
};


// Function to calculate a formula for all rows of a spreadsheet
export const calculateFormulaForSpreadsheet = (rows, headers, formula, columnStatus) => {
  const results = [];

  rows.forEach(row => {
    const rowData = headers.reduce((acc, header, index) => {
      acc[header] = row[index]; // Map headers to row values
      return acc;
    }, {});

    const columnTypes = headers.reduce((acc, header, index) => {
      acc[header] = columnStatus[index]; // Map header to its type ('PrimaryDate', 'Categorical', etc.)
      return acc;
    }, {});

    // Use the formula calculation logic here (parseFormula)
    const result = parseFormula(formula, rowData, columnTypes); 
    results.push(result);
  });

  return results; // Return an array of results corresponding to each row
};
