import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from './authcontext';

const OrganisationPage = () => {
    const { orgId } = useParams(); // Extract orgId from URL parameters
    const { organisations, setOrganisations } = useAuth(); // Access organisations and setter from context
    const [organisation, setOrganisation] = useState(null); // State to hold the current organisation
    const [externalIntegrations, setExternalIntegrations] = useState(null); // State to hold parsed externalIntegrations
    const [loading, setLoading] = useState(true); // State to manage loading status
    const [error, setError] = useState(null); // State to handle errors

    // Function to handle connecting to Xero
    const handleConnectXero = () => {
        // Replace with your actual ngrok URL for Xero
        window.location.href = `https://623c-217-159-229-157.ngrok-free.app/api/xero/connect?organisation_id=${orgId}`;
    };

    // Function to handle connecting to QuickBooks
    const handleConnectQuickBooks = () => {
        // Replace with your actual ngrok URL for QuickBooks
        window.location.href = `https://17a7-217-159-229-157.ngrok-free.app/api/quickbooks/connect?organisation_id=${orgId}`;
    };

    useEffect(() => {
        // Function to find organisation in context
        const findOrganisation = () => {
            try {
                const orgIdInt = parseInt(orgId, 10);
                const foundOrganisation = organisations.find(org => org.id === orgIdInt);

                if (foundOrganisation) {
                    setOrganisation(foundOrganisation);

                    // Parse externalIntegrations if it exists
                    if (foundOrganisation.externalIntegrations) {
                        try {
                            const parsedIntegrations = JSON.parse(foundOrganisation.externalIntegrations);
                            setExternalIntegrations(parsedIntegrations);
                        } catch (parseError) {
                            console.error('Error parsing externalIntegrations:', parseError);
                            setError('Failed to parse external integrations data.');
                        }
                    }

                    setLoading(false);
                } else {
                    // Organisation not found in context
                    setError('Organisation not found in your data.');
                    setLoading(false);
                }
            } catch (err) {
                console.error('Error finding organisation:', err);
                setError('An error occurred while finding the organisation.');
                setLoading(false);
            }
        };

        findOrganisation();
    }, [orgId, organisations, setOrganisations]);

    // Render loading state
    if (loading) return <p>Loading...</p>;

    // Render error state
    if (error) return <p>{error}</p>;

    // Render organisation not found if organisation is still null
    if (!organisation) return <p>Organisation not found.</p>;

    return (
        <div>
            <h2>{organisation.name}</h2>
            <Link to={`/organisation/${organisation.id}/activity-log`}>
                Activity Log
            </Link>
            <Link to={`/organisation/${organisation.id}/start-subscription`}>
                Start Subscription
            </Link>
            {/* Display other organisation details here */}
            <p>Created By: {organisation.createdBy}</p>
            <p>Stripe ID: {organisation.stripeId}</p>
            {/* Add more fields as necessary */}

            {/* Display Xero Connection Status */}
            <div style={{ marginTop: '20px' }}>
                <h3>Connect to Xero</h3>
                {externalIntegrations && externalIntegrations.Xero ? (
                    <div>
                        <p>Connected to Xero.</p>
                        {/* Optionally, display more Xero details */}
                        <p>Tenant ID: {externalIntegrations.Xero.tenantId}</p>
                        <p>Connection Status: {externalIntegrations.Xero.connectionStatus}</p>
                        {/* Add more Xero-specific details if available */}
                    </div>
                ) : (
                    <button onClick={handleConnectXero}>
                        Connect to Xero
                    </button>
                )}
            </div>

            {/* Display QuickBooks Connection Status */}
            <div style={{ marginTop: '20px' }}>
                <h3>Connect to QuickBooks</h3>
                {externalIntegrations && externalIntegrations.QuickBooks ? (
                    <div>
                        <p>Connected to QuickBooks.</p>
                        {/* Optionally, display more QuickBooks details */}
                        <p>Realm ID: {externalIntegrations.QuickBooks.realmId}</p>
                        <p>Connection Status: {externalIntegrations.QuickBooks.connectionStatus}</p>
                        {/* Add more QuickBooks-specific details if available */}
                    </div>
                ) : (
                    <button onClick={handleConnectQuickBooks}>
                        Connect to QuickBooks
                    </button>
                )}
            </div>
        </div>
    );
};


export default OrganisationPage;
