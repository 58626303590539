import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import AddRowForm from './addrow';
import PivotPopup from './pivotpopup';
import ModelRenderer from './modelrenderer';
import api from '../api'; // Assuming you have an API utility for making requests
import { useParams, useNavigate } from 'react-router-dom'; 
import PivotTableForm from './pivottableform';

const SupportingPage = () => {
    const { modelId } = useParams(); // Get the modelId from the URL
    const { currentModel, setCurrentModel, spreadsheets, models, pivottables, isLoading: isModelsLoading } = useAuth();
    const [tableHeaders, setTableHeaders] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [showAddRowPopup, setShowAddRowPopup] = useState(false); // Controls simplified row popup
    const [showDetailedRowForm, setShowDetailedRowForm] = useState(false); // Controls detailed row form
    const [showFormulaBuildForm, setShowFormulaBuildForm] = useState(false); // Controls formula build form
    const [showPivotTableForm, setShowPivotTableForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null); // State to hold the clicked row data
    const [selectedPivotTableId, setSelectedPivotTableId] = useState(null); // State to hold the clicked pivot table ID
    const [detailedRowName, setDetailedRowName] = useState(''); // State for the custom row name
    const [formulaBuildName, setFormulaBuildName] = useState(''); // State for the formula build name
    const navigate = useNavigate();

    // Fetch the model whenever the modelId changes and models are loaded
    useEffect(() => {
        // Fetch the model only if modelId is present and models have been loaded
        if (modelId && !isModelsLoading && models.length > 0) {
            fetchModel(modelId);
        } else if (!isModelsLoading && models.length === 0) {
            // Optionally fetch the models again if not loaded (depending on context)
            console.warn('No models available.');
        }
    }, [modelId, isModelsLoading, models]);
       

    const fetchModel = async (modelId) => {
        try {
            setIsLoading(true);
            const model = models.find((m) => m.id === parseInt(modelId, 10));

            if (model) {
                setCurrentModel(model); // Set the new model in context
                const histHeaders = getHeadersByBasis(model, 'Hist');
                const foreHeaders = getHeadersByBasis(model, 'Fore');

                const headers = ['Row name', 'Driver', ...histHeaders.slice(-model.histPeriods), ...foreHeaders.slice(0, model.forePeriods)];
                setTableHeaders(headers);

                processRowsData(model, model.histPeriods, model.forePeriods);
            } else {
                console.error('Model not found for modelId:', modelId);
            }
        } catch (error) {
            console.error('Error fetching model:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getHeadersByBasis = (model, type) => {
        let headers = [];
        if (!model) {
            console.error('Model is undefined');
            return headers;
        }
    
        try {
            let rawHeaders;
            switch (model.basis) {
                case 'Yearly':
                    rawHeaders = type === 'Hist' ? model.annualHistHeaders : model.annualForeHeaders;
                    break;
                case 'Quarterly':
                    rawHeaders = type === 'Hist' ? model.quarterlyHistHeaders : model.quarterlyForeHeaders;
                    break;
                case 'Monthly':
                    rawHeaders = type === 'Hist' ? model.monthlyHistHeaders : model.monthlyForeHeaders;
                    break;
                default:
                    console.error('Invalid basis specified in the model');
                    return [];
            }
    
            // If rawHeaders is already an array, no need to parse
            headers = Array.isArray(rawHeaders) ? rawHeaders : JSON.parse(rawHeaders);
    
            if (!Array.isArray(headers)) {
                console.error('Headers are not an array:', headers);
                return [];
            }
    
            return headers.map(header => Array.isArray(header) ? header[0] : header.label || '');
        } catch (error) {
            console.error('Error parsing headers:', error);
            return [];
        }
    };
    

    const processRowsData = async (model, histPeriodsCount, forePeriodsCount) => {
        if (!model) {
            console.error('Model is undefined');
            setRowsData([]);
            return;
        }
    
        console.log('Processing rows data...');
        setIsLoading(true);
        const rows = [];
    
        const {
            rowNames = [],
            drivers = [],
            annualHistRows = [],
            quarterlyHistRows = [],
            monthlyHistRows = [],
            annualForeRows = [],
            quarterlyForeRows = [],
            monthlyForeRows = [],
        } = model;
    
        if (!Array.isArray(rowNames) || rowNames.length === 0) {
            console.warn('No rows found in the model');
            setRowsData([]); 
            setIsLoading(false); 
            return;
        }
    
        let histRowsData, foreRowsData;
        switch (model.basis) {
            case 'Yearly':
                histRowsData = JSON.parse(annualHistRows);
                foreRowsData = JSON.parse(annualForeRows);
                break;
            case 'Quarterly':
                histRowsData = JSON.parse(quarterlyHistRows);
                foreRowsData = JSON.parse(quarterlyForeRows);
                break;
            case 'Monthly':
                histRowsData = JSON.parse(monthlyHistRows);
                foreRowsData = JSON.parse(monthlyForeRows);
                break;
            default:
                console.error('Invalid basis specified in the model');
                setRowsData([]);
                setIsLoading(false);
                return;
        }

        // Define the driver types that are stored as arrays
        const arrayStoredDrivers = ['Fixed Growth', 'Percent Increase', 'Percent of Another Row', 'Formula of Rows'];
    
        for (let i = 0; i < rowNames.length; i++) {
            const rowName = rowNames[i];
    
            // **Handle Pivot Table Rows**
            if (Array.isArray(rowName) && rowName[0] === 'Pivot') {
                const pivotTableId = rowName[1];
                rows.push({
                    isPivot: true,
                    pivotTableId,
                });
                continue; // Continue to next iteration
            }

            // **Extract Driver Name**
            let driverName = '';
            if (Array.isArray(drivers[i]) && arrayStoredDrivers.includes(drivers[i][0])) {
                driverName = drivers[i][0]; // Extract only the driver name
            } else {
                driverName = drivers[i] || ''; // Use the driver as-is if it's a string
            }
    
            const rowData = {
                name: rowName || '',
                driver: driverName,
                values: [],
            };
    
            // **Process Historical Data**
            if (histRowsData && Array.isArray(histRowsData[i])) {
                const histValues = histRowsData[i].slice(-histPeriodsCount);
                rowData.values.push(...histValues);
            }
    
            // **Process Forecast Data Directly from foreRowsData**
            if (foreRowsData && Array.isArray(foreRowsData[i])) {
                const foreValues = foreRowsData[i].slice(0, forePeriodsCount);
                rowData.values.push(...foreValues);
            }
    
            rows.push(rowData);
        }
    
        setRowsData(rows);
        setIsLoading(false);
    };    

    const handleRowClick = (row, rowIndex) => {
        // Handle Pivot Table Click
        if (row.isPivot) {
            setSelectedPivotTableId(row.pivotTableId);
            return;
        }

        // Ensure the supportingAssumptionModelIds is parsed correctly as JSON
        let supportingAssumptionModelIds = [];
    
        try {
            supportingAssumptionModelIds = JSON.parse(currentModel.supportingAssumptionModelIds);
        } catch (error) {
            console.error('Error parsing supportingAssumptionModelIds:', error);
        }
    
        // Check if the driver indicates a related model type
        if (row.driver === 'Supporting Assumptions' || row.driver === 'Formula Build') {
            const relatedModelId = supportingAssumptionModelIds?.[rowIndex]; // Safely access the ID using rowIndex
            if (relatedModelId) {
                // Determine the route suffix based on the driver
                const routeSuffix = row.driver === 'Supporting Assumptions' ? 'sa' : 'fb';
                navigate(`/forecasting/${relatedModelId}/${routeSuffix}`);
            } else {
                console.warn(`No related model ID found for this row with driver "${row.driver}"`);
            }
        } else {
            const modelBasis = currentModel.basis;
    
            // Extract historical and forecast data based on model's basis
            let historicalData = [];
            let forecastData = [];
            let forecastValues = [];
    
            switch (modelBasis) {
                case 'Yearly':
                    historicalData = JSON.parse(currentModel.annualHistRows)[rowIndex] || [];
                    forecastData = JSON.parse(currentModel.annualForeRows)[rowIndex] || [];
                    forecastValues = JSON.parse(currentModel.annualForeValues)[rowIndex] || [];
                    break;
                case 'Quarterly':
                    historicalData = JSON.parse(currentModel.quarterlyHistRows)[rowIndex] || [];
                    forecastData = JSON.parse(currentModel.quarterlyForeRows)[rowIndex] || [];
                    forecastValues = JSON.parse(currentModel.quarterlyForeValues)[rowIndex] || [];
                    break;
                case 'Monthly':
                    historicalData = JSON.parse(currentModel.monthlyHistRows)[rowIndex] || [];
                    forecastData = JSON.parse(currentModel.monthlyForeRows)[rowIndex] || [];
                    forecastValues = JSON.parse(currentModel.monthlyForeValues)[rowIndex] || [];
                    break;
                default:
                    console.warn('Invalid basis type');
                    return;
            }
    
            // Pass the data to the AddRowForm component
            setSelectedRowData({
                ...row,
                rowIndex, // Correctly use the rowIndex parameter
                historicalData, // Pass historical data for initial load
                forecastData, // Pass forecast data for initial load
                forecastValues, // Pass underlying values as well (like percent increases and growth amounts)
                selectedSpreadsheetId: currentModel.sourceFile?.[rowIndex]?.[0] || '',
                selectedCategory: currentModel.categories?.[rowIndex] || '',
                selectedCategoryValue: currentModel.rowVariables?.[rowIndex] || '',
                selectedDateColumn: currentModel.dateColumn?.[rowIndex] || '',
                selectedVariable: currentModel.groupedOver?.[rowIndex] || '',
                sourceFile: currentModel.sourceFile?.[rowIndex] || [],
            });
    
            // Ensure only the edit popup opens
            setShowAddRowPopup(false); // Ensure "Add Simplified Row" popup is closed
        }
    };       

    const closePopup = () => {
        setSelectedRowData(null); // Close the popup
        setSelectedPivotTableId(null); // Close pivot popup
    };

    const handleRowAdded = async (updatedModel) => {
        // Hide the row forms
        setShowAddRowPopup(false);
        setShowDetailedRowForm(false);
        setShowPivotTableForm(false);  // Make sure to hide the pivot table form after adding
    
        if (updatedModel) {
            try {
                // Update the context with the new model returned from AddRowForm or PivotTableForm
                console.log('updatedmodel: ', updatedModel);
                setCurrentModel(updatedModel);
    
                // Call function to update original model with supporting assumptions or pivot table always
                await updateOriginalModelWithSupportingAssumptions(updatedModel);
    
            } catch (error) {
                console.error('Error updating row:', error);
            }
        }
    };     
    
    const updateOriginalModelWithSupportingAssumptions = async (updatedModel) => {
        try {
            // Find the original models associated with this supporting assumption
            const drivers = updatedModel.drivers || [];
    
            const relevantModels = models.map((model) => {
                let parsedSupportingIds = [];
    
                // Parse supportingAssumptionModelIds if necessary
                try {
                    parsedSupportingIds = Array.isArray(model.supportingAssumptionModelIds) 
                                          ? model.supportingAssumptionModelIds 
                                          : JSON.parse(model.supportingAssumptionModelIds || '[]');
                } catch (error) {
                    console.error('Error parsing supportingAssumptionModelIds:', error);
                }
    
                // Find the index where the updatedModel.id matches
                const rowIndex = parsedSupportingIds.findIndex(id => id === String(updatedModel.id));
    
                console.log('Parent Model ID:', model.id);
                console.log('Matching Row Index:', rowIndex);
    
                if (rowIndex !== -1) {
                    return {
                        modelId: model.id,
                        rowIndex // Store the index where the matching supporting assumption model ID was found
                    };
                }
                return null;
            }).filter(item => item !== null); // Filter out any null results
    
            console.log('Relevant parent models to update:', relevantModels);
    
            if (relevantModels.length === 0) {
                console.warn('No matching models found for this supporting assumption ID.');
                return;
            }

            // **Extract forecast rows from the updated model**
            const { 
                annualForeRows, 
                quarterlyForeRows, 
                monthlyForeRows 
            } = updatedModel;
    
            console.log('annualForeRows:', annualForeRows);
            console.log('quarterlyForeRows:', quarterlyForeRows);
            console.log('monthlyForeRows:', monthlyForeRows);
    
            // **Sum the forecast rows**
            let summedAnnualForeRows = sumRows(annualForeRows);
            let summedQuarterlyForeRows = sumRows(quarterlyForeRows);
            let summedMonthlyForeRows = sumRows(monthlyForeRows);
    
            console.log('Summed Annual Forecast Rows:', summedAnnualForeRows);
            console.log('Summed Quarterly Forecast Rows:', summedQuarterlyForeRows);
            console.log('Summed Monthly Forecast Rows:', summedMonthlyForeRows);
    
            // **Handle Pivot Tables**
            // Sum pivot tables for forecast periods
            summedAnnualForeRows = await sumPivotTables(summedAnnualForeRows, updatedModel, 'forePeriods', tableHeaders);
            summedQuarterlyForeRows = await sumPivotTables(summedQuarterlyForeRows, updatedModel, 'forePeriods', tableHeaders);
            summedMonthlyForeRows = await sumPivotTables(summedMonthlyForeRows, updatedModel, 'forePeriods', tableHeaders);
    
            // **Format rows: Convert 0 to an empty string, and wrap non-zero numbers in quotes**
            const formatRowData = (rows) => {
                return rows.map(value => (value === 0 ? "" : `${value}`));
            };
    
            const formattedAnnualForeRows = formatRowData(summedAnnualForeRows);
            const formattedQuarterlyForeRows = formatRowData(summedQuarterlyForeRows);
            const formattedMonthlyForeRows = formatRowData(summedMonthlyForeRows);
    
            console.log('Formatted Forecast Rows:', {
                annual: formattedAnnualForeRows,
                quarterly: formattedQuarterlyForeRows,
                monthly: formattedMonthlyForeRows,
            });
    
            // **Prepare data to be sent to the parent models**
            const rowData = {
                annualForeRows: formattedAnnualForeRows,
                quarterlyForeRows: formattedQuarterlyForeRows,
                monthlyForeRows: formattedMonthlyForeRows
            };
    
            console.log('Data prepared to send to parent models:', rowData);
    
            // **Make an API call to update the parent model rows for each relevant model**
            for (const { modelId, rowIndex } of relevantModels) {
                console.log(`Updating parent model ID: ${modelId}, Row Index: ${rowIndex}`);
                console.log('Data being sent to update parent model:', { ...rowData, rowIndex });
    
                try {
                    const updateResponse = await api.put(`/models/${modelId}/update-parent-row`, { ...rowData, rowIndex }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log(`Successfully updated parent model with ID: ${modelId}`, updateResponse.data);
                } catch (error) {
                    console.error(`Error updating parent model with ID ${modelId}:`, error.response ? error.response.data : error.message);
                }
            }
    
            // Optionally reload the page or refetch the model data
            window.location.reload()
        } catch (error) {
            console.error('Error updating original models with supporting assumptions:', error);
        }
    };
    
    
    // Utility function to sum rows with better validation
    const sumRows = (rows) => {
        if (!Array.isArray(rows) || rows.length === 0) {
            console.warn('Rows is not a valid array or is empty.');
            return []; // Return an empty array if rows is not valid
        }

        // Initialize an array to hold the summed values for each column (historical periods)
        const numberOfColumns = rows[0].length; // Assume all rows have the same number of columns
        const summedValues = Array(numberOfColumns).fill(0); // Initialize an array with zeros

        // Ensure we are dealing with arrays that contain valid numbers and include the most recent row
        rows.forEach((row) => {
            if (Array.isArray(row) && row.length > 0) {
                row.forEach((value, index) => {
                    const rowValue = parseFloat(value); // Parse the row value as a float
                    if (!isNaN(rowValue)) { // Only add valid numbers
                        summedValues[index] += rowValue;
                    }
                });
            } else {
                console.warn('Encountered non-array row or empty row:', row);
            }
        });

        return summedValues;
    };   

    const sumPivotTables = async (summedRows, updatedModel, periodType, tableHeaders) => {
        const { rowNames } = updatedModel;
        const pivotTables = pivottables; // Assuming you have pivot tables stored in useAuth()
    
        // Extract period labels from tableHeaders based on periodType
        const periodLabels = periodType === 'histPeriods'
            ? tableHeaders.slice(2, 2 + currentModel.histPeriods)
            : tableHeaders.slice(2 + currentModel.histPeriods, 2 + currentModel.histPeriods + currentModel.forePeriods);
    
        // Create a mapping from period labels to their indices in summedRows
        const periodMap = {};
        periodLabels.forEach((label, index) => {
            periodMap[label] = index;
        });
    
        // Log the periodMap once to verify correct mapping
        console.log(`Period Map for ${periodType}:`, periodMap);
    
        for (const rowName of rowNames) {
            if (Array.isArray(rowName) && rowName[0] === 'Pivot') {
                const pivotTableId = rowName[1];
                const pivotTable = pivotTables.find(pt => pt.id === pivotTableId);
    
                if (pivotTable) {
                    let periodRows;
    
                    // Determine which data to use based on the periodType and basis
                    try {
                        switch (periodType) {
                            case 'histPeriods':
                                if (summedRows.length === 10) {
                                    periodRows = pivotTable.annualHistData; // Already an object
                                } else if (summedRows.length === 40) {
                                    periodRows = pivotTable.quarterlyHistData; // Already an object
                                } else if (summedRows.length === 120) {
                                    periodRows = pivotTable.monthlyHistData; // Already an object
                                }
                                break;
                            case 'forePeriods':
                                if (summedRows.length === 10) {
                                    periodRows = pivotTable.annualForeData; // Already an object
                                } else if (summedRows.length === 40) {
                                    periodRows = pivotTable.quarterlyForeData; // Already an object
                                } else if (summedRows.length === 120) {
                                    periodRows = pivotTable.monthlyForeData; // Already an object
                                }
                                break;
                            default:
                                console.warn(`Unknown periodType: ${periodType}`);
                        }
                    } catch (error) {
                        console.error(`Error accessing pivot table data for Pivot Table ID ${pivotTableId}:`, error);
                        continue; // Skip this pivot table if there's an error
                    }
    
                    // Log periodRows once to verify structure
                    if (periodRows) {
                        console.log(`Parsed periodRows for Pivot Table ID ${pivotTableId}:`, periodRows);
                    }
    
                    if (periodRows) {
                        const topLevelCategories = Object.keys(periodRows);
    
                        topLevelCategories.forEach((category) => {
                            const categoryData = periodRows[category];
    
                            if (categoryData && typeof categoryData.total === 'object' && categoryData.total !== null) {
                                const periodKeys = Object.keys(categoryData.total);
    
                                periodKeys.forEach((periodLabel) => {
                                    const sumValue = parseFloat(categoryData.total[periodLabel]?.sum) || 0;
                                    const index = periodMap[periodLabel];
    
                                    if (index !== undefined && index < summedRows.length) {
                                        summedRows[index] += sumValue;
                                    } else {
                                        console.warn(`Period label "${periodLabel}" not found in periodMap.`);
                                    }
                                });
                            }
                        });
    
                        // After processing each pivot table, log the updated summedRows for verification
                        console.log(`Summed Rows after processing Pivot Table ID ${pivotTableId}:`, summedRows);
                    }
                } else {
                    console.warn(`Pivot table with ID ${pivotTableId} not found.`);
                }
            }
        }
    
        return summedRows;
    };       
    
    // API Call to create a new detailed model
    const createDetailedModel = async (modelType) => {
        try {
            // Determine the row name based on the model type
            const rowName = modelType === 'Formula Build' ? formulaBuildName : detailedRowName;

            // Validate that rowName is provided
            if (!rowName.trim()) {
                alert('Please enter a row name.');
                return;
            }

            const response = await api.post('/models', {
                organisationId: currentModel.organisationId,
                superModelId: currentModel.superModelId, // This links the new model to the current model
                modelName: rowName,
                modelType: modelType,
                basis: currentModel.basis,
                histPeriods: currentModel.histPeriods,
                forePeriods: currentModel.forePeriods,
                yearEnd: currentModel.yearEnd,
                templateUrl: currentModel.templateUrl,
                annualHistHeaders: currentModel.annualHistHeaders,
                quarterlyHistHeaders: currentModel.quarterlyHistHeaders,
                monthlyHistHeaders: currentModel.monthlyHistHeaders,
                annualForeHeaders: currentModel.annualForeHeaders,
                quarterlyForeHeaders: currentModel.quarterlyForeHeaders,
                monthlyForeHeaders: currentModel.monthlyForeHeaders,
            });
            if (response.status === 201) {
                console.log(`${modelType} model created successfully`);

                const newModelId = response.data.model.id;

                // Add a new row to the current model with padding and the new model ID
                await addDetailedRowToCurrentModel(newModelId, modelType);
                handleRowAdded(); // Optionally reload the rows after creation

                // Navigate based on the model type
                if (modelType === 'Supporting Assumptions') {
                    navigate(`/forecasting/${newModelId}/sa`);
                } else if (modelType === 'Formula Build') {
                    navigate(`/forecasting/${newModelId}/fb`);
                }

                window.location.reload();
            } else {
                console.error('Failed to create detailed model');
            }
        } catch (error) {
            console.error('Error creating detailed model:', error);
        }
    };     

    const addDetailedRowToCurrentModel = async (supportingAssumptionId, drivers) => {
        try {
            // Padding for data (10 for annual, 40 for quarterly, 120 for monthly)
            const emptyAnnualData = Array(10).fill('');
            const emptyQuarterlyData = Array(40).fill('');
            const emptyMonthlyData = Array(120).fill('');
    
            // Prepare the row data with padding
            const rowData = {
                rowNames: [drivers === 'Supporting Assumptions' ? detailedRowName : formulaBuildName], // Use the entered detailed row name
                rowVariables: ['None'],
                categories: ['None'],
                groupedOver: 'None',
                dateColumn: 'None',
                sourceFile: ['None'],
                drivers: [drivers], // Set the driver to "Supporting Assumptions"
                supportingAssumptionModelIds: [String(supportingAssumptionId)], // Set the new supporting assumption model ID
                annualHistRows: [emptyAnnualData],
                quarterlyHistRows: [emptyQuarterlyData],
                monthlyHistRows: [emptyMonthlyData],
                annualForeRows: [emptyAnnualData],
                quarterlyForeRows: [emptyQuarterlyData],
                monthlyForeRows: [emptyMonthlyData],
                annualForeValues: [emptyAnnualData],
                quarterlyForeValues: [emptyQuarterlyData],
                monthlyForeValues: [emptyMonthlyData],                
            };
    
            // Send the new row to the backend to add it to the current model
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowData);
    
            if (response.status === 200) {
                console.log('Detailed row added successfully');
            } else {
                console.error('Failed to add detailed row to model:', response.data);
            }
        } catch (error) {
            console.error('Error adding detailed row:', error);
        }
    };   

    const handleSave = async (updatedData) => {
        try {
            console.log('handleSave called with data:', JSON.stringify(updatedData, null, 2));

            const {
                rowIndex,
                rowName,
                driver,
                category,
                categoryValue,
                groupedOver,
                dateColumn,
                sourceFile,
                updatedHistoricalValues,
                updatedForecastValues,
                updatedForecastRows,
                basis,
            } = updatedData;

            // Validate that all required fields are present
            const requiredFields = ['rowName', 'category', 'categoryValue', 'groupedOver', 'dateColumn', 'sourceFile'];
            const missingFields = requiredFields.filter(field => !updatedData[field]);

            if (missingFields.length > 0) {
                console.error(`Missing required fields: ${missingFields.join(', ')}`);
                alert(`Missing required fields: ${missingFields.join(', ')}`);
                return;
            }

            const payload = {
                rowIndex,
                rowName,
                driver,
                category,
                categoryValue,
                groupedOver,
                dateColumn,
                sourceFile,
                updatedHistoricalValues,
                updatedForecastValues,
                updatedForecastRows,
                basis,
            };

            console.log("Data being sent to backend:", JSON.stringify(payload, null, 2));

            const response = await api.put(`/model/update-forecasting-row/${currentModel.id}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    // Include Authorization header if required
                    // 'Authorization': `Bearer ${token}`,
                },
            });

            console.log('Received response from backend:', response);

            if (response.status === 200) {
                console.log('Row data successfully updated:', response.data);
                closePopup();
                fetchModel(currentModel.id); // Refresh model data
                window.location.reload()
            } else {
                console.error('Failed to save changes:', response.data);
                alert(`Failed to save changes: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            alert('An error occurred while saving changes.');
        }
    };    

    const toggleShowRowForm = () => {
        setShowAddRowPopup(!showAddRowPopup);
        setShowDetailedRowForm(false);
        setShowFormulaBuildForm(false)
        setShowPivotTableForm(false);
        setSelectedRowData(null);
    };

    const toggleShowDetailedRowForm = () => {
        setShowDetailedRowForm(!showDetailedRowForm);
        setShowFormulaBuildForm(false)
        setShowAddRowPopup(false); // Hide simplified row form when showing detailed form
        setShowPivotTableForm(false);
        setSelectedRowData(null);
    };     

    const toggleShowPivotTableForm = () => {
        setShowPivotTableForm(!showPivotTableForm);
        setShowAddRowPopup(false);
        setShowDetailedRowForm(false); // Ensure row forms are hidden when this is clicked
        setSelectedRowData(null);
    };

    const toggleShowBuildFormulaForm = () => {
        setShowFormulaBuildForm(!showFormulaBuildForm)
        setShowDetailedRowForm(false);
        setShowPivotTableForm(false)
        setShowAddRowPopup(false); // Hide simplified row form when showing detailed form
    };     

    return (
        <div>
            {currentModel && (
                <>
                    <h2>{`${currentModel.modelName} - ${currentModel.modelType}`}</h2>
                    <div className="button-container">
                        <button onClick={toggleShowRowForm}>Add Simplified Row</button>
                        <button onClick={toggleShowDetailedRowForm}>Add Detailed Row</button>
                        <button onClick={toggleShowBuildFormulaForm}>Build Formula Row</button>
                        <button onClick={toggleShowPivotTableForm}>Add Pivot Table</button>
                    </div>

                    {showAddRowPopup && !selectedRowData && (
                        <div className="popup-overlay">
                            <button className="popup-close" onClick={() => setShowAddRowPopup(false)}>×</button>
                            <AddRowForm 
                                currentModel={currentModel}
                                spreadsheets={spreadsheets}
                                onRowAdded={handleRowAdded}
                                onClose={() => setShowAddRowPopup(false)}
                                isEditing={false}
                            />
                        </div>
                    )}

                    {showDetailedRowForm && (
                        <div>
                            <input 
                                type="text" 
                                placeholder="Enter detailed row name" 
                                value={detailedRowName}
                                onChange={(e) => setDetailedRowName(e.target.value)}
                            />
                            <button onClick={() => createDetailedModel('Supporting Assumptions')}>
                            Confirm
                            </button>
                        </div>
                    )}

                    {showPivotTableForm && (
                        <PivotTableForm handleRowAdded={handleRowAdded} />
                    )}

                    {showFormulaBuildForm && (
                        <div>
                            <input 
                                type="text" 
                                placeholder="Enter formula build row name" 
                                value={formulaBuildName}
                                onChange={(e) => setFormulaBuildName(e.target.value)}
                            />
                            <button onClick={() => createDetailedModel('Formula Build')}>
                                Confirm
                            </button>
                        </div>
                    )}

                    {isLoading ? (
                        <p>Loading data...</p>
                    ) : (
                        <div className="scrollable-table-container">
                            <ModelRenderer 
                                rowsData={rowsData} 
                                tableHeaders={tableHeaders} 
                                histPeriods={currentModel.histPeriods} 
                                forePeriods={currentModel.forePeriods} 
                                onRowClick={handleRowClick}
                            />
                        </div>
                    )}
                </>
            )}

            {/* Popup for editing row */}
            {selectedRowData && (
                <div className="popup-overlay">
                    <AddRowForm
                        currentModel={currentModel}
                        spreadsheets={spreadsheets}
                        onRowAdded={handleRowAdded}
                        onClose={closePopup}
                        isEditing={true}
                        rowData={selectedRowData}
                        rowIndex={selectedRowData.rowIndex} // Correctly pass rowIndex from selectedRowData
                        onSave={handleSave}
                    />
                </div>
            )}

            {/* PivotPopup for viewing pivot table details */}
            {selectedPivotTableId && (
                <PivotPopup 
                    pivotTableId={selectedPivotTableId} 
                    histPeriods={currentModel.histPeriods} 
                    forePeriods={currentModel.forePeriods} 
                    basis={currentModel.basis}
                    onClose={closePopup} 
                />
            )}
        </div>
    );
};

export default SupportingPage;
