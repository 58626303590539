import React, { useState, useEffect, useRef } from 'react';

const RowNameAutosuggest = ({ numericalColumns, onSelect, inputValue, setInputValue, selectedDriver }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

    // Determine if autosuggest should be enabled
    const isAutosuggestEnabled = selectedDriver !== 'Formula of Rows';

  // Handle clicks outside the component to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Filter options based on input value
  useEffect(() => {
    if (!isAutosuggestEnabled) {
      setFilteredOptions([]);
      setIsDropdownVisible(false);
      return;
    }

    if (inputValue.trim() === '') {
      setFilteredOptions([]);
      setIsDropdownVisible(false);
      return;
    }

    const filtered = numericalColumns.filter((item) =>
      item.columnName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setFilteredOptions(filtered);
    setIsDropdownVisible(filtered.length > 0);
  }, [inputValue, numericalColumns, isAutosuggestEnabled]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onSelect(null); // Reset selections if typing
  };

  const handleSelect = (option) => {
    const formattedValue = `${option.columnName} - ${option.spreadsheetName}`;
    setInputValue(formattedValue);
    setIsDropdownVisible(false);
    onSelect(option);
  };

  const handleFocus = () => {
    if (isAutosuggestEnabled && filteredOptions.length > 0) {
      setIsDropdownVisible(true);
    }
  };

  return (
    <div className="autosuggest-dropdown">
      <input
        type="text"
        ref={inputRef}
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder="Enter Row Name"
      />
      {isDropdownVisible && isAutosuggestEnabled && (
        <ul className="dropdown" ref={dropdownRef}>
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              {`${option.columnName} - ${option.spreadsheetName}`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RowNameAutosuggest;
