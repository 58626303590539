import * as XLSX from 'xlsx';
import api from './api';
import moment from 'moment';
import { calculateFormulaForSpreadsheet } from './formulacalculator'; // Import formula calculation

const fetchSpreadsheetData = async (spreadsheetId, currentSpreadsheet, editedRows = [], applyEdits = true, calculateCustomColumns = false, onlyPrimaryDate = false) => {
  try {
    if (!currentSpreadsheet) {
      throw new Error('Spreadsheet not found');
    }

    const response = await api.get(`/spreadsheets/${spreadsheetId}/download`, {
      responseType: 'arraybuffer',
    });

    const workbook = XLSX.read(response.data, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName], {
      header: 1,
    });

    const headers = worksheet[0]; // First row is the header
    let rows = worksheet.slice(1); // Remaining rows are the data

    const editableHeaders = currentSpreadsheet.editedHeaders || headers;
    const columnStatus = currentSpreadsheet.columnStatus || [];

    // Step 1: Filter columns based on their status ("Date", "Categorical", or "Numerical")
    const filteredHeaders = editableHeaders.filter((_, index) => {
      const status = columnStatus[index];
      if (onlyPrimaryDate) {
        return ['PrimaryDate', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(status);
      }
      return ['PrimaryDate', 'SecondaryDate', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(status);
    });

    const filteredRows = rows.map(row =>
      row.filter((_, index) => {
        const status = columnStatus[index];
        if (onlyPrimaryDate) {
          return ['PrimaryDate', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(status);
        }
        return ['PrimaryDate', 'SecondaryDate', 'Categorical', 'NumericalEditable', 'CategoricalEditable'].includes(status);
      })
    );

    // Step 2: Process rows to convert date columns into proper "DD/MM/YYYY" format
    const processedRows = filteredRows.map(row =>
      row.map((cell, index) => {
        const headerIndex = editableHeaders.findIndex(header => header === filteredHeaders[index]);
        if (columnStatus[headerIndex] && columnStatus[headerIndex].includes('Date')) {
          if (typeof cell === 'string') {
            const date = moment(cell, ["DD/MM/YYYY", "YYYY-MM-DD"]);
            return date.isValid() ? date.format('DD/MM/YYYY') : cell;
          }
          if (typeof cell === 'number') {
            const date = XLSX.SSF.parse_date_code(cell);
            if (date) {
              const formattedDate = moment({ y: date.y, M: date.m - 1, d: date.d }).format('DD/MM/YYYY');
              return formattedDate;
            }
          }
        }
        return cell;
      })
    );

    // Step 3: Apply edits if the applyEdits flag is true
    if (applyEdits) {
      editedRows.forEach((editedRow) => {
        if (editedRow.spreadsheetId === spreadsheetId) {
          const colIndex = filteredHeaders.indexOf(editedRow.editedColumnName);
          if (colIndex !== -1) {
            // Replace the original value with the edited value in the rows
            processedRows.forEach((row) => {
              if (String(row[colIndex]) === String(editedRow.originalValue)) {
                row[colIndex] = editedRow.editedValue;
              }
            });
          }
        }
      });
    }

    // Step 4: Categorize columns into Date, Numerical, Categorical
    const dateCols = [];
    const numCols = [];
    const catCols = [];

    columnStatus.forEach((status, index) => {
      if (onlyPrimaryDate) {
        if (status === 'PrimaryDate') {
          dateCols.push(editableHeaders[index]); // Return only the "edited" field names
        }
      } else {
        if (status === 'PrimaryDate' || status === 'SecondaryDate') {
          dateCols.push(editableHeaders[index]); // Return only the "edited" field names
        }
      }

      if (status === 'NumericalEditable') {
        numCols.push(editableHeaders[index]); // Return only the "edited" field names
      } else if (status === 'Categorical' || status === 'CategoricalEditable') {
        catCols.push(editableHeaders[index]); // Return only the "edited" field names
      }
    });

    // Step 5: Process custom columns only if calculateCustomColumns flag is true
    if (calculateCustomColumns && currentSpreadsheet.customColumns) {
      currentSpreadsheet.customColumns.forEach(([columnName, formula]) => {
        // Evaluate the formula for the current spreadsheet and add it as a new column
        const customColumnValues = calculateFormulaForSpreadsheet(processedRows, filteredHeaders, formula, columnStatus);

        // Add the custom column to the spreadsheet data
        processedRows.forEach((row, rowIndex) => {
          row.push(customColumnValues[rowIndex]);
        });

        // Add the custom column to headers
        filteredHeaders.push(columnName);
        numCols.push(columnName); // Custom columns are always Numerical
      });
    }

    // Convert processedRows into an object (keyed by headers) to match spreadsheet structure
    const spreadsheetData = filteredHeaders.reduce((acc, header, colIndex) => {
      acc[header] = processedRows.map(row => row[colIndex]);
      return acc;
    }, {});

    // Transpose the data: converting from columns to rows
    const transposedRows = Array.from({ length: processedRows.length }, (_, rowIndex) =>
      filteredHeaders.map(header => spreadsheetData[header][rowIndex])
    );

    // Return all necessary information, including custom columns if calculated
    return {
      filteredHeaders, // Headers after filtering columns and adding custom columns if applicable
      processedRows: transposedRows, // Transposed rows to display properly
      dateCols,        // List of date column names (edited names only)
      numCols,         // List of numerical column names (edited names + custom columns)
      catCols,         // List of categorical column names (edited names only)
    };

  } catch (error) {
    console.error('Error fetching spreadsheet data:', error);
    throw error;
  }
};

export default fetchSpreadsheetData;
