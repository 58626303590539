import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { useAuth } from "./authcontext";
import api from "./api";

// Load the Stripe instance
const stripePromise = loadStripe("pk_test_51QQdZYFPQ6DLQJCu4SID9PrBzNSSyc4pMtdETQGgIqdjcB9MHpamknXsNvo6PzaX9wMEFFZiPYsfTxY9BvByXcpm00PBGWYCuw");

const EmbeddedCheckoutPage = () => {
    const { orgId } = useParams();
    const { user } = useAuth();
    const [clientSecret, setClientSecret] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState("open");

    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await api.post("/create-subscription-checkout-session", {
                    orgId,
                    email: user.email,
                });

                if (response.data.clientSecret) {
                    setClientSecret(response.data.clientSecret);
                } else {
                    throw new Error("Failed to retrieve client secret.");
                }
            } catch (err) {
                console.error("Error fetching client secret:", err);
                setError("Failed to load payment details. Please try again later.");
            }
        };

        fetchClientSecret();
    }, [orgId, user.email]);

    const handleCheckoutComplete = async () => {
        try {
            // Use the client secret to retrieve the checkout session status
            const response = await api.get(`/check-session-status?clientSecret=${clientSecret}`);
            setStatus(response.data.status);
        } catch (err) {
            console.error("Error checking session status:", err);
            setError("Failed to confirm payment. Please try again.");
        }
    };

    if (status === "complete") {
        return <p>Payment successful! Thank you for subscribing.</p>;
    }

    if (status === "open") {
        return clientSecret ? (
            <div id="checkout">
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                    onComplete={handleCheckoutComplete} // Hook for post-payment actions
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        ) : (
            <p>Loading payment details...</p>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    return null;
};

export default EmbeddedCheckoutPage;
