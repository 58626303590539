// src/Components/ActivityLogPage.js

import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from './authcontext';

const ActivityLogPage = () => {
    const { orgId } = useParams(); // Extract orgId from URL parameters
    const { activityLogs, organisations, user } = useAuth(); // Access activityLogs, organisations, and users from context

    // Find the current organisation to display its name
    const organisation = organisations.find(org => org.id === parseInt(orgId, 10));

    console.log(activityLogs)

    return (
        <div style={{ padding: '20px' }}>
            <h3>Activity Log for {organisation ? organisation.name : 'Organisation'}</h3>
            {activityLogs.length === 0 ? (
                <p>No activity logs available.</p>
            ) : (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={tableHeaderStyle}>Timestamp</th>
                            <th style={tableHeaderStyle}>User</th>
                            <th style={tableHeaderStyle}>Action Type</th>
                            <th style={tableHeaderStyle}>Entity</th>
                            <th style={tableHeaderStyle}>Entity ID</th>
                            <th style={tableHeaderStyle}>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activityLogs.map((log) => {
                            const userName = user ? `${user.firstName} ${user.lastName}` : 'Unknown User';

                            // Parse details JSON for better readability
                            let detailsContent = log.details;
                            try {
                                const detailsJSON = JSON.parse(log.details);
                                detailsContent = JSON.stringify(detailsJSON, null, 2); // Pretty-print JSON
                            } catch (error) {
                                // If parsing fails, keep details as is
                            }

                            return (
                                <tr key={log.id}>
                                    <td style={tableCellStyle}>{new Date(log.timestamp).toLocaleString()}</td>
                                    <td style={tableCellStyle}>{userName}</td>
                                    <td style={tableCellStyle}>{log.actionType}</td>
                                    <td style={tableCellStyle}>{log.entity}</td>
                                    <td style={tableCellStyle}>{log.entityID}</td>
                                    <td style={tableCellStyle}>
                                        <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{detailsContent}</pre>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

// Inline styles for table headers and cells
const tableHeaderStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    backgroundColor: '#f2f2f2',
    textAlign: 'left',
    minWidth: '100px' // Ensure minimum width
};

const tableCellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    minWidth: '100px', // Ensure minimum width
    verticalAlign: 'top' // Align text to top for better readability
};

export default ActivityLogPage;
