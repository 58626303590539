import { useEffect } from 'react';

// Helper to remove leading '=' if present
const removeLeadingEquals = (formula) => {
    if (formula.startsWith('=')) {
      return { formula: formula.slice(1).trim(), hasLeadingEquals: true };
    }
    return { formula, hasLeadingEquals: false };
};

// Helper to convert all double quotes to single quotes
const convertToSingleQuotes = (formula) => {
  return formula.replace(/"/g, "'");
};

// Helper to remove whitespace outside quotes
const removeWhitespaceOutsideQuotes = (formula) => {
    let result = '';
    let insideQuotes = false;
  
    for (let i = 0; i < formula.length; i++) {
      const char = formula[i];
  
      // Toggle inside/outside quotes state
      if (char === "'" || char === '"') {
        insideQuotes = !insideQuotes;
        result += char;
        continue;
      }
  
      // Only add characters if they are non-whitespace, or if we're inside quotes
      if (insideQuotes || !/\s/.test(char)) {
        result += char;
      }
    }
  
    return result;
};

// Helper to check if quotes are balanced and ensure no adjacent quotes
const checkQuotes = (formula) => {
    const totalQuoteCount = (formula.match(/['"]/g) || []).length;

    // Check if the total number of quotes (both ' and ") is odd
    if (totalQuoteCount % 2 !== 0) {
        throw new Error("Error: Unmatched quotes in the formula.");
    }

    let insideQuotes = false;

    // Ensure no two quotes are adjacent when outside quotes
    for (let i = 0; i < formula.length - 1; i++) {
        const currentChar = formula[i];
        const nextChar = formula[i + 1];

        // Toggle inside/outside quotes
        if (currentChar === "'" || currentChar === '"') {
            insideQuotes = !insideQuotes;
        }

        // Check for adjacent quotes of either type, but only when outside quotes
        if (!insideQuotes && (currentChar === "'" || currentChar === '"') && (nextChar === "'" || nextChar === '"')) {
            throw new Error("Error: Adjacent quotes are not allowed outside of quotes.");
        }
    }
};

// Helper to validate characters outside of quotes
const validateCharactersOutsideQuotes = (formula) => {
  const validCharsPattern = /^[a-zA-Z0-9+\-*/^().\s]*$/;

  let insideQuotes = false;

  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];

    // Handle quotes (toggle inside/outside quote mode)
    if (char === "'" || char === '"') {
      insideQuotes = !insideQuotes;
      continue;
    }

    // Validate characters outside quotes
    if (!insideQuotes && !validCharsPattern.test(char)) {
      throw new Error(`Error: Invalid character '${char}' found outside of quotes.`);
    }
  }
};

// Helper to validate and fix brackets
const validateAndFixBrackets = (formula) => {
  let correctedFormula = '';
  let insideQuotes = false;
  let openBrackets = 0;

  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];

    // Handle quotes
    if (char === "'" || char === '"') {
      correctedFormula += char;
      insideQuotes = !insideQuotes;
      continue;
    }

    if (insideQuotes) {
      correctedFormula += char;
      continue;
    }

    if (char === '(') {
      openBrackets++;
    } else if (char === ')') {
      if (openBrackets > 0) {
        openBrackets--;
      } else {
        throw new Error("Error: Mismatched closing bracket found.");
      }
    }

    correctedFormula += char;
  }

  // Add missing closing brackets
  if (openBrackets > 0) {
    correctedFormula += ')'.repeat(openBrackets);
  }

  return correctedFormula;
};

// Helper to validate non-alphanumeric character combinations
const validateNonAlphanumericCombinations = (formula) => {
  let insideQuotes = false;

  for (let i = 0; i < formula.length; i++) {
    const char = formula[i];
    const nextChar = formula[i + 1] || '';
    const prevChar = formula[i - 1] || '';

    // Handle quotes (toggle inside/outside quote mode)
    if (char === "'" || char === '"') {
      insideQuotes = !insideQuotes;
      continue;
    }

    // Skip checking inside quotes
    if (insideQuotes) {
      continue;
    }

    // Disallow numbers or decimal points immediately before an opening quote or after a closing quote
    if ((/[0-9.)]/.test(char) && (nextChar === '"' || nextChar === "'")) ||  // Before opening quote
        ((/[0-9.]/.test(char)) && (prevChar === '"' || prevChar === "'"))) {      // Immediately after closing quote
        throw new Error(`Error: Invalid use of number, decimal point or bracket next to a quote.`);
    }
    

    if (nextChar === "'" || nextChar === '"') {
      continue; // Skip this combination check because we're reaching quotes
    }

    // Skip checking parentheses at this stage
    if (char === '(' || char === ')') {
      continue;
    }

    // Ensure decimal point is followed by a number
    if (char === '.' && !/[0-9]/.test(nextChar)) {
      throw new Error(`Error: Decimal point '.' must be followed by a number, but '${nextChar}' found.`);
    }

    // Check if two invalid non-alphanumeric characters are adjacent (excluding parentheses and decimal checks)
    if (/[^a-zA-Z0-9\s().]/.test(char) && /[^a-zA-Z0-9\s().]/.test(nextChar)) {
      throw new Error(`Error: Invalid combination '${char}${nextChar}' found.`);
    }
  }
};

// Helper to validate symbol placement rules, treating quotes like numbers or letters
const validateSymbolPlacement = (formula) => {
    let insideQuotes = false;
  
    // Remove the leading equals sign if it exists
    if (formula.startsWith('=')) {
      formula = formula.slice(1).trim();
    }
  
    for (let i = 0; i < formula.length; i++) {
      const char = formula[i];
      const nextChar = formula[i + 1] || '';
      const prevChar = formula[i - 1] || '';
  
      // Handle quotes (toggle inside/outside quote mode)
      if (char === "'" || char === '"') {
        insideQuotes = !insideQuotes;
        continue;
      }
  
      // Skip checking inside quotes
      if (insideQuotes) {
        continue;
      }
  
      // Rule 1: Open bracket placement
      if (char === '(') {
        // Disallow a number or decimal point immediately before an opening bracket
        if (/[0-9.]/.test(prevChar)) {
          throw new Error(`Error: Number or decimal point '${prevChar}' found before '('.`);
        }
        // It can only be followed by a number, letter, another open bracket, a decimal point, or a quote
        if (/[^a-zA-Z0-9.(\'"]/ .test(nextChar)) {
          throw new Error(`Error: Invalid symbol '${nextChar}' following '('.`);
        }
      }

      // Rule 2: Closed bracket placement
      if (char === ')') {
        // Disallow a number or decimal point immediately after a closing bracket
        if (/[0-9.]/.test(nextChar)) {
          throw new Error(`Error: Number or decimal point '${nextChar}' found after ')'.`);
        }
        // It cannot be preceded by a symbol (except numbers, letters, another closed bracket, or a quote)
        if (/[^a-zA-Z0-9)\'"]/.test(prevChar)) {
          throw new Error(`Error: Invalid symbol '${prevChar}' before ')'.`);
        }
      }
  
      // Rule 3: Formula can't start with symbols (except open bracket '(' or quotes)
      if (i === 0 && /[^a-zA-Z0-9('"()]/.test(char)) {
        throw new Error(`Error: Formula cannot start with '${char}'.`);
      }
  
      // Rule 4: Formula can't end with symbols (except closed bracket ')' or quotes)
      if (i === formula.length - 1 && /[^a-zA-Z0-9'")]/.test(char)) {
        throw new Error(`Error: Formula cannot end with '${char}'.`);
      }
    }
};

// Controller function to execute validation and adjustments in sequence
const validateAndAdjustFormula = (formula) => {
  // Step 1: Remove leading '=' if present
  const { formula: adjustedFormula, hasLeadingEquals } = removeLeadingEquals(formula);

  // Step 2: Remove whitespace outside quotes
  let whitespaceStrippedFormula = removeWhitespaceOutsideQuotes(adjustedFormula);

  // Step 3: Convert all double quotes to single quotes
  whitespaceStrippedFormula = convertToSingleQuotes(whitespaceStrippedFormula);

  // Step 4: Check for matching quotes
  checkQuotes(whitespaceStrippedFormula);

  // Step 5: Validate characters outside of quotes
  validateCharactersOutsideQuotes(whitespaceStrippedFormula);

  // Step 6: Fix and validate brackets
  let finalFormula = validateAndFixBrackets(whitespaceStrippedFormula);

  // Step 7: Validate that only allowed non-alphanumeric combinations are present
  validateNonAlphanumericCombinations(finalFormula);

  // Step 8: Validate symbol placement rules
  validateSymbolPlacement(finalFormula);

  // Step 9: Re-add the leading '=' if it was present
  if (hasLeadingEquals) {
    finalFormula = '=' + finalFormula;
  }

  return finalFormula;
};

// FormulaValidator component
const FormulaValidatorCustomDriver = ({ formula, onValidationResult }) => {
  useEffect(() => {
    try {
      const correctedFormula = validateAndAdjustFormula(formula);
      console.log("Validation successful. Corrected formula:", correctedFormula);
      onValidationResult(null, correctedFormula); // No errors, pass the corrected formula
    } catch (error) {
      console.log("Validation failed. Error message:", error.message);
      onValidationResult(error.message); // Pass the error message
    }
  }, [formula, onValidationResult]);

  return null; // No UI needed for this component
};

export default FormulaValidatorCustomDriver;
