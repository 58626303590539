// SpreadsheetSelector.jsx

import React, { useState, useEffect, useCallback } from 'react';

const SpreadsheetSelector = ({
  spreadsheets,
  selectedSpreadsheet,
  setSelectedSpreadsheet,
  selectedSpreadsheets,
  setSelectedSpreadsheets,
  isConsolidated,
  selectedCategory,
  setSelectedCategory,
  selectedVariable,
  setSelectedVariable,
  selectedDateColumn,
  setSelectedDateColumn,
  categoryValues,
  setCategoryValues,
  selectedCategoryValue,
  setSelectedCategoryValue,
  handleCategoryValueChange,
  fetchSpreadsheetData,
  processedData,
  currentOrgId,
  isEditable, // Determines if the selector is editable
}) => {
  const [expandedSuborgs, setExpandedSuborgs] = useState({});

  // Toggle the expansion state of a suborganisation folder
  const toggleSuborgExpand = (suborg) => {
    setExpandedSuborgs((prevExpanded) => ({
      ...prevExpanded,
      [suborg]: !prevExpanded[suborg],
    }));
  };

  // Group spreadsheets by suborganisation for the folder system
  const groupedSpreadsheets = spreadsheets.reduce((groups, spreadsheet) => {
    const suborg = spreadsheet.suborganisation || 'No Suborganisation';
    if (!groups[suborg]) {
      groups[suborg] = [];
    }
    groups[suborg].push(spreadsheet);
    return groups;
  }, {});

  // Handler for single spreadsheet selection (radio buttons)
  const handleSingleSelection = useCallback(
    (spreadsheetId) => {
      setSelectedSpreadsheet(spreadsheetId);
      setSelectedSpreadsheets([]); // Clear any consolidated selections
      // Reset dependent fields
      setSelectedCategory('');
      setSelectedVariable('');
      setSelectedDateColumn('');
      setCategoryValues([]);
      setSelectedCategoryValue('');
      if (!isEditable) {
        handleCategoryValueChange('');
      }
    },
    [
      setSelectedSpreadsheet,
      setSelectedSpreadsheets,
      setSelectedCategory,
      setSelectedVariable,
      setSelectedDateColumn,
      setCategoryValues,
      setSelectedCategoryValue,
      handleCategoryValueChange,
      isEditable,
    ]
  );

  // Handler for consolidated spreadsheet selection (checkboxes)
  const handleConsolidatedSelection = useCallback(
    (spreadsheetId) => {
      setSelectedSpreadsheets((prevSelected) => {
        if (prevSelected.includes(spreadsheetId)) {
          return prevSelected.filter((id) => id !== spreadsheetId);
        } else {
          return [...prevSelected, spreadsheetId];
        }
      });
      // Reset dependent fields when selection changes
      setSelectedCategory('');
      setSelectedVariable('');
      setSelectedDateColumn('');
      setCategoryValues([]);
      setSelectedCategoryValue('');
      if (!isEditable) {
        handleCategoryValueChange('');
      }
    },
    [
      setSelectedSpreadsheets,
      setSelectedCategory,
      setSelectedVariable,
      setSelectedDateColumn,
      setCategoryValues,
      setSelectedCategoryValue,
      handleCategoryValueChange,
      isEditable,
    ]
  );

  // Handler for category change
  const handleCategoryChange = useCallback(
    (e) => {
      const selectedCategoryNew = e.target.value;
      if (selectedCategory !== selectedCategoryNew) {
        setSelectedCategory(selectedCategoryNew);
        setSelectedCategoryValue(''); // Reset category value when category changes

        if (!isEditable) {
          handleCategoryValueChange('');
        }
      }
    },
    [
      selectedCategory,
      setSelectedCategory,
      setSelectedCategoryValue,
      handleCategoryValueChange,
      isEditable,
    ]
  );

  // Handler for category value selection
  const handleCategoryValueSelect = useCallback(
    (e) => {
      const value = e.target.value;
      if (selectedCategoryValue !== value) {
        setSelectedCategoryValue(value);
        if (handleCategoryValueChange) {
          handleCategoryValueChange(value);
        }
      }
    },
    [selectedCategoryValue, setSelectedCategoryValue, handleCategoryValueChange]
  );

  // Fetch spreadsheet data when selections change
  useEffect(() => {
    if (isConsolidated && selectedSpreadsheets.length > 0) {
      // Consolidated mode: Fetch data for all selected spreadsheets
      selectedSpreadsheets.forEach((spreadsheetId) => {
        const spreadsheet = spreadsheets.find((s) => s.id === spreadsheetId);
        if (spreadsheet) {
          fetchSpreadsheetData(spreadsheetId, spreadsheet);
        } else {
          console.error('Selected spreadsheet not found:', spreadsheetId);
        }
      });
    } else if (!isConsolidated && selectedSpreadsheet) {
      // Single selection mode: Fetch data for the selected spreadsheet
      const spreadsheet = spreadsheets.find((s) => s.id === selectedSpreadsheet);
      if (spreadsheet) {
        fetchSpreadsheetData(selectedSpreadsheet, spreadsheet);
      } else {
        console.error('Selected spreadsheet not found:', selectedSpreadsheet);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpreadsheet, selectedSpreadsheets, isConsolidated]);

    // Automatically set the selectedDateColumn to the primary date column
  useEffect(() => {
    if (processedData && processedData.dateCols && processedData.dateCols.length === 1) {
      setSelectedDateColumn(processedData.dateCols[0]);
    }
  }, [processedData, setSelectedDateColumn]);

  // Populate categoryValues when selectedCategory and processedData change
  useEffect(() => {
    if (selectedCategory && processedData && processedData.filteredHeaders) {
      const categoryIndex = processedData.filteredHeaders.indexOf(selectedCategory);
      console.log('Selected category:', selectedCategory, 'Category index:', categoryIndex);

      if (categoryIndex === -1) {
        console.warn('Category not found in filteredHeaders');
        return;
      }

      // Log a sample row to check the structure
      if (processedData.processedRows.length > 0) {
        console.log('Sample row:', processedData.processedRows[0]);
      }

      let uniqueValues = [
        ...new Set(processedData.processedRows.map((row) => row[categoryIndex])),
      ];

      console.log('Unique values extracted:', uniqueValues); // Log unique values

      // Add 'Total' if relevant to your logic
      uniqueValues.unshift('Total');

      console.log('Unique Category Values with Total:', uniqueValues);
      setCategoryValues(uniqueValues); // Set the unique values
      
      // Automatically set selectedCategoryValue to the first value if not set
      if (uniqueValues.length > 0 && !selectedCategoryValue) {
        setSelectedCategoryValue(uniqueValues[0]);
        if (handleCategoryValueChange) {
          handleCategoryValueChange(uniqueValues[0]);
        }
      }
    } else {
      setCategoryValues([]); // Clear category values if selectedCategory is not set or processedData is not available
      setSelectedCategoryValue('');
    }
  }, [selectedCategory, processedData, setCategoryValues]);

    // Automatically set selectedCategory to the first available category when processedData is available
    useEffect(() => {
      if (
        processedData &&
        processedData.filteredHeaders &&
        processedData.filteredHeaders.filter((header) => processedData.catCols.includes(header)).length > 0 &&
        !selectedCategory
      ) {
        const firstCategory = processedData.filteredHeaders.find((header) =>
          processedData.catCols.includes(header)
        );
        if (firstCategory) {
          setSelectedCategory(firstCategory);
        }
      }
    }, [processedData, setSelectedCategory, selectedCategory]);

  // Filter spreadsheets based on the organization
  const filteredSpreadsheets = spreadsheets.filter(
    (spreadsheet) => spreadsheet.organisationId === currentOrgId
  );

  return (
    <div>
      {isConsolidated ? (
        <div>
          <h4>Select Spreadsheets to Consolidate:</h4>
          {Object.keys(groupedSpreadsheets).map((suborg) => (
            <div key={suborg}>
              <div
                onClick={() => toggleSuborgExpand(suborg)}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                {expandedSuborgs[suborg] ? '▼' : '▶'} {suborg}
              </div>
              {expandedSuborgs[suborg] && (
                <div style={{ marginLeft: '20px' }}>
                  {groupedSpreadsheets[suborg].map((spreadsheet) => (
                    <div key={spreadsheet.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedSpreadsheets.includes(spreadsheet.id)}
                          onChange={() => handleConsolidatedSelection(spreadsheet.id)}
                          disabled={!isEditable}
                        />
                        {spreadsheet.newName}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (<></>
      )}

      {processedData && (
        <>
          <div>
            <label htmlFor="category-select">Select Category:</label>
            <select
              id="category-select"
              value={selectedCategory}
              onChange={handleCategoryChange}
              disabled={!isEditable}
            >
              {/*<option value="" disabled>
                Select category
              </option>*/}
              {processedData.filteredHeaders
                .filter((header) => processedData.catCols.includes(header))
                .map((header, index) => (
                  <option key={index} value={header}>
                    {header}
                  </option>
                ))}
            </select>
          </div>

          {/* Render the Category Value dropdown */}
          {selectedCategory && categoryValues.length > 0 && isEditable && (
            <div>
              <label htmlFor="category-value-select">Select Category Value:</label>
              <select
                id="category-value-select"
                value={selectedCategoryValue}
                onChange={handleCategoryValueSelect}
                disabled={!isEditable}
              >
                {/*<option value="" disabled>
                  Select category value
                </option>*/}
                {categoryValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SpreadsheetSelector;
