import api from "../api";

const overwritePeriods = (existingArray, newData, type, histPeriods, forePeriods) => {
    const updatedArray = [...existingArray]; // Clone to avoid mutation

    console.log(existingArray, updatedArray, updatedArray.length, newData, histPeriods, forePeriods)

    histPeriods = parseFloat(histPeriods)
    forePeriods = parseFloat(forePeriods)
  
    if (type === 'historical') {
      const start = updatedArray.length - histPeriods;
      console.log('start: ', start)
      for (let i = 0; i < histPeriods; i++) {
        updatedArray[start + i] = newData[i] || '0';
      }
    } else if (type === 'forecasting') {
      for (let i = 0; i < forePeriods; i++) {
        updatedArray[i] = newData[i] || '0.00';
      }
    }
  
    return updatedArray;
  };

export const importCustomDriverRow = async ({
  rowName,
  customDriverRows,
  currentModel,
  historicalAmounts,
  onRowAdded,
  onClose,
  setIsSubmitting,
  histLabels,
  foreLabels,
  selectedCategoryValue, 
  selectedCategory,
  selectedVariable,
  selectedDateColumn,
  sourceFile
}) => {
  try {
    const formulaRow = customDriverRows.find(row => row.driverType === 'Formula');
    if (!formulaRow) {
      throw new Error('Formula row is missing in customDriverRows.');
    }

    const subrows = customDriverRows.filter(row => row.driverType !== 'Formula');

    const customDriverModelData = {
      organisationId: currentModel.organisationId,
      superModelId: currentModel.superModelId,
      modelName: rowName,
      modelType: 'Custom Driver',
      basis: 'None',
      histPeriods: currentModel.histPeriods,
      forePeriods: currentModel.forePeriods,
      yearEnd: currentModel.yearEnd,
      annualHistHeaders: currentModel.annualHistHeaders, // Empty arrays for non-applicable bases
      quarterlyHistHeaders: currentModel.quarterlyHistHeaders,
      monthlyHistHeaders: currentModel.monthlyHistHeaders,
      annualForeHeaders: currentModel.annualForeHeaders,
      quarterlyForeHeaders: currentModel.quarterlyForeHeaders,
      monthlyForeHeaders: currentModel.monthlyForeHeaders
    };

    console.log('Custom Driver Model Data to Send:', customDriverModelData);

    const customDriverModelResponse = await api.post('/models', customDriverModelData);

    if (customDriverModelResponse.status !== 201) {
      throw new Error(
        `Failed to create main custom driver row: ${customDriverModelResponse.data.message}`
      );
    }

    console.log('Custom Driver Model successfully imported');
    const customDriverModel = customDriverModelResponse.data.model;

    const basis = currentModel.basis; // e.g., 'Quarterly'

    

    for (const subrow of subrows) {
      const {
        rowName: subRowName,
        driverType: subDriverType,
        forecastInputs: subForecastInputs,
        forecastResults: subForecastResults,
        historicalValues: subHistoricalValues,
        historicalDifferences: subHistoricalDifferences,
      } = subrow;

      // **Prepare Historical Data for Subrow (if applicable)**
      const subHistRows = histLabels.map(label => subHistoricalValues[label] || '0');

      // **Prepare Forecast Data for Subrow**
      const subForeRows = foreLabels.map(label => subForecastResults[label] || '0.00');

      const subForeValues = foreLabels.map(label => subForecastInputs[label] || '0.00')

      console.log('subhistrows: ', subHistRows)
      console.log('subforerows: ', subForeRows)
      console.log('subforevalues: ', subForeValues)

      // **Prepare the Subrow Model Data**
      const subrowData = {
        rowNames: [subRowName],
        rowVariables: ['None'], // Adjust as needed
        categories: ['None'], // Adjust as needed
        groupedOver: ['None'], // Adjust as needed
        dateColumn: ['None'], // Adjust as needed
        sourceFile: ['None'], // Adjust as needed
        drivers: [subDriverType],
        supportingAssumptionModelIds: [''],
        annualHistRows: [Array(10).fill('')], // Empty for non-applicable bases
        quarterlyHistRows: [Array(40).fill('')], // Will be populated based on the current basis
        monthlyHistRows: [Array(120).fill('')],
        annualForeRows: [Array(10).fill('')], // Empty for non-applicable bases
        quarterlyForeRows: [Array(40).fill('')], // Will be populated based on the current basis
        monthlyForeRows: [Array(120).fill('')],
        annualForeValues: [Array(10).fill('')], // Empty for non-applicable bases
        quarterlyForeValues: [Array(40).fill('')], // Will be populated based on the current basis
        monthlyForeValues: [Array(120).fill('')], // Will be populated based on the current basis
      };

      // **Populate Historical and Forecast Data for Subrow**
      if (basis === 'Annual') {
        subrowData.annualHistRows = [overwritePeriods(Array(10).fill(''), subHistRows, 'historical', currentModel.histPeriods, currentModel.forePeriods)];
        subrowData.annualForeRows = [overwritePeriods(Array(10).fill(''), subForeRows, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
        subrowData.annualForeValues = [overwritePeriods(Array(10).fill(''), subForeValues, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
      } else if (basis === 'Quarterly') {
        subrowData.quarterlyHistRows = [overwritePeriods(Array(40).fill(''), subHistRows, 'historical', currentModel.histPeriods, currentModel.forePeriods)];
        subrowData.quarterlyForeRows = [overwritePeriods(Array(40).fill(''), subForeRows, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
        subrowData.quarterlyForeValues = [overwritePeriods(Array(40).fill(''), subForeValues, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
      } else if (basis === 'Monthly') {
        subrowData.monthlyHistRows = [overwritePeriods(Array(120).fill(''), subHistRows, 'historical', currentModel.histPeriods, currentModel.forePeriods)];
        subrowData.monthlyForeRows = [overwritePeriods(Array(120).fill(''), subForeRows, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
        subrowData.monthlyForeValues = [overwritePeriods(Array(120).fill(''), subForeValues, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
      }

      console.log(`Subrow "${subRowName}" Data to Send:`, subrowData);

      // **Create the Subrow Model**
      const subrowResponse = await api.put(`/models/${customDriverModel.id}/add-row`, subrowData);

      if (subrowResponse.status !== 200) {
        console.warn(
          `Failed to create subrow "${subRowName}": ${subrowResponse.data.message}`
        );
        alert(
          `Failed to create subrow "${subRowName}": ${subrowResponse.data.message}`
        );
        // Continue creating other subrows even if one fails
        continue;
      }

      console.log(`Subrow "${subRowName}" successfully imported`);
    }

    const formulaHistData = histLabels.map(label => historicalAmounts[label] || '0');
    const formulaForeData = foreLabels.map(label => formulaRow.forecastResults[label] || '0.00');

    const mainModelData = {
        rowNames: [rowName],
        rowVariables: [selectedCategoryValue], // Adjust as needed
        categories: [selectedCategory], // Adjust as needed
        groupedOver: selectedVariable, // Adjust as needed
        dateColumn: selectedDateColumn, // Adjust as needed
        sourceFile: sourceFile, // Adjust as needed
        drivers: ['Custom Driver'],
        supportingAssumptionModelIds: [customDriverModel.id],
        annualHistRows: [Array(10).fill('')], // Empty for non-applicable bases
        quarterlyHistRows: [Array(40).fill('')], // Will be populated based on the current basis
        monthlyHistRows: [Array(120).fill('')],
        annualForeRows: [Array(10).fill('')], // Empty for non-applicable bases
        quarterlyForeRows: [Array(40).fill('')], // Will be populated based on the current basis
        monthlyForeRows: [Array(120).fill('')],
        annualForeValues: [Array(10).fill('')], // Empty for non-applicable bases
        quarterlyForeValues: [Array(40).fill('')], // Will be populated based on the current basis
        monthlyForeValues: [Array(120).fill('')], // Will be populated based on the current basis
      };

      // **Populate Historical and Forecast Data for Subrow**
      if (basis === 'Annual') {
        mainModelData.annualHistRows = [overwritePeriods(Array(10).fill(''), formulaHistData, 'historical', currentModel.histPeriods, currentModel.forePeriods)];
        mainModelData.annualForeRows = [overwritePeriods(Array(10).fill(''), formulaForeData, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
        mainModelData.annualForeValues = [overwritePeriods(Array(10).fill(''), formulaForeData, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
      } else if (basis === 'Quarterly') {
        mainModelData.quarterlyHistRows = [overwritePeriods(Array(40).fill(''), formulaHistData, 'historical', currentModel.histPeriods, currentModel.forePeriods)];
        mainModelData.quarterlyForeRows = [overwritePeriods(Array(40).fill(''), formulaForeData, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
        mainModelData.quarterlyForeValues = [overwritePeriods(Array(40).fill(''), formulaForeData, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
      } else if (basis === 'Monthly') {
        mainModelData.monthlyHistRows = [overwritePeriods(Array(120).fill(''), formulaHistData, 'historical', currentModel.histPeriods, currentModel.forePeriods)];
        mainModelData.monthlyForeRows = [overwritePeriods(Array(120).fill(''), formulaForeData, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
        mainModelData.monthlyForeValues = [overwritePeriods(Array(120).fill(''), formulaForeData, 'forecasting', currentModel.histPeriods, currentModel.forePeriods)];
      }

      console.log(`Main Model Data to Send:`, mainModelData);     
      
        // **Create the Subrow Model**
        const mainModelResponse = await api.put(`/models/${currentModel.id}/add-row`, mainModelData);

        if (mainModelResponse.status !== 200) {
            console.warn(
            `Failed to add row: ${mainModelResponse.data.message}`
            );
            alert(
            `Failed to add row: ${mainModelResponse.data.message}`
            );
        }

        console.log(`Custom Driver row successfully imported`);

    onRowAdded(mainModelResponse.data.model);
    onClose();
  } catch (error) {
    console.error('Error importing custom driver row data:', error);
    alert(
      `An error occurred while importing custom driver row data: ${error.message}`
    );
    setIsSubmitting(false);
  }
};
