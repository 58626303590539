import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import api from '../api';
import SpreadsheetSelector from './spreadsheetselector';
import fetchSpreadsheetData from '../fetchspreadsheetdata';
import * as XLSX from 'xlsx';
import { useAuth } from '../authcontext'; // Import the useAuth hook
import FormulaValidator from '../formulavalidator';
import FormulaValidatorCustomDriver from './formulavalidatorcustomdriver';
import { evaluateSumFormula } from './evaluatesumformula';
import { evaluateCustomDriverFormula } from './evaluatecustomdriverformula';
import { importCustomDriverRow } from './importcustomdriverrow';
import RowNameAutosuggest from './rownameautosuggest';

const AddRowForm = ({
  currentModel,
  spreadsheets,
  onRowAdded,
  onClose,
  isEditing,
  rowData,
  rowIndex,
  onSave,
}) => {
  // States to track modes
  const [isHistorical, setIsHistorical] = useState(false);
  const [isConsolidated, setIsConsolidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingSetup, setIsEditingSetup] = useState(false);

  // States to hold spreadsheet data
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState('');
  const [selectedSpreadsheets, setSelectedSpreadsheets] = useState([]); // For consolidated mode
  const [processedData, setProcessedData] = useState(null);

  // States to hold spreadsheet filtering options
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [selectedDateColumn, setSelectedDateColumn] = useState('');
  const [categoryValues, setCategoryValues] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');

  // States to hold other row setup options
  const [rowName, setRowName] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('Fixed Amount');
  const [selectedPeriodOption, setSelectedPeriodOption] = useState('Previous period'); // Default to 'Previous period'
  const [specificPeriod, setSpecificPeriod] = useState(''); // To hold the user-entered specific period
  const [selectedReferenceRow, setSelectedReferenceRow] = useState(''); // New State for Reference Row

  // States to hold popup table row values
  const [forecastInputs, setForecastInputs] = useState({});
  const [forecastAmounts, setForecastAmounts] = useState({});
  const [referenceRowForecastValues, setReferenceRowForecastValues] = useState([]); // New state for reference row forecast values
  const [historicalAmounts, setHistoricalAmounts] = useState({});
  const [allHistoricalAmounts, setAllHistoricalAmounts] = useState({});
  const [tableRows, setTableRows] = useState([]);

  // Formula of rows specific states
  const [sumFormulaInput, setSumFormulaInput] = useState(''); // To hold the user's formula input
  const [sumValidationMessage, setSumValidationMessage] = useState(null); // To display validation results
  const [sumFormulaToValidate, setSumFormulaToValidate] = useState(''); // To trigger validation
  const [calculatedHistoricalValues, setCalculatedHistoricalValues] = useState({});
  const [calculatedForecastValues, setCalculatedForecastValues] = useState({});

  //Custom Driver specific states
  const [customDriverFormula, setCustomDriverFormula] = useState(''); // New state for Custom Driver formula
  const [customDriverValidationMessage, setCustomDriverValidationMessage] = useState(null); // Validation message
  const [customCalculatedHistoricalValues, setCustomCalculatedHistoricalValues] = useState({});
  const [customFormulaToValidate, setCustomFormulaToValidate] = useState(''); // New state for Custom Driver formula validation
  const [customDriverRows, setCustomDriverRows] = useState([]);


  const { editedRows } = useAuth(); // Get editedRows from useAuth


  // Helper function for shallow comparison
  const isObjectShallowEqual = useCallback((obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== typeof obj2) return false;
    if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) return false;
    
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) {
      console.log('Different number of keys:', keys1.length, keys2.length);
      return false;
    }
    
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        console.log(`Difference found at key "${key}":`, obj1[key], obj2[key]);
        return false;
      }
    }
    
    return true;
  }, []);  

  // Helper function to parse Excel dates
  const parseExcelDate = useCallback((excelDate) => {
    if (typeof excelDate === 'number') {
      const date = XLSX.SSF.parse_date_code(excelDate);
      if (date) {
        return moment({ y: date.y, M: date.m - 1, d: date.d });
      }
    } else if (typeof excelDate === 'string') {
      const parsedDate = moment(excelDate, ['DD/MM/YYYY', 'YYYY-MM-DD']);
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }
    return moment.invalid(); // Return an invalid moment if parsing fails
  }, []);

  // Handler to update spreadsheets data
  const loadSpreadsheetData = useCallback(
    async (spreadsheetId) => {
      const currentSpreadsheet = spreadsheets.find((s) => s.id === spreadsheetId);
      if (!currentSpreadsheet) {
        console.error('Spreadsheet not found:', spreadsheetId);
        return;
      }
      const data = await fetchSpreadsheetData(
        spreadsheetId,
        currentSpreadsheet,
        editedRows,
        true,
        true,
        true
      );
      console.log('Fetched processed data:', data); // Add this log
      setProcessedData(data);
    },
    [spreadsheets, editedRows, fetchSpreadsheetData]
  );

  // Helper function to calculate sum for a period
  const calculateSumForPeriod = useCallback(
    (startDate, endDate, processedData, categoryValue = selectedCategoryValue) => {
      if (
        !processedData ||
        !selectedCategory ||
        !selectedVariable ||
        !selectedDateColumn
      ) {
        console.log('Missing required data or selections');
        return 0;
      }

      const categoryIndex = processedData.filteredHeaders.indexOf(selectedCategory);
      const variableIndex = processedData.filteredHeaders.indexOf(selectedVariable);
      const dateIndex = processedData.filteredHeaders.indexOf(selectedDateColumn);

      if (categoryIndex === -1 || variableIndex === -1 || dateIndex === -1) {
        console.error('Invalid indices for category/variable/date');
        return 0;
      }

      // Parse startDate and endDate into moment objects with strict parsing
      const periodStartDate = moment(startDate, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);
      const periodEndDate = moment(endDate, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);

      if (!periodStartDate.isValid() || !periodEndDate.isValid()) {
        console.error('Invalid period startDate or endDate');
        return 0;
      }

      const sum = processedData.processedRows.reduce((total, row, rowIndex) => {
        const rowDate = parseExcelDate(row[dateIndex]);

        const isInPeriod =
          rowDate.isValid() &&
          rowDate.isBetween(periodStartDate, periodEndDate, undefined, '[]');

        let categoryMatch = true;
        if (categoryValue && categoryValue !== 'Total') {
          categoryMatch = row[categoryIndex] === categoryValue;
        }

        if (categoryMatch && isInPeriod) {
          const value = parseFloat(row[variableIndex]);
          if (!isNaN(value)) {
            return total + value;
          } else {
            console.warn(`Invalid value at row index ${rowIndex}:`, row[variableIndex]);
          }
        }

        return total;
      }, 0);
      return sum;
    },
    [parseExcelDate, selectedCategory, selectedVariable, selectedDateColumn, selectedCategoryValue]
  );


  const getHeaderData = () => {
    const { basis, histPeriods, forePeriods } = currentModel;
    let histHeadersRaw = [];
    let foreHeadersRaw = [];

    try {
      switch (basis) {
        case 'Yearly':
          histHeadersRaw = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-histPeriods);
          foreHeadersRaw = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, forePeriods);
          break;
        case 'Quarterly':
          histHeadersRaw = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-histPeriods);
          foreHeadersRaw = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, forePeriods);
          break;
        case 'Monthly':
          histHeadersRaw = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-histPeriods);
          foreHeadersRaw = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, forePeriods);
          break;
        default:
          console.error('Invalid model basis');
          break;
      }
    } catch (error) {
      console.error('Error parsing headers:', error);
    }

    // Extract labels only
    const histLabels = histHeadersRaw.map((header) => header[0]);
    const foreLabels = foreHeadersRaw.map((header) => header[0]);

    // Extract labels with dates
    const histHeaders = histHeadersRaw.map((header) => ({
      label: header[0],
      startDate: header[1],
      endDate: header[2],
    }));
    const foreHeaders = foreHeadersRaw.map((header) => ({
      label: header[0],
      startDate: header[1],
      endDate: header[2],
    }));

    // Combine labels
    const combinedLabels = [...histLabels, ...foreLabels];

    console.log('histHeaders: ', histHeaders, 'combinedLabels: ', combinedLabels)

    return {
      histHeaders,
      foreHeaders,
      histLabels,
      foreLabels,
      combinedLabels,
    };
  };


  // Helper function to decide how many periods to look back when dealing with offset
  const getPeriodsToLookBack = () => {
    if (selectedPeriodOption === 'Previous period') {
      return 1;
    } else if (selectedPeriodOption === 'Prior year month') {
      switch (currentModel.basis) {
        case 'Yearly':
          return 1;
        case 'Quarterly':
          return 4;
        case 'Monthly':
          return 12;
        default:
          console.error('Invalid model basis');
          return 1;
      }
    } else if (selectedPeriodOption === 'Specific period') {
      const period = parseInt(specificPeriod, 10);
      return isNaN(period) || period < 1 ? 1 : period;
    }
    return 1; // Default fallback
  };

  // Inside AddRowForm component

  // Function to extract numerical columns with their spreadsheet names
  const getNumericalColumns = useCallback(() => {
    const numericalColumns = [];

    spreadsheets.forEach((spreadsheet) => {
      // Assuming columnStatus is a JSON string representing an array of column statuses
      const columnStatuses = spreadsheet.columnStatus;

      // Assuming originalHeaders is a JSON string representing an array of column names
      const editedHeaders = spreadsheet.editedHeaders;

      columnStatuses.forEach((status, index) => {
        if (status === 'NumericalEditable') {
          numericalColumns.push({
            columnName: editedHeaders[index],
            spreadsheetName: spreadsheet.newName, // Use newName as per your schema
            spreadsheetId: spreadsheet.id, // Include spreadsheet ID for reference
            columnIndex: index, // Optional: To reference the column if needed
          });
        }
      });
    });

    return numericalColumns;
  }, [spreadsheets]);

  const numericalColumns = useMemo(() => getNumericalColumns(), [getNumericalColumns]);

  // Handler for selecting a numerical column from autosuggest
  const handleRowNameSelect = (selectedOption) => {
    if (selectedOption) {
      const formattedValue = `${selectedOption.columnName} - ${selectedOption.spreadsheetName}`;
      setRowName(formattedValue);
      setSelectedSpreadsheet(selectedOption.spreadsheetId);
      setSelectedVariable(selectedOption.columnName);
      setIsHistorical(true);
    }
  };
  
  // Helper function to recalculate forecast amounts, including Custom Drivers
  const recalculateForecastAmounts = useCallback(
    (inputs, periods, customRows = []) => {
      const updatedForecastAmounts = {};
      const offset = getPeriodsToLookBack();

      console.log('offset: ', offset);
      console.log('allhistamounts: ', allHistoricalAmounts);

      // Initialize forecast amounts with base values from standard drivers
      for (let i = 0; i < periods.length; i++) {
        const currentPeriodLabel = periods[i];

        if (i < currentModel.histPeriods) {
          // Skip historical periods
          continue;
        }

        // Determine the index of the period to look back
        const lookBackIndex = i - offset;

        let baseAmount = 0;
        if (lookBackIndex < 0) {
          console.warn(`Look-back index ${lookBackIndex} is out of bounds. Using 0 as base amount.`);
          baseAmount = 0;
        } else {
          const basePeriodLabel = periods[lookBackIndex] || '';
          baseAmount = parseFloat(
            (isHistorical ? allHistoricalAmounts[basePeriodLabel] : historicalAmounts[basePeriodLabel]) || forecastAmounts[basePeriodLabel] || 0);
        }

        // Calculate based on the selected standard driver
        if (selectedDriver === 'Fixed Amount') {
          const inputAmount = parseFloat(inputs[currentPeriodLabel]) || 0;
          updatedForecastAmounts[currentPeriodLabel] = inputAmount.toFixed(2);
        } else if (selectedDriver === 'Fixed Growth') {
          const growthAmount = parseFloat(inputs[currentPeriodLabel]) || 0;
          const newAmount = baseAmount + growthAmount;
          updatedForecastAmounts[currentPeriodLabel] = newAmount.toFixed(2);
        } else if (selectedDriver === 'Percent Increase') {
          const percentageIncrease = parseFloat(inputs[currentPeriodLabel]) || 0;
          const newAmount = baseAmount + (baseAmount * percentageIncrease) / 100;
          updatedForecastAmounts[currentPeriodLabel] = newAmount.toFixed(2);
        } else if (selectedDriver === 'Percent of Another Row') {
          console.log('refrowforevalues: ', referenceRowForecastValues)
          // **Handling "Percent of Another Row" Driver**
          if (referenceRowForecastValues && referenceRowForecastValues[i - currentModel.histPeriods] !== undefined) {
            const referenceForecastValue = parseFloat(referenceRowForecastValues[i - currentModel.histPeriods]) || 0;
            const percentValue = parseFloat(inputs[currentPeriodLabel]) || 0;
            const calculatedAmount = (referenceForecastValue * percentValue) / 100;
            updatedForecastAmounts[currentPeriodLabel] = calculatedAmount.toFixed(2);
          } else {
            console.warn(`Reference forecast value for period "${currentPeriodLabel}" is not available.`);
            updatedForecastAmounts[currentPeriodLabel] = '0.00';
          }
        }

        // **Handle Custom Drivers**
        customRows.forEach(customRow => {
          const customPeriodInput = parseFloat(customRow.forecastInputs[currentPeriodLabel]) || 0;

          if (customRow.driverType === 'Fixed Growth') {
            // Add fixed growth amount to the current forecast amount
            updatedForecastAmounts[currentPeriodLabel] = (
              parseFloat(updatedForecastAmounts[currentPeriodLabel] || 0) + customPeriodInput
            ).toFixed(2);
          } else if (customRow.driverType === 'Percent Increase') {
            // Apply percentage increase to the current forecast amount
            updatedForecastAmounts[currentPeriodLabel] = (
              parseFloat(updatedForecastAmounts[currentPeriodLabel] || 0) +
              (parseFloat(updatedForecastAmounts[currentPeriodLabel] || 0) * customPeriodInput) / 100
            ).toFixed(2);
          } else if (customRow.driverType === 'Fixed Amount') {
            // Add fixed amount to the current forecast amount
            updatedForecastAmounts[currentPeriodLabel] = (
              parseFloat(updatedForecastAmounts[currentPeriodLabel] || 0) + customPeriodInput
            ).toFixed(2);
          } else if (customRow.driverType === 'Fixed Percent') {
            // Apply fixed percentage to the current forecast amount
            updatedForecastAmounts[currentPeriodLabel] = (
              parseFloat(updatedForecastAmounts[currentPeriodLabel] || 0) +
              (parseFloat(updatedForecastAmounts[currentPeriodLabel] || 0) * customPeriodInput) / 100
            ).toFixed(2);
          }
        });
      }

      // Update the forecastAmounts state
      setForecastAmounts((prevForecastAmounts) => {
        if (!isObjectShallowEqual(updatedForecastAmounts, prevForecastAmounts)) {
          console.log('Updating forecastAmounts from:', prevForecastAmounts, 'to:', updatedForecastAmounts);
          return updatedForecastAmounts;
        }
        console.log('forecastAmounts unchanged, no update needed.');
        return prevForecastAmounts;
      });
    },
    [
      currentModel.histPeriods,
      allHistoricalAmounts,
      forecastAmounts,
      isObjectShallowEqual,
      selectedDriver,
      referenceRowForecastValues
    ]
  );

  // Helper function to recalculate historical amounts
  const recalculateHistoricalAmounts = useCallback(
    (newCategoryValue) => {
      if (processedData && currentModel.histPeriods) {
        const { histHeaders } = getHeaderData();
  
        const tempHistoricalAmounts = {};
        histHeaders.forEach((header) => {
          const sum = calculateSumForPeriod(
            header.startDate,
            header.endDate,
            processedData,
            newCategoryValue
          );
          tempHistoricalAmounts[header.label] = sum;
        });
  
        // Compare before setting state
        if (!isObjectShallowEqual(tempHistoricalAmounts, historicalAmounts)) {
          setHistoricalAmounts(tempHistoricalAmounts);
        }
      }
    },
    [processedData, currentModel, calculateSumForPeriod, historicalAmounts, isObjectShallowEqual]
  );

  // Handler function for category value changes
  const handleCategoryValueChange = useCallback(
    (value) => {
      if (selectedCategoryValue !== value) {
        setSelectedCategoryValue(value);
        recalculateHistoricalAmounts(value);
      }
    },
    [selectedCategoryValue, recalculateHistoricalAmounts]
  );

  // Handler for forecast input changes
  const handleForecastInputChange = useCallback(
    (period, value, periods) => {
      const parsedValue = parseFloat(value);
      console.log(`Entered forecast value for ${period}:`, parsedValue);
  
      setForecastInputs((prevState) => {
        const updatedInputs = {
          ...prevState,
          [period]: isNaN(parsedValue) ? '' : parsedValue, // Allow empty string for flexibility
        };
  
        console.log('Updated forecast inputs:', updatedInputs);
  
        // Recalculate forecast amounts whenever inputs change
        recalculateForecastAmounts(updatedInputs, periods);
  
        return updatedInputs;
      });
    },
    [recalculateForecastAmounts]
  );  

  // Handler to update historical amounts when edited
  const handleHistoricalAmountChange = useCallback((label, value) => {
    const parsedValue = isNaN(parseFloat(value)) ? '' : parseFloat(value);
    setHistoricalAmounts((prevState) => {
      if (prevState[label] === parsedValue) return prevState; // Prevent update if value is same
      return {
        ...prevState,
        [label]: parsedValue,
      };
    });
  }, []);  

  // Toggle between single and consolidated spreadsheet modes
  const handleToggleSpreadsheetMode = () => {
    setIsConsolidated((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setSelectedSpreadsheets([]); // Clear consolidated selections when switching back
      }
      return newState;
    });
  };
    
  const handleDeleteRow = useCallback(async () => {
    // Step 1: Prompt the user for confirmation
    const confirmDelete = window.confirm('Are you sure you want to delete this row? This action cannot be undone.');
  
    if (!confirmDelete) {
      console.log('Row deletion canceled by the user.');
      return;
    }
  
    console.log(`Attempting to delete row at index: ${rowIndex}`);
  
    try {
      // Step 2: Make an API call to delete the row
      const response = await api.delete(`/model/${currentModel.id}/delete-row`, {
        data: { rowIndex }, // The rowIndex is passed correctly here as part of the config object
      });
  
      if (response.status === 200) {
        console.log('Row successfully deleted:', response.data);
  
        // Step 3: Update the parent component or state
        const updatedModel = response.data.model; // Assuming the updated model is returned in the response
        onRowAdded(updatedModel); // Update the parent with the new data
  
        // Close the popup after successful deletion
        window.location.reload()
        onClose();
      } else {
        console.error('Failed to delete row:', response.data);
        alert(`Failed to delete row: ${response.data.message || 'Unknown error.'}`);
      }
    } catch (error) {
      console.error('Error deleting row:', error);
      alert('An error occurred while deleting the row. Please try again.');
    }
  }, [rowIndex, currentModel.id, onRowAdded, onClose, api]);  


  const generateTableRows = useCallback(
    (periods) => {
      const isFormulaOfRows = selectedDriver === 'Formula of Rows' && sumValidationMessage === "Formula is valid!";
      const isCustomDriver = selectedDriver === 'Custom Driver';
      console.log(`Generating table rows. isFormulaOfRows: ${isFormulaOfRows}, isCustomDriver: ${isCustomDriver}`);
  
      // Determine which calculated values to use
      const histValues = isFormulaOfRows
        ? Object.values(calculatedHistoricalValues).slice(-currentModel.histPeriods)
        : isCustomDriver
          ? Object.values(customCalculatedHistoricalValues).slice(-currentModel.histPeriods)
          : null;
  
      console.log('histValues: ', histValues);
      console.log('customDriverRows:', customDriverRows); // Log customDriverRows
  
      const rows = []; // Initialize the rows array
  
      /**
        * 1. Handle Standard Drivers
        */
      const standardGrowthRow = [];
      const standardAmountRow = [];
  
      console.log('historicalamounts: ', historicalAmounts)
  
      periods.forEach((label, i) => {
        console.log(`Period Label: ${label}, Forecast Input: ${forecastInputs[label]}`);
  
        if (i < currentModel.histPeriods) {
          // Historical Periods
          let sum;
          if (isFormulaOfRows) {
            sum = histValues && histValues[i] !== undefined ? histValues[i] : '0';
          } else {
            sum = historicalAmounts[label] !== undefined
              ? historicalAmounts[label]
              : (isHistorical ? '0' : '0');
          }
  
          // Determine if the historical amount should be editable
          const isEditableHistorical = !isHistorical || (isEditing && !isEditingSetup);
  
          standardAmountRow.push(
            <td key={`standard-amount-${i}`}>
              {(isEditableHistorical && selectedDriver !== 'Formula of Rows') ? (
                <input
                  type="number"
                  value={sum}
                  onChange={(e) => handleHistoricalAmountChange(label, e.target.value)}
                />
              ) : (
                sum
              )}
            </td>
          );
  
          // Handle different drivers for historical data
          switch (selectedDriver) {
            case 'Percent Increase':
              if (i === 0) {
                // First column should be blank for percentage change
                standardGrowthRow.push(<td key={`standard-percent-${i}`} />);
              } else {
                const previousSum = parseFloat(historicalAmounts[periods[i - 1]]) || 0;
                const currentSum = parseFloat(sum) || 0;
                const percentChange = previousSum !== 0 ? ((currentSum - previousSum) / previousSum) * 100 : 0;
                standardGrowthRow.push(
                  <td key={`standard-percent-${i}`}>
                    {percentChange.toFixed(2)}%
                  </td>
                );
              }
              break;
  
            case 'Fixed Growth':
              // For "Fixed Growth", calculate the difference from the previous value
              const change = i === 0 ? '' : (parseFloat(sum) - (parseFloat(historicalAmounts[periods[i - 1]]) || 0)).toFixed(2);
              standardGrowthRow.push(
                <td key={`standard-growth-${i}`}>
                  {change !== '' ? change : ''}
                </td>
              );
              break;
  
            case 'Percent of Another Row':
              // Leave this row blank for "Percent of Another Row" historical periods
              standardGrowthRow.push(<td key={`standard-growth-${i}`} />);
              break;
  
            default:
              // Handle other driver types if necessary
              standardGrowthRow.push(<td key={`standard-growth-${i}`} />);
              break;
          }
        } else {
          // Forecast Periods
          if (isFormulaOfRows) {
            const forecastIndex = i - currentModel.histPeriods;
            const value = calculatedForecastValues[forecastIndex] || '';
            standardAmountRow.push(<td key={`standard-fore-${i}`}>{value}</td>);
            // For "Formula of Rows", no growth row is needed for forecast periods
            standardGrowthRow.push(<td key={`standard-fore-growth-${i}`}></td>);
          } else if (isCustomDriver) {
            // **Custom Driver Handling: Do not aggregate forecastResults into the main amountRow**
            // Each custom driver sub-row manages its own inputs and results independently
            // Therefore, the main amountRow remains unaffected
            standardGrowthRow.push(<td key={`standard-fore-growth-${i}`}></td>);
            standardAmountRow.push(<td key={`standard-fore-${i}`}></td>); // Placeholder or leave empty
          } else {
            // Handling other drivers
            switch (selectedDriver) {
              case 'Percent Increase':
                // Input row for percentage changes for forecast periods
                standardGrowthRow.push(
                  <td key={`standard-percent-input-${i}`}>
                    <input
                      type="number"
                      value={forecastInputs[label] || ''}
                      onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                    />
                    %
                  </td>
                );
  
                // Row for forecasted amounts based on input percentages for "Percent"
                standardAmountRow.push(
                  <td key={`standard-fore-amount-${i}`}>
                    {forecastAmounts[label] !== undefined && forecastAmounts[label] !== ''
                      ? forecastAmounts[label]
                      : ''}
                  </td>
                );
                break;
  
              case 'Percent of Another Row':
                // Input row for percentage changes for "Percent of Another Row"
                standardGrowthRow.push(
                  <td key={`standard-percent-of-another-row-${i}`}>
                    <input
                      type="number"
                      value={forecastInputs[label] || ''}
                      onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                    />
                    %
                  </td>
                );
  
                // Row for forecasted amounts based on input percentages for "Percent of Another Row"
                const calculatedAmount = forecastAmounts[label] || '0.00';
  
                standardAmountRow.push(
                  <td key={`standard-fore-calculated-amount-${i}`}>
                    {calculatedAmount}
                  </td>
                );
                break;
  
              case 'Fixed Growth':
                // Input row for fixed growth
                standardGrowthRow.push(
                  <td key={`standard-fixed-growth-${i}`}>
                    <input
                      type="number"
                      value={forecastInputs[label] || ''}
                      onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                    />
                  </td>
                );
  
                // Use forecastAmounts[label] instead of recalculating
                const fixedGrowthAmount = forecastAmounts[label] || '0.00';
  
                standardAmountRow.push(<td key={`standard-fore-fixed-growth-amount-${i}`}>{fixedGrowthAmount}</td>);
                break;
  
              case 'Fixed Percent':
                // Input row for fixed percent
                standardGrowthRow.push(
                  <td key={`standard-fixed-percent-${i}`}>
                    <input
                      type="number"
                      value={forecastInputs[label] || ''}
                      onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                    />
                    %
                  </td>
                );
  
                // For "Fixed Percent", the forecast amounts are the same as input percentages, but stored as strings with '%'
                const fixedPercentAmount = forecastAmounts[label] || '0.00%';
  
                standardAmountRow.push(<td key={`standard-fore-fixed-percent-amount-${i}`}>{fixedPercentAmount}</td>);
                break;
  
              case 'Fixed Amount':
              case 'Formula of Rows':
                // Input row for fixed amounts
                standardAmountRow.push(
                  <td key={`standard-fore-fixed-amount-${i}`}>
                    <input
                      type="number"
                      value={forecastInputs[label] || ''}
                      onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                    />
                  </td>
                );
                // No growth input for Fixed Amount
                standardGrowthRow.push(<td key={`standard-fore-fixed-growth-${i}`}></td>);
                break;
  
              default:
                // Handle other driver types if necessary
                standardGrowthRow.push(<td key={`standard-fore-growth-default-${i}`}></td>);
                standardAmountRow.push(<td key={`standard-fore-amount-default-${i}`}></td>);
                break;
            }
          }
        }});
  
        // Render growth or percentage row based on driver
        if (['Percent Increase', 'Fixed Growth', 'Fixed Amount', 'Fixed Percent', 'Percent of Another Row'].includes(selectedDriver)) {
          rows.push(
            <tr key="standard-amount-row">
              <td>{rowName}</td>
              <td>
                <select
                  id={`driver-select-${rowName}`}
                  value={selectedDriver}
                  onChange={(e) => {
                    const newDriver = e.target.value;
                    setSelectedDriver(newDriver);
  
                    // Reset formula-related states when driver changes
                    if (
                      newDriver !== 'Formula of Rows' &&
                      newDriver !== 'Custom Driver'
                    ) {
                      setSumFormulaInput('');
                      setSumFormulaToValidate('');
  
                      setCustomDriverFormula('');
                      setCustomFormulaToValidate('');
                      setCustomDriverValidationMessage(null);
                      setCustomCalculatedHistoricalValues({});
                    }
  
                    // If "Custom Driver" is selected, reset any formula inputs
                    if (newDriver !== 'Custom Driver') {
                      setCustomDriverFormula('');
                      setCustomFormulaToValidate('');
                      setCustomDriverValidationMessage(null);
                      setCustomCalculatedHistoricalValues({});
                    }
                  }}
                  style={{ width: '150px' }}
                >
                  <option value="Fixed Amount">Fixed Amount</option>
                  <option value="Fixed Growth">Fixed Growth</option>
                  <option value="Percent Increase">Percent Increase</option>
                  <option value="Percent of Another Row">Percent of Another Row</option>
                  <option value="Formula of Rows">Formula of Rows</option>
                  <option value="Custom Driver">Custom Driver</option>
                </select>
              </td>
              {['Fixed Growth', 'Percent Increase'].includes(selectedDriver) && (
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <select
                    value={selectedPeriodOption}
                    onChange={(e) => setSelectedPeriodOption(e.target.value)}
                    style={{ width: '150px' }}
                  >
                    <option value="Previous period">Previous period</option>
                    <option value="Prior year month">Prior year month</option>
                    <option value="Specific period">Specific period</option>
                  </select>
                  {selectedPeriodOption === 'Specific period' && (
                    <input
                      type="number"
                      min="1"
                      placeholder="#"
                      value={specificPeriod}
                      onChange={(e) => setSpecificPeriod(e.target.value)}
                      className="number-input-no-spinner"
                      style={{ width: '30px', marginLeft: '5px' }} // Inline styling for narrow input
                    />
                  )}
                  </div>
                </td>
              )}
              {selectedDriver === 'Percent of Another Row' && (
                <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <select
                    id="reference-row-select"
                    value={selectedReferenceRow}
                    onChange={(e) => setSelectedReferenceRow(e.target.value)}
                  >
                    <option value="">Select Row</option>
                    {currentModel.rowNames && currentModel.rowNames.length > 0 ? (
                      currentModel.rowNames
                        .filter((row) => typeof row === 'string') // Filter out non-string entries
                        .map((row, index) => (
                          <option key={index} value={row}>
                            {row}
                          </option>
                        ))
                    ) : (
                      <option disabled>No rows available</option>
                    )}
                  </select>
                </div>
                </td>
              )}
              {standardAmountRow}
            </tr>
          );
  
          if (selectedDriver !== 'Fixed Amount' && selectedDriver !== 'Formula of Rows') {
            rows.push(
              <tr key="standard-growth-row">
                <td>{rowName}</td>
                <td>{
                  selectedDriver === 'Percent Increase' ? 'Percent Change' :
                  selectedDriver === 'Fixed Growth' ? 'Growth' :
                  selectedDriver === 'Fixed Percent' ? 'Fixed Percent Change' :
                  selectedDriver === 'Percent of Another Row' ? 'Percentage' : ''
                }</td>
                <td></td>
                {standardGrowthRow}
              </tr>
            );
          }
        }
  
        // **Handle Non-standard Drivers**
        if (selectedDriver === 'Formula of Rows') {
          rows.push(
            <tr key="standard-amount-row-default">
              <td>{rowName}</td>
              <td>
                <select
                  id={`driver-select-${rowName}-default`}
                  value={selectedDriver}
                  onChange={(e) => {
                    const newDriver = e.target.value;
                    setSelectedDriver(newDriver);
  
                    // Reset formula-related states when driver changes
                    if (
                      newDriver !== 'Formula of Rows' &&
                      newDriver !== 'Custom Driver'
                    ) {
                      setSumFormulaInput('');
                      setSumFormulaToValidate('');
  
                      setCustomDriverFormula('');
                      setCustomFormulaToValidate('');
                      setCustomDriverValidationMessage(null);
                      setCustomCalculatedHistoricalValues({});
                    }
  
                    // If "Custom Driver" is selected, reset any formula inputs
                    if (newDriver !== 'Custom Driver') {
                      setCustomDriverFormula('');
                      setCustomFormulaToValidate('');
                      setCustomDriverValidationMessage(null);
                      setCustomCalculatedHistoricalValues({});
                    }
                  }}
                  style={{ width: '150px' }}
                >
                  <option value="Fixed Amount">Fixed Amount</option>
                  <option value="Fixed Growth">Fixed Growth</option>
                  <option value="Percent Increase">Percent Increase</option>
                  <option value="Percent of Another Row">Percent of Another Row</option>
                  <option value="Formula of Rows">Formula of Rows</option>
                  <option value="Custom Driver">Custom Driver</option>
                </select>
              </td>
              {standardAmountRow}
            </tr>
          );
        }
        /**
          * Handle Custom Drivers (if applicable)
          */
        if (selectedDriver === 'Custom Driver') {
          console.log('Rendering Custom Driver Sub-Rows:', customDriverRows); // Log before rendering
  
          // Check if the formula has been validated
          const formulaRow = customDriverRows.find(row => row.driverType === 'Formula');


          rows.push(
            <tr key="custom-driver-row">
              <td>{rowName}</td>
              <td>
                <select
                  id={`custom-driver-select-${rowName}`}
                  value={selectedDriver}
                  onChange={(e) => {
                    const newDriver = e.target.value;
                    setSelectedDriver(newDriver);

                    // Reset formula-related states when driver changes
                    if (
                      newDriver !== 'Formula of Rows' &&
                      newDriver !== 'Custom Driver'
                    ) {
                      setSumFormulaInput('');
                      setSumFormulaToValidate('');

                      setCustomDriverFormula('');
                      setCustomFormulaToValidate('');
                      setCustomDriverValidationMessage(null);
                      setCustomCalculatedHistoricalValues({});
                    }

                    // If "Custom Driver" is selected, reset any formula inputs
                    if (newDriver !== 'Custom Driver') {
                      setCustomDriverFormula('');
                      setCustomFormulaToValidate('');
                      setCustomDriverValidationMessage(null);
                      setCustomCalculatedHistoricalValues({});
                    }
                  }}
                  style={{ width: '150px' }}
                >
                  <option value="Fixed Amount">Fixed Amount</option>
                  <option value="Fixed Growth">Fixed Growth</option>
                  <option value="Percent Increase">Percent Increase</option>
                  <option value="Percent of Another Row">Percent of Another Row</option>
                  <option value="Formula of Rows">Formula of Rows</option>
                  <option value="Custom Driver">Custom Driver</option>
                </select>
              </td>
              {periods.map((label, i) => (
                <td key={`custom-driver-amount-${i}`}>
                  {formulaRow ? (i < currentModel.histPeriods
                    ? (isHistorical ? historicalAmounts[label] || '' :                 
                    <input
                      type="number"
                      value={historicalAmounts[label] || ''}
                      onChange={(e) => handleHistoricalAmountChange(label, e.target.value)}
                    />)
                    : formulaRow.forecastResults[label] || '0.00'
                  ) : ('0')}
                </td>
              ))}
            </tr>
          );         
  
          // 2. **Render Custom Driver Sub-Rows Independently**
          customDriverRows.forEach((customRow, index) => {
            if (customRow.driverType === 'Formula') {
              // Skip rendering the formula row again
              return;
            }
  
            const subRowName = customRow.rowName;
            const subDriver = customRow.driverType;
  
            // Determine if the subDriver requires a secondary row
            const subNeedsSecondaryRow = subDriver === 'Fixed Growth' || subDriver === 'Percent Increase';
            
            // Push the main custom sub-row
            rows.push(
              <tr key={`custom-sub-row-${index}`}>
                <td>{subRowName}</td>
                <td>
                  <select
                    value={subDriver}
                    onChange={(e) => handleCustomDriverRowDriverChange(index, e.target.value)}
                  >
                    <option value="Fixed Amount">Fixed Amount</option>
                    <option value="Fixed Percent">Fixed Percent</option>
                    <option value="Fixed Growth">Fixed Growth</option>
                    <option value="Percent Increase">Percent Increase</option>
                  </select>
                </td>
                {periods.map((label, i) => {
                  if (i < currentModel.histPeriods) {
                    // **Display historicalDifferences in the main custom sub-row for relevant driver types**
                    if (['Fixed Growth', 'Percent Increase'].includes(subDriver)) {
                      return (
                        <td key={`custom-${index}-hist-${i}`}>
                          {customRow.historicalDifferences[label] || ''}
                        </td>
                      );
                    } else {
                      return <td key={`custom-${index}-hist-${i}`}></td>;
                    }                
                  } else {
                    // Forecast Periods - input for forecast value based on sub-driver
                    if (['Fixed Growth', 'Percent Increase'].includes(subDriver)) {
                      return (
                        <td key={`custom-${index}-fore-${i}`}>
                          <input
                            type="number"
                            value={customRow.forecastInputs[label] || ''}
                            onChange={(e) => handleCustomDriverForecastInputChange(index, label, e.target.value)}
                          />
                          {subDriver === 'Percent Increase' && '%'}
                        </td>
                      );
                    } else if (subDriver === 'Fixed Amount') {
                      return (
                        <td key={`custom-${index}-fore-${i}`}>
                          <input
                            type="number"
                            value={customRow.forecastInputs[label] || ''}
                            onChange={(e) => handleCustomDriverForecastInputChange(index, label, e.target.value)}
                          />
                        </td>
                      );
                    } else if (subDriver === 'Fixed Percent') {
                      return (
                        <td key={`custom-${index}-fore-${i}`}>
                          <input
                            type="number"
                            value={customRow.forecastInputs[label] || ''}
                            onChange={(e) => handleCustomDriverForecastInputChange(index, label, e.target.value)}
                          />
                          %
                        </td>
                      );
                    } else {
                      return <td key={`custom-${index}-fore-${i}`}></td>;
                    }
                  }
                })}
              </tr>
            );
  
            // If the subDriver requires displaying the calculated forecast result, add a secondary row
            if (subNeedsSecondaryRow) {
              rows.push(
                <tr key={`custom-sub-row-${index}-result`}>
                  <td></td>
                  <td>Calculated Amount</td>
                  {periods.map((label, i) => {
                    if (i < currentModel.histPeriods) {
                      // Only render the input box in the last historical column of the secondary row
                      return (
                        <td key={`custom-result-${index}-${i}`}>
                          <input
                            type="number"
                            value={customRow.historicalValues[label] || ''}
                            onChange={(e) => handleCustomDriverHistoricalInputChange(index, e.target.value, label)}
                          />
                        </td>
                      );
                    } else {
                      // Forecast Periods - show the calculated forecast results
                      return (
                        <td key={`custom-result-${index}-${i}`}>
                          {customRow.forecastResults[label] || '0.00'}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            }
          });
        }
  
        console.log('Generated Rows:', rows); // Log the final rows array
  
        return rows;
      },
      [
        currentModel.histPeriods,
        currentModel.forePeriods,
        selectedDriver,
        selectedReferenceRow,
        rowName,
        historicalAmounts,
        forecastInputs,
        forecastAmounts,
        calculatedHistoricalValues,
        calculatedForecastValues,
        handleForecastInputChange,
        handleHistoricalAmountChange,
        selectedPeriodOption,
        specificPeriod,
        referenceRowForecastValues, // Added for "Percent of Another Row"
        isEditing,
        isEditingSetup,
        isHistorical,
        sumValidationMessage,
        customDriverRows,
        customCalculatedHistoricalValues,
      ]
    );



  // useEffect to set setup-related fields when isEditingSetup changes
  useEffect(() => {
    if (isEditingSetup && isEditing && rowData) {
      console.log(
        'Editing setup options: Initializing setup fields from rowData'
      );

      // Update only if the new value differs from the current state
      if (selectedSpreadsheet !== rowData.selectedSpreadsheetId) {
        setSelectedSpreadsheet(rowData.selectedSpreadsheetId || '');
      }

      if (selectedCategory !== rowData.selectedCategory) {
        setSelectedCategory(rowData.selectedCategory || '');
      }

      if (selectedVariable !== rowData.selectedVariable) {
        setSelectedVariable(rowData.selectedVariable || '');
      }

      if (selectedDateColumn !== rowData.selectedDateColumn) {
        setSelectedDateColumn(rowData.selectedDateColumn || '');
      }

      if (selectedCategoryValue !== rowData.selectedCategoryValue) {
        setSelectedCategoryValue(rowData.selectedCategoryValue || '');
      }

      // After setting up, allow recalculations
    } else if (!isEditingSetup && isEditing) {
      console.log('Setup editing canceled: Resetting setup fields');

      if (selectedSpreadsheet !== '') {
        setSelectedSpreadsheet('');
      }

      if (selectedCategory !== '') {
        setSelectedCategory('');
      }

      if (selectedVariable !== '') {
        setSelectedVariable('');
      }

      if (selectedDateColumn !== '') {
        setSelectedDateColumn('');
      }

      if (selectedCategoryValue !== '') {
        setSelectedCategoryValue('');
      }

      console.log('Setup fields reset and isFirstLoad set to false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingSetup, isEditing, rowData]);


  // useEffect to prepopulate form if editing an existing row
  useEffect(() => {
    if (isEditing && rowData) {
      console.log('Editing mode: Initializing with existing row data');
      console.log('rowData:', rowData);
  
      // Prepopulate row details when editing
      setRowName(rowData.name || '');
      setSelectedDriver(rowData.driver || '')

      switch (rowData.driver) {
        case 'Fixed Amount':
          break
        case 'Fixed Growth':
          setSelectedPeriodOption(rowData.selectedDriver[2])
          if (rowData.selectedDriver[2] === 'Specific period') {
            setSpecificPeriod(rowData.selectedDriver[1])
          }
          break
        case 'Percent Increase':
          setSelectedPeriodOption(rowData.selectedDriver[2])
          if (rowData.selectedDriver[2] === 'Specific period') {
            setSpecificPeriod(rowData.selectedDriver[1])
          }
          break
        case 'Percent of Another Row':
          const referenceRowName = rowData.selectedDriver[1]
          console.log(referenceRowName)
          setSelectedReferenceRow(referenceRowName)
          const referenceRowIndex = currentModel.rowNames.indexOf(referenceRowName);
          console.log(`Reference row index for "${referenceRowName}":`, referenceRowIndex);
  
          if (referenceRowIndex === -1) {
            console.error(`Reference row "${referenceRowName}" not found in currentModel.rowNames.`);
            setReferenceRowForecastValues([]);
          } else {
            let parsedForecastRows = [];
            switch (currentModel.basis) {
              case 'Yearly':
                parsedForecastRows = currentModel.annualForeRows ? JSON.parse(currentModel.annualForeRows) : [];
                break;
              case 'Quarterly':
                parsedForecastRows = currentModel.quarterlyForeRows ? JSON.parse(currentModel.quarterlyForeRows) : [];
                break;
              case 'Monthly':
                parsedForecastRows = currentModel.monthlyForeRows ? JSON.parse(currentModel.monthlyForeRows) : [];
                break;
              default:
                console.error('Invalid model basis');
            }
  
            const selectedRowData = parsedForecastRows[referenceRowIndex] || [];
            console.log('Selected row forecast data:', selectedRowData);
  
            const forecastValues = selectedRowData.slice(0, currentModel.forePeriods) || [];
            console.log('Setting referenceRowForecastValues:', forecastValues);
  
            setReferenceRowForecastValues(forecastValues);
          }
          break
        case 'Formula of Rows':
          setSumFormulaInput(rowData.selectedDriver[1])
          break
        case 'Custom Driver':
          break
        default:
          console.warn(`Unhandled driver type: ${rowData.driver}`);
      }
  
      // Slice historical and forecast data based on model periods
      const slicedHistoricalData = rowData.histRows.slice(-currentModel.histPeriods);
      const slicedForecastData = rowData.foreRows.slice(0, currentModel.forePeriods);
      const slicedForecastValues = rowData.foreValues.slice(0, currentModel.forePeriods);
  
      console.log('Editing row with prepopulated data:', {
        rowData,
        slicedHistoricalData,
        slicedForecastData,
        slicedForecastValues
      });
  
      if (isHistorical) {
        const { histLabels, foreLabels } = getHeaderData()
  
        // Map slicedHistoricalData to histLabels
        const tempHistoricalAmounts = {};
        histLabels.forEach((label, idx) => {
          tempHistoricalAmounts[label] = Number(slicedHistoricalData[idx]) || 0;
        });
        console.log('Setting historicalAmounts:', tempHistoricalAmounts);
        setHistoricalAmounts(tempHistoricalAmounts);
  
        // Map slicedForecastData to foreLabels
        const tempForecastInputs = {};
        foreLabels.forEach((label, idx) => {
          tempForecastInputs[label] = slicedForecastValues[idx] || '';
        });
        console.log('Setting forecastInputs:', tempForecastInputs);
        setForecastInputs(tempForecastInputs);
  
        // Combine periods for table rows
        const periods = [...histLabels, ...foreLabels];
  
        // Generate table rows based on existing forecast amounts
        console.log('Generating table rows with periods:', periods);
        setTableRows(generateTableRows(periods));
      } else {
        // When not historical, pad historical data with "" based on currentModel's basis and histPeriods
  
        const { histLabels } = getHeaderData()
  
        // Set historicalAmounts to "" for each historical period
        const tempHistoricalAmounts = {};
        histLabels.forEach((label) => {
          tempHistoricalAmounts[label] = ''; // Set as empty string
        });
        setHistoricalAmounts(tempHistoricalAmounts);
  
        // Set forecastInputs to "" for each forecast period
        let foreHeaders = [];
        switch (currentModel.basis) {
          case 'Yearly':
            foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
            break;
          case 'Quarterly':
            foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
            break;
          case 'Monthly':
            foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
            break;
          default:
            foreHeaders = [];
        }
  
        const tempForecastInputs = {};
        foreHeaders.forEach((header) => {
          tempForecastInputs[header[0]] = ''; // Set as empty string
        });
        setForecastInputs(tempForecastInputs);
  
        // Combine periods for table rows
        const periods = [...histLabels, ...foreHeaders.map((header) => header[0])];
        setTableRows(generateTableRows(periods));
      }
    }
  }, [isEditing, rowData, currentModel, isHistorical]); 

  
  // useEffect to calculate percentage amount when using Percent of Another Row
  useEffect(() => {
    if (selectedDriver === 'Percent of Another Row' && selectedReferenceRow) {
      // Find the index of the selected reference row
      const referenceRowIndex = currentModel.rowNames.indexOf(selectedReferenceRow);
      
      if (referenceRowIndex === -1) {
        console.error(`Reference row "${selectedReferenceRow}" not found in currentModel.rowNames.`);
        setReferenceRowForecastValues([]);
        return;
      }
      
      let parsedForecastRows = [];
      switch (currentModel.basis) {
        case 'Yearly':
          parsedForecastRows = currentModel.annualForeRows ? JSON.parse(currentModel.annualForeRows) : [];
          break;
        case 'Quarterly':
          parsedForecastRows = currentModel.quarterlyForeRows ? JSON.parse(currentModel.quarterlyForeRows) : [];
          break;
        case 'Monthly':
          parsedForecastRows = currentModel.monthlyForeRows ? JSON.parse(currentModel.monthlyForeRows) : [];
          break;
        default:
          console.error('Invalid model basis');
      }
      
      const selectedRowData = parsedForecastRows[referenceRowIndex] || [];
      const forecastValues = selectedRowData.slice(0, currentModel.forePeriods) || [];
      console.log('Setting referenceRowForecastValues:', forecastValues);
      setReferenceRowForecastValues(forecastValues);
    } else {
      // If driver is not 'Percent of Another Row' or no row selected, clear the reference values
      setReferenceRowForecastValues([]);
    }
  }, [selectedDriver, selectedReferenceRow, currentModel]);
  

  // This can probably be a simpler handler to avoid the risk of infinite loops

  // useEffect to reset selectedReferenceRow when driver changes
  useEffect(() => {
    if (selectedDriver !== 'Percent of Another Row') {
      setSelectedReferenceRow('');
    }
  }, [selectedDriver]);       



  // Perhaps don't need this? why not just rely on the two which might be combinable below?

  // useEffect to generate table rows when dependencies change
  useEffect(() => {
    const { combinedLabels } = getHeaderData()

    // Update table rows whenever relevant dependencies change
    setTableRows(generateTableRows(combinedLabels));
  }, [
    selectedCategoryValue, // Trigger when the selected category value changes
    selectedVariable,
    selectedDateColumn,
    selectedCategory,
    forecastInputs, // Trigger when forecast inputs change
    forecastAmounts, // Trigger when forecast amounts change
    historicalAmounts, // Trigger when historical amounts change
    currentModel, // Trigger when the model changes (including headers)
    generateTableRows,
  ]);



  // Perhaps combine these next two useeffects

  // useEffect to recalculate forecast amounts whenever forecastInputs or historicalAmounts change
  useEffect(() => {
    if (isEditingSetup) {
      // Skip recalculations during setup editing
      console.log('Recalculation skipped: isEditingSetup is true');
      return;
    }
  
    if (
      Object.keys(forecastInputs).length === 0 ||
      Object.keys(historicalAmounts).length === 0
    ) {
      // Do not calculate if inputs are not set
      console.log('Recalculation skipped: forecastInputs or historicalAmounts are empty');
      return;
    }
  
    console.log('Recalculating forecast amounts');

    const { combinedLabels } = getHeaderData()
  
    recalculateForecastAmounts(forecastInputs, combinedLabels);
  }, [
    forecastInputs,
    historicalAmounts,
    currentModel.histPeriods,
    currentModel.forePeriods,
    currentModel.basis,
    recalculateForecastAmounts,
    isEditingSetup,
  ]);  

  // useEffect to set historicalAmounts when dependencies change
  useEffect(() => {
    if (processedData && currentModel.histPeriods) {
      const { histHeaders } = getHeaderData();
  
      // Calculate all historical amounts
      const tempAllHistoricalAmounts = {};
      histHeaders.forEach((header) => {
        const sum = calculateSumForPeriod(
          header.startDate,
          header.endDate,
          processedData,
          selectedCategoryValue
        );
        tempAllHistoricalAmounts[header.label] = sum;
      });
  
      // Update allHistoricalAmounts state
      if (!isObjectShallowEqual(tempAllHistoricalAmounts, allHistoricalAmounts)) {
        setAllHistoricalAmounts(tempAllHistoricalAmounts);
      }
  
      // Also, set the displayed historicalAmounts by slicing the last histPeriods
      const tempDisplayedHistoricalAmounts = {};
      histHeaders.forEach((header) => {
        tempDisplayedHistoricalAmounts[header.label] = tempAllHistoricalAmounts[header.label];
      });
  
      if (!isObjectShallowEqual(tempDisplayedHistoricalAmounts, historicalAmounts)) {
        setHistoricalAmounts(tempDisplayedHistoricalAmounts);
      }
    }
  }, [
    processedData,
    currentModel.histPeriods,
    currentModel.basis,
    selectedCategoryValue,
    calculateSumForPeriod,
    historicalAmounts, // For comparison
    allHistoricalAmounts, // For comparison
    isObjectShallowEqual
  ]); 



  // useEffect to update custom driver rows
  useEffect(() => {
    if (selectedDriver !== 'Custom Driver') return;
  
    const formulaRow = customDriverRows.find(row => row.driverType === 'Formula');
    if (!formulaRow || !formulaRow.formula) return;
  
    const formula = formulaRow.formula;
    const { foreLabels, combinedLabels } = getHeaderData()
  
    const newForecastResults = {};
  
    // Calculate all forecast results first
    foreLabels.forEach(periodLabel => {
      try {
        const evaluatedValue = evaluateCustomDriverFormula(formula, customDriverRows, periodLabel, combinedLabels, currentModel.histPeriods);
        newForecastResults[periodLabel] = typeof evaluatedValue === 'number' ? evaluatedValue.toFixed(2) : evaluatedValue;
      } catch (error) {
        console.error(`Error evaluating formula for period "${periodLabel}":`, error.message);
        newForecastResults[periodLabel] = 'Error';
      }
    });
  
    // Determine if any forecastResults have changed
    let hasChanged = false;
    const updatedRows = [...customDriverRows];
    const formulaRowIndex = updatedRows.findIndex(row => row.driverType === 'Formula');
  
    if (formulaRowIndex !== -1) {
      Object.entries(newForecastResults).forEach(([periodLabel, value]) => {
        if (updatedRows[formulaRowIndex].forecastResults[periodLabel] !== value) {
          updatedRows[formulaRowIndex].forecastResults[periodLabel] = value;
          hasChanged = true;
        }
      });
  
      if (hasChanged) {
        setCustomDriverRows(updatedRows);
      }
    }
  }, [
    selectedDriver,
    customDriverRows,
    historicalAmounts,
    evaluateCustomDriverFormula,
    currentModel.histPeriods,
    currentModel.forePeriods,
  ]);
    
  



  // Formula related stuff - so Formula of Rows and Custom Driver

  // Helper function to extract distinct expressions within quotes
  const extractExpressionsFromFormula = (formula) => {
    const regex = /['"]([^'"]+)['"]/g;
    let match;
    const expressions = new Set();
    
    while ((match = regex.exec(formula)) !== null) {
      expressions.add(match[1]);
    }
    
    const extractedExpressions = Array.from(expressions);
    console.log('Extracted Expressions from Formula:', extractedExpressions); // Logging the expressions
    return extractedExpressions;
  };

  // Update this function within your component
  const calculateSumBasedOnFormula = useCallback(
    (formula, basis) => { // Added 'basis' parameter
      if (!currentModel || Object.keys(currentModel).length === 0) {
        console.error('Current model data is not available for formula evaluation.');
        return { calculatedHistoricalValues: {}, calculatedForecastValues: {} };
      }

      // Function to extract data based on type and basis
      const extractData = (type, basis) => {
        let rows = {};
        const rowNames = currentModel.rowNames; // Array of row names
      
        rowNames.forEach((rowName, index) => {
          // **Skip Pivot Table Rows**
          if (Array.isArray(rowName) && rowName[0] === 'Pivot') {
            console.log(`Skipping pivot row at index ${index}:`, rowName);
            return; // Skip to the next iteration
          }

          let data = [];
          if (type === 'historical') {
            switch (basis) { // Use the passed 'basis' parameter
              case 'Annual':
                data = JSON.parse(currentModel.annualHistRows || '[]')[index] || [];
                break;
              case 'Quarterly':
                data = JSON.parse(currentModel.quarterlyHistRows || '[]')[index] || [];
                break;
              case 'Monthly':
                data = JSON.parse(currentModel.monthlyHistRows || '[]')[index] || [];
                break;
              default:
                console.error('Invalid basis');
                data = [];
            }
          } else if (type === 'forecast') {
            switch (basis) { // Use the passed 'basis' parameter
              case 'Annual':
                data = JSON.parse(currentModel.annualForeRows || '[]')[index] || [];
                break;
              case 'Quarterly':
                data = JSON.parse(currentModel.quarterlyForeRows || '[]')[index] || [];
                break;
              case 'Monthly':
                data = JSON.parse(currentModel.monthlyForeRows || '[]')[index] || [];
                break;
              default:
                console.error('Invalid basis');
                data = [];
            }
          }
          
          // Sanitize data: ensure all values are numeric
          rows[rowName] = Array.isArray(data)
            ? data.map(value => {
                const parsed = parseFloat(value);
                return isNaN(parsed) ? 0 : parsed;
              })
            : [];
        });
      
        return rows;
      };            

      // Extract historical and forecast data based on the provided basis
      const allHistoricalData = extractData('historical', basis); // Pass the basis as an argument
      const allForecastData = extractData('forecast', basis); // Pass the basis as an argument

      console.log('allhistdata: ', allHistoricalData);
      console.log('allforedata: ', allForecastData);

      // Evaluate formula for all historical periods
      const newCalculatedHistoricalValues = {};
      const histPeriodCount = Object.values(allHistoricalData)[0]?.length || 0; // Determine the number of historical periods

      for (let i = 0; i < histPeriodCount; i++) {
        try {
          const result = evaluateSumFormula(formula, allHistoricalData, i);
          newCalculatedHistoricalValues[i] = result.toFixed(2);
        } catch (err) {
          console.error(`Error evaluating formula for historical period ${i + 1}:`, err.message);
          newCalculatedHistoricalValues[i] = 'Error';
        }
      }

      // Evaluate formula for all forecast periods
      const newCalculatedForecastValues = {};
      const forePeriodCount = Object.values(allForecastData)[0]?.length || 0; // Determine the number of forecast periods

      for (let i = 0; i < forePeriodCount; i++) {
        try {
          const result = evaluateSumFormula(formula, allForecastData, i);
          newCalculatedForecastValues[i] = result.toFixed(2);
        } catch (err) {
          console.error(`Error evaluating formula for forecast period ${i + 1}:`, err.message);
          newCalculatedForecastValues[i] = 'Error';
        }
      }

      console.log('newhistvalues: ', newCalculatedHistoricalValues);
      console.log('newforevalues: ', newCalculatedForecastValues);

      // **Return** the calculated values instead of setting state
      return {
        calculatedHistoricalValues: newCalculatedHistoricalValues,
        calculatedForecastValues: newCalculatedForecastValues,
      };
    },
    [currentModel, evaluateSumFormula]
  );

  // Handler for "Formula of Rows" validation results
  const handleValidationResult = useCallback(
    (error, correctedFormula) => {
      if (error) {
        setSumValidationMessage(error);
        setCalculatedHistoricalValues({});
        setCalculatedForecastValues({});
      } else {
        setSumValidationMessage("Formula is valid!");
        const sanitizedFormula = correctedFormula.replace(/"/g, "'");
        
        // Call calculateSumBasedOnFormula for "Formula of Rows"
        const { calculatedHistoricalValues, calculatedForecastValues } = calculateSumBasedOnFormula(sanitizedFormula, currentModel.basis);
        
        // Slice and set the calculated historical values
        const slicedHistoricalValues = {};
        Object.keys(calculatedHistoricalValues).forEach(key => {
          const index = parseInt(key, 10);
          if (index >= (Object.keys(calculatedHistoricalValues).length - currentModel.histPeriods)) {
            slicedHistoricalValues[index] = calculatedHistoricalValues[key];
          }
        });
        
        // Slice and set the calculated forecast values
        const slicedForecastValues = {};
        Object.keys(calculatedForecastValues).forEach(key => {
          const index = parseInt(key, 10);
          if (index < currentModel.forePeriods) {
            slicedForecastValues[index] = calculatedForecastValues[key];
          }
        });
        
        setCalculatedHistoricalValues(slicedHistoricalValues);
        setCalculatedForecastValues(slicedForecastValues);
      }
      // Reset sumFormulaToValidate to prevent re-validation
      setSumFormulaToValidate('');
    },
    [calculateSumBasedOnFormula, currentModel.histPeriods, currentModel.forePeriods, currentModel.basis]
  );

  const handleCustomDriverValidationResult = useCallback(
    (error, correctedFormula) => {
      if (error) {
        // Handle validation errors
        console.log('Custom Driver Validation Error:', error);
        setCustomDriverValidationMessage(error);
        setCustomDriverRows([]);
      } else {
        // Handle successful validation
        console.log('Custom Driver Validation Successful. Corrected Formula:', correctedFormula);
        setCustomDriverValidationMessage("Formula is valid!");

        // Step 1: Sanitize the formula
        let sanitizedFormula = correctedFormula.replace(/"/g, "'"); // Replace double quotes with single quotes

        // Step 2: Remove quotes around 'PP'
        sanitizedFormula = sanitizedFormula.replace(/['"]PP['"]/gi, 'PP');
        console.log('Sanitized Formula:', sanitizedFormula);

        // Step 3: Extract expressions (e.g., row names) from the sanitized formula
        let expressions = extractExpressionsFromFormula(sanitizedFormula);
        console.log('Expressions Extracted for Custom Driver:', expressions);

        // Step 4: Exclude 'PP' from expressions to prevent creating a subrow for it
        expressions = expressions.filter(expr => expr.toUpperCase() !== 'PP');
        console.log('Filtered Expressions (excluding PP):', expressions);

        // Step 5: Get all combined periods
        const { foreLabels, histLabels } = getHeaderData()

        // Step 8: Generate sub-driver rows based on expressions
        const subDriverRows = expressions.map(expr => {
          // Initialize forecastInputs and forecastResults with empty values for all forecast periods
          const forecastInputs = {};
          const forecastResults = {};
          foreLabels.forEach(label => {
            forecastInputs[label] = '';
            forecastResults[label] = '0.00';
          });

          // Initialize historicalValues with historicalAmounts for all historical periods
          const historicalValues = {};
          histLabels.forEach(label => {
            historicalValues[label] = '';
          });

          // Initialize historicalDifferences with empty strings for all historical periods
          const historicalDifferences = {};
          histLabels.forEach(label => {
            historicalDifferences[label] = '';
          });

          return {
            rowName: expr,
            driverType: 'Fixed Amount', // Default driver type; adjust as needed or make dynamic
            forecastInputs, // Initialize inputs per forecast period
            forecastResults, // Initialize results per forecast period
            historicalValues, // Initialize historical inputs per historical period
            historicalDifferences, // Initialize differences for each historical period
          };
        });

        // Step 9: Create the formula row
        const formulaRow = {
          rowName: 'Formula Row', // Customize as needed
          driverType: 'Formula', // Distinguish this row as the formula row
          formula: sanitizedFormula, // Store the sanitized formula
          forecastInputs: {}, // No inputs needed for formula row
          forecastResults: {}, // Initialize forecast results
          historicalValues: { ...historicalAmounts }, // Store historicalAmounts
          historicalDifferences: {}, // No historical differences needed
        };

        // Step 10: Initialize forecastResults for formula row with '0.00' for all forecast periods
        foreLabels.forEach(label => {
          formulaRow.forecastResults[label] = '0.00';
        });

        // Step 11: Combine formula row with sub-driver rows
        const newCustomDriverRows = [formulaRow, ...subDriverRows];

        console.log('New Custom Driver Rows:', newCustomDriverRows);
        setCustomDriverRows(newCustomDriverRows);
      }
      // Reset customFormulaToValidate to prevent re-validation
      setCustomFormulaToValidate('');
    },
    [
      extractExpressionsFromFormula,
      currentModel.histPeriods,
      currentModel.forePeriods,
    ]
  );

  const handleCustomDriverForecastInputChange = useCallback(
    (index, periodLabel, value) => {
      setCustomDriverRows((prevRows) => {
        const updatedRows = [...prevRows];
        const row = updatedRows[index];
        row.forecastInputs[periodLabel] = value !== '' ? String(parseFloat(value) || 0) : '0';

        // Get the index of the current period
        const { combinedLabels } = getHeaderData()
        const forecastPeriodIndex = combinedLabels.indexOf(periodLabel);

        if (forecastPeriodIndex === -1 || forecastPeriodIndex < currentModel.histPeriods) {
          // Invalid period label or not a forecast period
          return updatedRows;
        }

        // Initialize the base amount from the last historical value or previous forecast
        let baseAmount = 0;
        if (parseFloat(forecastPeriodIndex) === parseFloat(currentModel.histPeriods)) {
          // First forecast period: base is the last historical value
          const lastHistoricalLabel = combinedLabels[currentModel.histPeriods - 1];
          baseAmount = parseFloat(row.historicalValues[lastHistoricalLabel]) || 0;
          console.log('baseamountmain: ', baseAmount)
        } else {
          // Subsequent forecast periods: base is the previous forecast result
          const previousLabel = combinedLabels[forecastPeriodIndex - 1];
          baseAmount = parseFloat(row.forecastResults[previousLabel]) || 0;
        }

        // Calculate the current forecasted value based on the driver type
        const inputValue = parseFloat(row.forecastInputs[periodLabel]) || 0;
        let calculatedValue = baseAmount; // Initialize with baseAmount

        if (row.driverType === 'Fixed Growth') {
          calculatedValue = baseAmount + inputValue; // Use '=' instead of '+='
        } else if (row.driverType === 'Percent Increase') {
          calculatedValue = baseAmount + (baseAmount * inputValue) / 100; // Use '=' instead of '+='
        }

        if (['Fixed Growth', 'Percent Increase'].includes(row.driverType)) {
          row.forecastResults[periodLabel] = calculatedValue.toFixed(2); // Store as string with two decimals
        } else {
          row.forecastResults[periodLabel] = inputValue;
        }

        // Now, recursively update subsequent forecast periods
        if (['Fixed Growth', 'Percent Increase'].includes(row.driverType)) {
          for (let i = forecastPeriodIndex + 1; i < combinedLabels.length; i++) {
            const nextPeriodLabel = combinedLabels[i];
            if (i < currentModel.histPeriods) continue; // Skip historical periods

            const nextInputValue = parseFloat(row.forecastInputs[nextPeriodLabel]) || 0;
            const previousLabel = combinedLabels[i - 1];
            const nextBaseAmount = parseFloat(row.forecastResults[previousLabel]) || 0;
            let nextCalculatedValue = nextBaseAmount;

            if (row.driverType === 'Fixed Growth') {
              nextCalculatedValue = nextBaseAmount + nextInputValue; // Use '=' instead of '+='
            } else if (row.driverType === 'Percent Increase') {
              nextCalculatedValue = nextBaseAmount + (nextBaseAmount * nextInputValue) / 100; // Use '=' instead of '+='
            }

            row.forecastResults[nextPeriodLabel] = nextCalculatedValue.toFixed(2); // Store as string with two decimals
          }
        }

        console.log('Updated Rows with Recursive Forecasts:', updatedRows);
        return updatedRows;
      });

      // No need to trigger recalculation here as forecasts are handled within the handler
    },
    [
      currentModel.histPeriods,
    ]
  );

  const handleCustomDriverHistoricalInputChange = useCallback((index, value, periodLabel) => {
    setCustomDriverRows(prevRows => {
      const updatedRows = [...prevRows];
      const row = updatedRows[index];

      // Update the historical value
      row.historicalValues[periodLabel] = value !== '' ? String(parseFloat(value) || 0) : '0';

      // Retrieve and sort historical labels based on their order in currentPeriods
      const historicalLabels = Object.keys(row.historicalValues);
      const { combinedLabels } = getHeaderData()
      historicalLabels.sort((a, b) => combinedLabels.indexOf(a) - combinedLabels.indexOf(b));

      // Calculate differences or percentage changes
      historicalLabels.forEach((label, i) => {
        if (i === 0) {
          row.historicalDifferences[label] = ''; // No difference for the first period
        } else {
          const previousLabel = historicalLabels[i - 1];
          const previousValue = parseFloat(row.historicalValues[previousLabel]) || 0;
          const currentValue = parseFloat(row.historicalValues[label]) || 0;

          if (row.driverType === 'Percent Increase') {
            const percentChange = previousValue !== 0 ? ((currentValue - previousValue) / previousValue) * 100 : 0;
            row.historicalDifferences[label] = `${percentChange.toFixed(2)}%`;
          } else if (row.driverType === 'Fixed Growth') {
            const difference = (currentValue - previousValue).toFixed(2);
            row.historicalDifferences[label] = difference;
          } else {
            // Handle other driver types if necessary
            row.historicalDifferences[label] = '';
          }

          console.log('rowdiffs: ', row.historicalDifferences);
        }
      });

      // If the driver type requires recalculating the forecast results for all periods
      if (['Fixed Growth', 'Percent Increase'].includes(row.driverType)) {
        // Identify the last historical period
        const lastHistoricalLabel = historicalLabels[historicalLabels.length - 1];
        const baseAmount = parseFloat(row.historicalValues[lastHistoricalLabel]) || 0;

        // Retrieve forecast periods in chronological order
        const forecastPeriods = combinedLabels.slice(currentModel.histPeriods, currentModel.histPeriods + currentModel.forePeriods);

        // Initialize a new base amount for recursive calculations
        let newBaseAmount = baseAmount;

        // Iterate through each forecast period and calculate recursively
        forecastPeriods.forEach(period => {
          const forecastInputValue = parseFloat(row.forecastInputs[period]) || 0;
          let calculatedValue = newBaseAmount;

          if (row.driverType === 'Fixed Growth') {
            calculatedValue = newBaseAmount + forecastInputValue;
          } else if (row.driverType === 'Percent Increase') {
            calculatedValue = newBaseAmount + (newBaseAmount * forecastInputValue) / 100;
          }

          // Update the forecast result for the current period
          row.forecastResults[period] = calculatedValue.toFixed(2);

          // Update the base amount for the next forecast period
          newBaseAmount = calculatedValue;
        });
      }

      console.log('Updated Rows after Historical Input Change:', updatedRows);
      return updatedRows;
    });
  }, [
    currentModel.histPeriods,
  ]);

  const handleCustomDriverRowDriverChange = (index, newDriverType) => {
    setCustomDriverRows(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index].driverType = newDriverType;

      // Reset historicalDifferences if the new driver type doesn't require it
      if (!['Fixed Growth', 'Percent Increase'].includes(newDriverType)) {
        Object.keys(updatedRows[index].historicalDifferences).forEach(label => {
          updatedRows[index].historicalDifferences[label] = '';
        });
      }

      // Reset historicalValues if the new driver type doesn't require it
      if (!['Fixed Growth', 'Percent Increase'].includes(newDriverType)) {
        Object.keys(updatedRows[index].historicalValues).forEach(label => {
          updatedRows[index].historicalValues[label] = '';
        });
      }

      return updatedRows;
    });
  };




  
  // Helper function to generate table headers
  const generateTableHeaders = useCallback(() => {

    const { histLabels, foreLabels } = getHeaderData()

    console.log('Historical Labels:', histLabels);
    console.log('Forecast Labels:', foreLabels);

    if (selectedDriver === 'Fixed Growth' || selectedDriver === 'Percent Increase') {
      return ['Row name', 'Driver', 'Formula', ...histLabels, ...foreLabels];
    } else if (selectedDriver === 'Percent of Another Row') {
      return ['Row name', 'Driver', 'Ref Row', ...histLabels, ...foreLabels];
    } else {
      return ['Row name', 'Driver', ...histLabels, ...foreLabels];
    }
  }, [currentModel, selectedDriver]);

  
  const handleImportRowData = useCallback(async () => {
    console.log('handleImportRowData called');
    if (isSubmitting) return;
    if (!rowName || !selectedDriver) {
        console.error('Row name and driver must be filled.');
        alert('Please fill in all required fields: Row Name and Driver.');
        return;
    }
    setIsSubmitting(true);

    if (selectedDriver === 'Custom Driver') {
      // **Handling Custom Driver Import**

      const { histLabels, foreLabels } = getHeaderData()

      await importCustomDriverRow({
        rowName,
        customDriverRows, // Ensure this array is defined and contains subrow details
        currentModel,
        historicalAmounts, // Object mapping period labels to historical values
        onRowAdded,
        onClose,
        setIsSubmitting,
        histLabels,
        foreLabels,
        selectedCategory,
        selectedCategoryValue, 
        selectedDateColumn,
        selectedVariable,
        sourceFile: [
          [
              selectedSpreadsheet,
              spreadsheets.find((s) => s.id === selectedSpreadsheet)?.suborganisation || '',
              spreadsheets.find((s) => s.id === selectedSpreadsheet)?.newName || '',
          ],
        ]
      });
      return; // Exit early as the custom driver import is handled
    }

    // Initialize empty arrays for historical and forecast data
    let annualHistData = Array(10).fill('0');
    let quarterlyHistData = Array(40).fill('0');
    let monthlyHistData = Array(120).fill('0');

    let annualForeValues = Array(10).fill('0'); // To store percentages/growth amounts
    let quarterlyForeValues = Array(40).fill('0');
    let monthlyForeValues = Array(120).fill('0');

    let annualForeRows = Array(10).fill('0'); // To store calculated forecast values
    let quarterlyForeRows = Array(40).fill('0');
    let monthlyForeRows = Array(120).fill('0');

      // Retrieve offset and selected period option
  const offset = getPeriodsToLookBack();
  const selectedPeriod = selectedPeriodOption;

    if (isHistorical) {
            // Helper function to process historical data using calculateSumForPeriod
            const processHistoricalData = (histHeaders) => {
              return histHeaders.map((header) => {
                  const [year, startDate, endDate] = header;
                  const sum = calculateSumForPeriod(startDate, endDate, processedData, selectedCategoryValue);
                  return sum !== 'Error' ? String(sum) : '';
              });
            };
            try {
              // Process Annual Historical Data
              const annualHistHeaders = JSON.parse(currentModel.annualHistHeaders || '[]');
              annualHistData = processHistoricalData(annualHistHeaders);

              // Process Quarterly Historical Data
              const quarterlyHistHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]');
              quarterlyHistData = processHistoricalData(quarterlyHistHeaders);

              // Process Monthly Historical Data
              const monthlyHistHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]');
              monthlyHistData = processHistoricalData(monthlyHistHeaders);
            } catch (error) {
                console.error('Error processing historical data:', error);
                alert('An error occurred while processing historical data.');
                setIsSubmitting(false);
                return;
            }

            // Handle Forecast Data
            try {
              const generateForecastData = (foreHeaders, foreValuesArray, foreRowsArray, basis) => {
                  foreHeaders.forEach((header, index) => {
                      const periodLabel = header[0];
                      const foreValue = forecastInputs[periodLabel] || '';
                      console.log('foreValue: ', foreValue);

                      // Store the input percentage/growth amount in foreValuesArray
                      foreValuesArray[index] = foreValue !== '' ? String(foreValue) : '0';

                      // Calculate the forecasted amount based on driver
                      if (selectedDriver === 'Fixed Amount') {
                          // For Fixed Amount, the foreRows are the same as foreValues
                          foreRowsArray[index] = foreValue !== '' ? String(foreValue) : '0';
                      } else if (selectedDriver === 'Fixed Growth' || selectedDriver === 'Percent Increase') {
                        // **Use pre-calculated forecastAmounts for Fixed Growth and Percent**
                        const calculatedAmount = forecastAmounts[periodLabel] || '0.00';
                        foreRowsArray[index] = calculatedAmount;
                      } else if (selectedDriver === 'Percent of Another Row') {
                        // **Handling "Percent of Another Row" Driver**
                        if (!selectedReferenceRow) {
                            console.error('Reference row must be specified for "Percent of Another Row" driver.');
                            foreRowsArray[index] = '0';
                            return;
                        }

                        // Find the index of the reference row in rowNames
                        const rowIndex = currentModel.rowNames.indexOf(selectedReferenceRow);
                        if (rowIndex === -1) {
                            console.error(`Reference row "${selectedReferenceRow}" not found.`);
                            foreRowsArray[index] = '0';
                            return;
                        }

                        console.log('rowIndex: ', rowIndex);
                        console.log('currentModel.annualForeRows[rowIndex]: ', JSON.parse(currentModel.annualForeRows)[rowIndex]);

                        // Retrieve the reference forecast value based on the basis
                        let referenceForecastValue = 0;
                        if (basis === 'Annual') {
                            referenceForecastValue = parseFloat(JSON.parse(currentModel.annualForeRows)[rowIndex][index]) || 0;
                        } else if (basis === 'Quarterly') {
                            referenceForecastValue = parseFloat(JSON.parse(currentModel.quarterlyForeRows)[rowIndex][index]) || 0;
                        } else if (basis === 'Monthly') {
                            referenceForecastValue = parseFloat(JSON.parse(currentModel.monthlyForeRows)[rowIndex][index]) || 0;
                        }

                        // Retrieve the percentage
                        const percentage = parseFloat(foreValue) || 0;

                        // Calculate the forecasted amount
                        const calculatedValue = (referenceForecastValue * percentage) / 100;
                        foreRowsArray[index] = calculatedValue.toFixed(2);

                        console.log(`Percent of Another Row - Reference Forecast Value (${basis}, period ${index + 1}): ${referenceForecastValue}, Percentage: ${percentage}, Calculated Value: ${calculatedValue}`);
                    }
                  });
              };

              // Process Annual Forecast Data
              const annualForeHeaders = JSON.parse(currentModel.annualForeHeaders || '[]');
              generateForecastData(annualForeHeaders, annualForeValues, annualForeRows, 'Annual');

              // Process Quarterly Forecast Data
              const quarterlyForeHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]');
              generateForecastData(quarterlyForeHeaders, quarterlyForeValues, quarterlyForeRows, 'Quarterly');

              // Process Monthly Forecast Data
              const monthlyForeHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]');
              generateForecastData(monthlyForeHeaders, monthlyForeValues, monthlyForeRows, 'Monthly');

              console.log('Processed Forecast Values and Rows:', {
                  annualForeValues,
                  quarterlyForeValues,
                  monthlyForeValues,
                  annualForeRows,
                  quarterlyForeRows,
                  monthlyForeRows,
              });
          } catch (error) {
              console.error('Error processing forecast data:', error);
              alert('An error occurred while processing forecast data.');
              setIsSubmitting(false);
              return;
          } // End of if (isHistorical) block
      } else {
        if (selectedDriver === 'Formula of Rows') {
          // **Handling "Formula of Rows" Driver for All Bases**
          console.log('sumFormulaInput: ', sumFormulaInput);

          const bases = ['Annual', 'Quarterly', 'Monthly'];
          const calculatedData = {};

          // Loop through each basis and calculate values
          for (const basis of bases) {
              const { calculatedHistoricalValues, calculatedForecastValues } = calculateSumBasedOnFormula(sumFormulaInput, basis);

              calculatedData[basis] = {
                  historical: Object.values(calculatedHistoricalValues),
                  forecast: Object.values(calculatedForecastValues),
              };
          }

          // Assign calculated historical data
          annualHistData = calculatedData['Annual'].historical;
          quarterlyHistData = calculatedData['Quarterly'].historical;
          monthlyHistData = calculatedData['Monthly'].historical;

          // Assign calculated forecast data
          annualForeValues = calculatedData['Annual'].forecast; // Store percentages/growth amounts
          quarterlyForeValues = calculatedData['Quarterly'].forecast;
          monthlyForeValues = calculatedData['Monthly'].forecast;

          // For "Formula of Rows", forecast rows are the calculated values
          annualForeRows = calculatedData['Annual'].forecast;
          quarterlyForeRows = calculatedData['Quarterly'].forecast;
          monthlyForeRows = calculatedData['Monthly'].forecast;

          console.log('Calculated Historical Data:', {
              annualHistData,
              quarterlyHistData,
              monthlyHistData,
          });
          console.log('Calculated Forecast Data:', {
              annualForeValues,
              quarterlyForeValues,
              monthlyForeValues,
          });
          console.log('Calculated Forecast Rows:', {
              annualForeRows,
              quarterlyForeRows,
              monthlyForeRows,
          });
        } else {
          try {
                // Overwrite the last `histPeriods` historical entries with user-entered values from `historicalAmounts`
                const overwriteHistorical = (histDataArray, histPeriods, histLabels) => {
                  histLabels.forEach((label, idx) => {
                      const position = histDataArray.length - histPeriods + idx;
                      histDataArray[position] = historicalAmounts[label] || '0';
                  });
              };

              // Determine histLabels based on basis
              const overwriteHistoricalData = () => {
                  const bases = ['Annual', 'Quarterly', 'Monthly'];
                  bases.forEach((basis) => {
                      let histHeaders = [];
                      let histDataArray;
                      let histPeriods;

                      switch (basis) {
                          case 'Annual':
                              histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
                              histDataArray = annualHistData;
                              histPeriods = currentModel.histPeriods;
                              break;
                          case 'Quarterly':
                              histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
                              histDataArray = quarterlyHistData;
                              histPeriods = currentModel.histPeriods;
                              break;
                          case 'Monthly':
                              histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
                              histDataArray = monthlyHistData;
                              histPeriods = currentModel.histPeriods;
                              break;
                          default:
                              console.error('Invalid model basis');
                              return;
                      }

                      const histLabels = histHeaders.map(header => header[0]);
                      overwriteHistorical(histDataArray, histPeriods, histLabels);
                      console.log(`Overwritten Historical Data for ${basis}:`, histDataArray);
                  });
              };

              overwriteHistoricalData();
          } catch (error) {
              console.error('Error overwriting historical data for non-historical row:', error);
              alert('An error occurred while setting historical data for non-historical row.');
              setIsSubmitting(false);
              return;
          }

      // **b. Overwrite the Forecast Entries with User Inputs Using forecastAmounts and Apply Offset Logic**
      try {
        const overwriteForecast = (foreHeaders, foreValuesArray, foreRowsArray, basis) => {
          foreHeaders.slice(0, currentModel.forePeriods).forEach((header, idx) => {
              const periodLabel = header[0];
              const foreValue = forecastInputs[periodLabel] || '0';
              foreValuesArray[idx] = foreValue !== '' ? String(foreValue) : '0';

              if (selectedDriver === 'Formula of Rows' || selectedDriver === 'Fixed Amount') {
                  // For Formula of Rows and Fixed Amount, forecast rows are the same as foreValues
                  foreRowsArray[idx] = foreValue !== '' ? String(foreValue) : '0';
              } else {
                // For Fixed Growth and Percent, use pre-calculated forecastAmounts
                const calculatedAmount = forecastAmounts[periodLabel] || '0.00';
                foreRowsArray[idx] = calculatedAmount;
              }

              console.log(`Forecast for ${basis} - Period ${idx + 1}:`, {
                  foreValue,
                  calculatedAmount: foreRowsArray[idx],
              });
          });

          // **Apply Offset Logic for Forecast Rows Beyond forePeriods**
          for (let i = currentModel.forePeriods; i < foreRowsArray.length; i++) {
            const referenceIndex = i - offset;

            if (referenceIndex < currentModel.forePeriods) {
              // Reference is within the current forecast periods
              foreRowsArray[i] = foreRowsArray[referenceIndex] || '0';
            } else {
              // Reference is in the historical data
              const histIndex = referenceIndex - currentModel.forePeriods;
              foreRowsArray[i] = histIndex >= 0 ? (historicalAmounts[foreHeaders[i].label] || '0') : '0';
            }

            console.log(`Forecast for ${basis} - Period ${i + 1} (Offset ${offset}): ${foreRowsArray[i]}`);
          }
      };

      const overwriteForecastData = () => {
          const bases = ['Annual', 'Quarterly', 'Monthly'];
          bases.forEach((basis) => {
              let foreHeaders = [];
              let foreValuesArray;
              let foreRowsArray;

              switch (basis) {
                  case 'Annual':
                      foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]');
                      foreValuesArray = annualForeValues;
                      foreRowsArray = annualForeRows;
                      break;
                  case 'Quarterly':
                      foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]');
                      foreValuesArray = quarterlyForeValues;
                      foreRowsArray = quarterlyForeRows;
                      break;
                  case 'Monthly':
                      foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]');
                      foreValuesArray = monthlyForeValues;
                      foreRowsArray = monthlyForeRows;
                      break;
                  default:
                      console.error('Invalid model basis');
                      return;
              }

              overwriteForecast(foreHeaders, foreValuesArray, foreRowsArray, basis);
              console.log(`Overwritten Forecast Data for ${basis}:`, {
                  foreValuesArray,
                  foreRowsArray,
              });
          });
      };

      overwriteForecastData();
      } catch (error) {
          console.error('Error overwriting forecast data for non-historical row:', error);
          alert('An error occurred while setting forecast data for non-historical row.');
          setIsSubmitting(false);
          return;
      }}
  }
        // **Prepare the Row Data to be Sent to the Backend**
        const rowDataToSend = {
            rowNames: [rowName],
            rowVariables: isHistorical ? [selectedCategoryValue] : ['None'],
            categories: isHistorical ? [selectedCategory] : ['None'],
            groupedOver: isHistorical ? selectedVariable : 'None',
            dateColumn: isHistorical ? selectedDateColumn : 'None',
            sourceFile: isHistorical
                ? [
                    [
                        selectedSpreadsheet,
                        spreadsheets.find((s) => s.id === selectedSpreadsheet)?.suborganisation || '',
                        spreadsheets.find((s) => s.id === selectedSpreadsheet)?.newName || '',
                    ],
                ]
                : ['None'],
            drivers: [
              (() => {
                if (selectedDriver === 'Percent of Another Row') {
                  return ['Percent of Another Row', selectedReferenceRow];
                } else if (selectedDriver === 'Formula of Rows') {
                  return ['Formula of Rows', sumFormulaInput];
                } else if (selectedDriver === 'Fixed Growth' || selectedDriver === 'Percent Increase') {
                  return [selectedDriver, offset, selectedPeriod];
                }
                return selectedDriver; // For Fixed Amount and other drivers
              })(),
            ],
            supportingAssumptionModelIds: [''],
            annualHistRows: [annualHistData],
            quarterlyHistRows: [quarterlyHistData],
            monthlyHistRows: [monthlyHistData],
            annualForeRows: [annualForeRows],
            quarterlyForeRows: [quarterlyForeRows],
            monthlyForeRows: [monthlyForeRows],
            annualForeValues: [annualForeValues],
            quarterlyForeValues: [quarterlyForeValues],
            monthlyForeValues: [monthlyForeValues],
            quarterlySeasonality: [[25, 25, 25, 25]],
            monthlySeasonality: [[8.33, 8.33, 8.34, 8.33, 8.33, 8.34, 8.33, 8.33, 8.34, 8.33, 8.33, 8.34]]
        };

        console.log('Row Data to Send:', rowDataToSend); // Add this log for debugging

        try {
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowDataToSend);
            if (response.status === 200) {
                console.log('Row data successfully imported');
                const updatedModel = response.data.model;
                onRowAdded(updatedModel);
                onClose();
            } else {
                console.error('Unexpected response:', response.data);
                alert(`Failed to import row data: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error importing row data:', error);
            alert('An error occurred while importing row data.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
  }, [
        isSubmitting,
        rowName,
        selectedDriver,
        currentModel,
        calculateSumForPeriod,
        processedData,
        forecastInputs,
        spreadsheets,
        isHistorical,
        historicalAmounts, // Added historicalAmounts as it's used in overwriteForecast
        forecastAmounts,    // Added forecastAmounts as it's used in overwriteForecast
        calculatedHistoricalValues, // For "Formula of Rows" driver
        calculatedForecastValues,  // For "Formula of Rows" driver
        selectedCategoryValue,
        selectedCategory,
        selectedVariable,
        selectedDateColumn,
        selectedSpreadsheet,
        selectedReferenceRow,
        onRowAdded,
        onClose,
        getPeriodsToLookBack, // Ensure getPeriodsToLookBack is included in dependencies
        selectedPeriodOption,  // Include selectedPeriodOption in dependencies
        sumFormulaInput,       // Include sumFormulaInput
        sumValidationMessage,  // Include sumValidationMessage
    ]);


  const handleSaveChanges = useCallback(async () => {
    console.log('handleSaveChanges called');
    if (onSave && rowData) {
      // Clone the existing historical and forecast data from rowData
      let existingHistoricalData = [...rowData.historicalData];
      let existingForecastData = [...rowData.forecastData];
      let existingForecastValues = [...rowData.forecastValues];
  
      console.log('rowdata: ', rowData);
  
      // Determine whether to use updated state variables or existing rowData values
      const driver = isEditingSetup ? selectedDriver : rowData.driver;
      console.log('driver: ', driver);
      const category = isEditingSetup ? selectedCategory : rowData.selectedCategory;
      console.log('category: ', category);
      const categoryValue = isEditingSetup ? selectedCategoryValue : rowData.selectedCategoryValue;
      const groupedOver = isEditingSetup ? selectedVariable : rowData.selectedVariable;
      const dateColumn = isEditingSetup ? selectedDateColumn : rowData.selectedDateColumn;
      const sourceFile = isEditingSetup
        ? isHistorical
          ? [
              [
                selectedSpreadsheet,
                spreadsheets.find((s) => s.id === selectedSpreadsheet)?.suborganisation || '',
                spreadsheets.find((s) => s.id === selectedSpreadsheet)?.newName || '',
              ],
            ]
          : ['None']
        : rowData.sourceFile;
  
      if (isHistorical) {
        // Update historical values based on user input
        const updatedHistoricalValues = Object.values(historicalAmounts).map(v => String(v));
        const startHistIndex = existingHistoricalData.length - currentModel.histPeriods;
        for (let i = 0; i < updatedHistoricalValues.length; i++) {
          existingHistoricalData[startHistIndex + i] = updatedHistoricalValues[i];
        }
      } else {
        // When not historical, pad historical data with ""
        switch (currentModel.basis) {
          case 'Yearly':
            existingHistoricalData = Array(currentModel.histPeriods).fill('0');
            break;
          case 'Quarterly':
            existingHistoricalData = Array(currentModel.histPeriods).fill('');
            break;
          case 'Monthly':
            existingHistoricalData = Array(currentModel.histPeriods).fill('');
            break;
          default:
            console.error('Invalid model basis');
            existingHistoricalData = [];
        }
      }
  
      // Initialize placeholders for updatedForecastRows and updatedForecastValues
      let updatedForecastRows = [];
      let updatedForecastValues = [];
  
      // Handle different drivers
      if (Array.isArray(driver)) {
        const [driverType, driverValue] = driver;
  
        switch (driverType) {
          case 'Formula of Rows':
            if (!sumFormulaInput || sumFormulaInput.trim() === '') {
              console.error('Formula is required for "Formula of Rows" driver.');
              alert('Please enter a formula for "Formula of Rows" driver.');
              return; // Exit early if formula is not provided
            }
            if (sumValidationMessage !== "Formula is valid!") {
              alert('Please ensure the Formula of Rows formula is valid before saving.');
              return;
            }
            // Use calculatedForecastValues which are derived from the validated formula
            updatedForecastRows = Object.values(calculatedForecastValues).map(v => String(v));
            // Depending on your backend needs, you might not need to send foreValues for Formula of Rows
            updatedForecastValues = []; // Or set to null/empty array if not needed
            break;
  
          case 'Percent of Another Row':
            if (!selectedReferenceRow) {
              console.error('Selected Reference Row is required for "Percent of Another Row" driver.');
              alert('Please select a reference row for "Percent of Another Row" driver.');
              return; // Exit early if reference row is not selected
            }
            // Use forecastInputs as foreValues
            updatedForecastValues = Object.values(forecastInputs).map(inputValue => String(inputValue));
            // Use calculatedForecastValues for foreRows
            updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
            break;
  
          default:
            console.error('Unknown driver type:', driverType);
            alert('Unknown driver type. Please check your selections.');
            return;
        }
      } else {
        // Handle standard drivers: 'Fixed Amount', 'Fixed Growth', 'Percent Increase'
        switch (driver) {
          case 'Fixed Amount':
            updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
            updatedForecastValues = Object.values(forecastAmounts).map(v => String(v));
            break;
          case 'Fixed Growth':
          case 'Percent Increase':
            updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
            updatedForecastValues = Object.values(forecastInputs).map(v => String(v));
            break;
          default:
            console.error('Unknown driver:', driver);
            alert('Unknown driver type. Please check your selections.');
            return;
        }
      }
  
      // Replace the forecast data with new values
      if (updatedForecastValues.length > 0) {
        for (let i = 0; i < updatedForecastValues.length; i++) {
          existingForecastValues[i] = updatedForecastValues[i];
        }
      }
  
      if (updatedForecastRows.length > 0) {
        for (let i = 0; i < updatedForecastRows.length; i++) {
          existingForecastData[i] = updatedForecastRows[i];
        }
      }
  
      // **Format the driver field based on its type**
      let formattedDriver;
      console.log('Selected Driver:', selectedDriver);
  
      if (Array.isArray(driver)) {
        // Driver is already in the correct format (array)
        formattedDriver = driver;
      } else {
        // For standard drivers, keep as string
        formattedDriver = driver;
      }
  
      // **Prepare the updated row data to be sent**
      const updatedData = {
        rowIndex,
        rowName, // Include updated row name
        driver: formattedDriver, // Updated driver: string or list
        category: category, // Updated category
        categoryValue: categoryValue, // Updated category value
        groupedOver: groupedOver, // Updated grouped over
        dateColumn: dateColumn, // Updated date column
        sourceFile: sourceFile,
        updatedHistoricalValues: existingHistoricalData,
        updatedForecastValues: existingForecastValues,
        updatedForecastRows: existingForecastData, // Include foreRows
        basis: currentModel.basis,
      };
  
      console.log('Submitting updated row data:', updatedData);
      await onSave(updatedData);
    }
  }, [
    onSave,
    rowIndex,
    historicalAmounts,
    forecastAmounts,
    forecastInputs,
    rowName,
    selectedDriver,
    selectedCategory,
    selectedCategoryValue,
    selectedVariable,
    selectedDateColumn,
    selectedReferenceRow,
    selectedSpreadsheet,
    spreadsheets,
    isHistorical,
    rowData,
    currentModel.histPeriods,
    currentModel.forePeriods,
    currentModel.basis,
    sumFormulaInput, // Added dependency for sumFormulaInput
    sumValidationMessage,
    calculatedForecastValues,
    forecastAmounts,
  ]);    


  return (
    <div className="popup-content">
      {/* Close Button */}
      <button className="popup-close" onClick={onClose}>
        ×
      </button>
  
      {/* Header Right Section */}
      <div className="popup-header-right">
        {/* Conditionally render Checkbox and Toggle Button */}
        {(!isEditing || isEditingSetup) && (
          <>
            {/* Toggle button for single/consolidated spreadsheet 
            {isHistorical && (
              <button
                type="button"
                onClick={handleToggleSpreadsheetMode}
                className="edit-setup-button"
              >
                {isConsolidated
                  ? 'Based on consolidated spreadsheets'
                  : 'Based on single spreadsheet'}
              </button>
            )}*/}
          </>
        )}

        {/* Delete Row button (always visible when editing) */}
        {isEditing && (
          <button type="button" onClick={handleDeleteRow} className="edit-setup-button">
            Delete Row
          </button>
        )}
      </div>
  
      {/* Popup Title */}
      <h2>{isEditing ? 'Edit Row' : 'Add Simplified Row'}</h2>
  
      {/* Form Section */}
      <div>
      <div>
        <label htmlFor="row-name" style={{ flexGrow: 1 }}>Row Name:</label>
        <RowNameAutosuggest
          numericalColumns={numericalColumns}
          onSelect={handleRowNameSelect}
          inputValue={rowName}
          setInputValue={(value) => {
            setRowName(value);
            if (!value) {
              setIsHistorical(false);
              setSelectedSpreadsheet('');
              setSelectedVariable('');
            }
          }}
          selectedDriver={selectedDriver}
        />
        {isHistorical && (
          <button
            type="button"
            onClick={() => {
              setIsHistorical(false);
              setRowName('');
              setSelectedSpreadsheet('');
              setSelectedVariable('');
            }}
            className="disconnect-button"
            style={{ marginLeft: '10px' }}
          >
            Disconnect
          </button>
        )}
      </div>
  
        {/* "Edit Setup Options" Button */}
        {isEditing && isHistorical && (
          <button
            type="button"
            onClick={() => setIsEditingSetup((prev) => !prev)}
            className="edit-setup-button"
          >
            {isEditingSetup ? 'Cancel Setup Editing' : 'Edit Setup Options'}
          </button>
        )}

        {/* Render Setup Options */}
        {(!isEditing || isEditingSetup) && (
          <>
            {/* Conditionally render Formula Input and Validate Button for "Formula of Rows" */}
            {selectedDriver === 'Formula of Rows' && (
              <div className="formula-validation-section">
                <div>
                  <label htmlFor="sum-formula-input">Enter Formula:</label>
                  <input
                    id="sum-formula-input"
                    type="text"
                    value={sumFormulaInput}
                    onChange={(e) => {
                      setSumFormulaInput(e.target.value);
                      setSumValidationMessage(null); // Hide validation message when editing
                      setSumFormulaToValidate(''); // Reset the formula to validate
                    }}
                    placeholder="'row1'+'row2'-'row3'"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setSumFormulaToValidate(sumFormulaInput)}
                  className="validate-formula-button"
                >
                  Validate Formula
                </button>
                {sumValidationMessage && (
                  <p className={`validation-message ${sumValidationMessage.startsWith('Error') ? 'error' : 'success'}`}>
                    {sumValidationMessage}
                  </p>
                )}

                {/* Conditionally render FormulaValidator only if sumFormulaToValidate is not empty */}
                {sumFormulaToValidate && (
                  <FormulaValidator
                    formula={sumFormulaToValidate}
                    columnData={currentModel.rowNames.map((name) => ({ name, status: 'Numeric' }))}
                    onValidationResult={handleValidationResult}
                  />
                )}
              </div>
            )}
            
            {/* Conditionally render Formula Input and Validate Button for "Custom Driver" */}
            {selectedDriver === 'Custom Driver' && (
              <div className="custom-driver-formula-section">
                <div>
                  <label htmlFor="custom-driver-formula">Enter Custom Formula:</label>
                  <input
                    id="custom-driver-formula"
                    type="text"
                    value={customDriverFormula}
                    onChange={(e) => {
                      setCustomDriverFormula(e.target.value);
                      setCustomDriverValidationMessage(null); // Reset validation message on change
                      setCustomFormulaToValidate(''); // Reset the formula to validate
                    }}
                    placeholder="'row1'+'row2'-'row3'"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setCustomFormulaToValidate(customDriverFormula)}
                  className="validate-formula-button"
                >
                  Validate Formula
                </button>
                {customDriverValidationMessage && (
                  <p className={`validation-message ${customDriverValidationMessage.startsWith('Error') ? 'error' : 'success'}`}>
                    {customDriverValidationMessage}
                  </p>
                )}

                {/* Conditionally render FormulaValidatorCustomDriver for "Custom Driver" */}
                {customFormulaToValidate && (
                  <FormulaValidatorCustomDriver
                    formula={customFormulaToValidate}
                    onValidationResult={handleCustomDriverValidationResult}
                  />
                )}
              </div>
            )}
          </>
        )}
  
        {/* Conditionally Render Spreadsheet Selector */}
        {isHistorical && (!isEditing || isEditingSetup) && (
          <>
            {/* Conditionally render SpreadsheetSelector for other drivers */}
            {selectedDriver !== 'Formula of Rows' && (
              <SpreadsheetSelector
                spreadsheets={spreadsheets}
                selectedSpreadsheet={selectedSpreadsheet}
                setSelectedSpreadsheet={setSelectedSpreadsheet}
                selectedSpreadsheets={selectedSpreadsheets}
                setSelectedSpreadsheets={setSelectedSpreadsheets}
                isConsolidated={isConsolidated}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedVariable={selectedVariable}
                setSelectedVariable={setSelectedVariable}
                selectedDateColumn={selectedDateColumn}
                setSelectedDateColumn={setSelectedDateColumn}
                categoryValues={categoryValues}
                setCategoryValues={setCategoryValues}
                selectedCategoryValue={selectedCategoryValue}
                setSelectedCategoryValue={setSelectedCategoryValue}
                handleCategoryValueChange={handleCategoryValueChange}
                fetchSpreadsheetData={loadSpreadsheetData}
                processedData={processedData}
                currentOrgId={currentModel.organisationId}
                isEditable={!isEditing || isEditingSetup} // New prop
              />
            )}
          </>
        )}
      </div>
  
      {/* Table Section */}
      <div className="popup-table-container">
        <table className="popup-table">
          <thead>
            <tr>
              {generateTableHeaders().map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
  
      {/* Conditionally render either the "Import" or "Save" button */}
      {!isEditing ? (
        <button
          className="popup-save"
          onClick={handleImportRowData}
          disabled={
            (selectedDriver === 'Formula of Rows' && sumValidationMessage !== "Formula is valid!") ||
            (selectedDriver === 'Formula of Rows' && sumFormulaInput.trim() === "")
          }
        >
          Import Row Data
        </button>
      ) : (
        <button
          className="popup-save"
          onClick={handleSaveChanges}
          disabled={
            (selectedDriver === 'Formula of Rows' && sumValidationMessage !== "Formula is valid!") ||
            (selectedDriver === 'Formula of Rows' && sumFormulaInput.trim() === "")
          }
        >
          Save
        </button>
      )}
    </div>
  );  
};

export default AddRowForm;