import { create, all } from 'mathjs';

const math = create(all, {});

// Helper to replace IF statements with ternary operators
const replaceIF = (formula) => {
  return formula.replace(/IF\s*\(([^,]+),([^,]+),([^)]+)\)/gi, (match, condition, valueIfTrue, valueIfFalse) => {
    // Replace '=' with '===' and '<>' with '!=='
    let jsCondition = condition.trim().replace(/([^<>=]+)=([^=]+)/g, '$1 === $2').replace(/<>/g, '!==');
    return `(${jsCondition} ? ${valueIfTrue.trim()} : ${valueIfFalse.trim()})`;
  });
};

// Helper to replace logical operators AND, OR with &&, ||
const replaceLogicalOperators = (formula) => {
  return formula.replace(/AND/gi, '&&').replace(/OR/gi, '||');
};

// Helper function to escape special regex characters in a string
const escapeRegExp = (string) => {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

/**
 * Evaluates a formula for custom driver rows based on other rows' forecasted values.
 *
 * @param {string} formula - The formula string to evaluate (e.g., "'Row1' * 'Row2' + PP").
 * @param {Array} customDriverRows - The array of custom driver rows.
 * @param {string} currentPeriod - The label of the current forecasting period (e.g., '2024-Q1').
 * @param {Array} combinedPeriods - Array of all period labels in chronological order.
 * @param {number} histPeriods - Number of historical periods.
 * @returns {number|string} - The evaluated result or an error message.
 */
export const evaluateCustomDriverFormula = (formula, customDriverRows, currentPeriod, combinedPeriods, histPeriods) => {
  console.log('Original Formula:', formula);

  // Replace IF statements with ternary operators
  let processedFormula = replaceIF(formula);
  console.log('After replaceIF:', processedFormula);

  // Replace logical operators AND, OR with &&, ||
  processedFormula = replaceLogicalOperators(processedFormula);
  console.log('After replaceLogicalOperators:', processedFormula);

  // Handle 'PP' special expression
  const ppRegex = /\bPP\b/g; // Matches 'PP' as a whole word
  if (ppRegex.test(processedFormula)) {
    const currentIndex = combinedPeriods.indexOf(currentPeriod);
    const previousIndex = currentIndex - 1;

    let ppValue = 0;

    if (previousIndex >= 0) {
      if (previousIndex < histPeriods) {
        // Previous period is historical
        const lastHistoricalPeriod = combinedPeriods[histPeriods - 1];
        const formulaRow = customDriverRows.find(r => r.driverType === 'Formula');
        if (formulaRow) {
          const historicalValue = formulaRow.historicalValues[lastHistoricalPeriod];
          ppValue = parseFloat(historicalValue) || 0;
        } else {
          console.warn('Formula row not found in customDriverRows.');
        }
      } else {
        // Previous period is forecast
        const previousPeriod = combinedPeriods[previousIndex];
        const formulaRow = customDriverRows.find(r => r.driverType === 'Formula');
        if (formulaRow) {
          const previousValue = formulaRow.forecastResults[previousPeriod];
          ppValue = parseFloat(previousValue) || 0;
        } else {
          console.warn('Formula row not found in customDriverRows.');
        }
      }
    } else {
      console.warn('No previous period found for PP.');
    }

    // Replace all occurrences of 'PP' with ppValue
    processedFormula = processedFormula.replace(ppRegex, ppValue);
    console.log(`After replacing 'PP' with ${ppValue}:`, processedFormula);
  }

  // Extract row names enclosed in single quotes
  const rowNames = processedFormula.match(/'([^']+)'/g)?.map(name => name.replace(/'/g, '')) || [];
  console.log('Row Names found:', rowNames);

  // Create a mapping of row names to their forecasted values for the current period
  const rowData = {};

  rowNames.forEach(rowName => {
    // Find the corresponding row in customDriverRows
    const row = customDriverRows.find(r => r.rowName === rowName && r.driverType !== 'Formula');
    if (!row) {
      throw new Error(`Row "${rowName}" does not exist or is a Formula row.`);
    }

    let value = 0;
    if (row.driverType === 'Fixed Percent') {
      value = parseFloat(row.forecastResults[currentPeriod]) / 100;
    } else {
      value = parseFloat(row.forecastResults[currentPeriod]);
    }
    
    if (isNaN(value)) {
      throw new Error(`Invalid numeric value for row "${rowName}" in period "${currentPeriod}".`);
    }

    rowData[rowName] = value;
  });

  // Replace row names in the formula with their corresponding values
  rowNames.forEach(rowName => {
    const escapedRowName = escapeRegExp(rowName);
    const regex = new RegExp(`'${escapedRowName}'`, 'g');
    processedFormula = processedFormula.replace(regex, rowData[rowName]);
    console.log(`Replaced '${rowName}' with ${rowData[rowName]}:`, processedFormula);
  });

  console.log('Final Processed Formula:', processedFormula);

  try {
    const result = math.evaluate(processedFormula);
    console.log('Evaluation Result:', result);
    return result;
  } catch (error) {
    console.error('Evaluation Error:', error);
    return 'Error';
  }
};
