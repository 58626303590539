import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faPen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './authcontext';
import FormulaValidator from './formulavalidator';
import api from './api'; // Importing the api for server communication
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CustomColumnsPage = () => {
  const { spreadsheets, currentOrg } = useAuth();
  const [selectedSpreadsheets, setSelectedSpreadsheets] = useState([]);
  const [expandedSuborgs, setExpandedSuborgs] = useState({});
  const [overlappingColumns, setOverlappingColumns] = useState([]);
  const [columnName, setColumnName] = useState(''); // State to store the new column name
  const [formula, setFormula] = useState(''); // State to store the formula input
  const [validationMessage, setValidationMessage] = useState(''); // State to display validation result
  const [runValidation, setRunValidation] = useState(false); // Trigger for validation
  const [columnData, setColumnData] = useState([]); // Store both column names and statuses for validation
  const [nameConflict, setNameConflict] = useState([]); // Track spreadsheets where the column name exists
  const [formulaAlreadyExists, setFormulaAlreadyExists] = useState([]); // Track spreadsheets where formula exists
  const [formulaWillBeAdded, setFormulaWillBeAdded] = useState([]); // Track spreadsheets where formula will be added
  const [showConfirm, setShowConfirm] = useState(false); // Show confirmation modal
  const [existingCustomColumns, setExistingCustomColumns] = useState([]); // Track all existing custom columns
  const [editingColumn, setEditingColumn] = useState(null); // Track which column name is being edited
  const [editingFormula, setEditingFormula] = useState(null); // Track which formula is being edited
  const [newColumnName, setNewColumnName] = useState('');
  const [newFormula, setNewFormula] = useState('');

  const navigate = useNavigate(); // Initialize navigate

  // Filter spreadsheets to only show those from the current organization
  const filteredSpreadsheets =
    currentOrg && Array.isArray(spreadsheets) && spreadsheets.length > 0
      ? spreadsheets.filter(
          (spreadsheet) => spreadsheet.organisationId === currentOrg.id
        )
      : [];


  // Group spreadsheets by suborganisation
  const groupedSpreadsheets = filteredSpreadsheets.reduce((groups, spreadsheet) => {
    const suborg = spreadsheet.suborganisation || 'No Suborganisation';
    if (!groups[suborg]) {
      groups[suborg] = [];
    }
    groups[suborg].push(spreadsheet);
    return groups;
  }, {});

  // Toggle expansion of suborganisation folders
  const toggleSuborgExpand = (suborg) => {
    setExpandedSuborgs((prevExpanded) => ({
      ...prevExpanded,
      [suborg]: !prevExpanded[suborg],
    }));
  };

  // Handle checkbox change for selecting spreadsheets
  const handleSpreadsheetSelection = (spreadsheetId) => {
    setSelectedSpreadsheets((prevSelected) => {
      const updatedSelected = prevSelected.includes(spreadsheetId)
        ? prevSelected.filter((id) => id !== spreadsheetId)
        : [...prevSelected, spreadsheetId];
      return updatedSelected;
    });
  };

  // Find overlapping columns across all selected spreadsheets based on columnStatus
  useEffect(() => {
    if (selectedSpreadsheets.length > 0) {
      const selectedSheets = filteredSpreadsheets.filter(sheet => selectedSpreadsheets.includes(sheet.id));

      // Get the editedHeaders of columns with a columnStatus of 'Date', 'NumericalEditable', 'CategoricalEditable', or 'Categorical'
      const headersList = selectedSheets.map(sheet =>
        sheet.editedHeaders.filter((_, index) =>
          ['PrimaryDate', 'SecondaryDate', 'NumericalEditable', 'CategoricalEditable', 'Categorical'].includes(sheet.columnStatus[index])
        )
      );

      // Find the intersection of all valid headers
      const intersectedColumns = headersList.reduce((commonHeaders, headers) => {
        return commonHeaders.filter(header => headers.includes(header));
      });

      setOverlappingColumns(intersectedColumns);

      // Map column names and their statuses for validation
      const columnsWithStatus = selectedSheets.flatMap(sheet =>
        sheet.editedHeaders.map((header, index) => ({
          name: header,
          status: sheet.columnStatus[index]
        }))
      );
      setColumnData(columnsWithStatus);
    } else {
      setOverlappingColumns([]); // Reset if no spreadsheets are selected
      setColumnData([]); // Reset column data for validation
    }
  }, [selectedSpreadsheets, filteredSpreadsheets]);

// Function to handle the creation of a new custom column
const handleCreateNewColumn = () => {
  // Check if columnName or formula is empty before proceeding
  if (!columnName.trim() || !formula.trim()) {
    setValidationMessage('Column name and formula cannot be empty.');
    return; // Stop further execution if inputs are empty
  }

  setRunValidation(true); // Trigger the validation
  setValidationMessage(''); // Clear previous validation messages

  // Check which spreadsheets have a column with the same name (either an editable header or a custom column)
  const nameConflicts = [];
  const formulaConflicts = [];
  const toAdd = [];

  for (const spreadsheetId of selectedSpreadsheets) {
    const spreadsheet = filteredSpreadsheets.find(sheet => sheet.id === spreadsheetId);
    const currentCustomColumns = spreadsheet.customColumns || [];
    const editableHeaders = spreadsheet.editedHeaders || [];

    // Check if the column name already exists in either the editable headers or custom column names
    const nameConflictExists = editableHeaders.includes(columnName) || currentCustomColumns.some(([existingColumn]) => existingColumn === columnName);

    if (nameConflictExists) {
      nameConflicts.push(spreadsheet.newName);
    } else if (currentCustomColumns.some(([, existingFormula]) => existingFormula === formula)) {
      formulaConflicts.push(spreadsheet.newName);
    } else {
      toAdd.push(spreadsheet.newName);
    }
  }

  setNameConflict(nameConflicts);
  setFormulaAlreadyExists(formulaConflicts);
  setFormulaWillBeAdded(toAdd);

  // If the column name already exists in all selected spreadsheets, show a message and prevent further action
  if (nameConflicts.length === selectedSpreadsheets.length) {
    setValidationMessage('The column name already exists in all selected spreadsheets.');
    return;
  }

  // If there are name conflicts but the user wants to proceed, show a confirmation modal
  if (nameConflicts.length > 0) {
    setValidationMessage(`The column name already exists in the following spreadsheets: ${nameConflicts.join(', ')}. Do you want to proceed with the remaining spreadsheets?`);
    setShowConfirm(true); // Show confirmation modal for user to decide if they want to proceed
  }
  // If there are no name conflicts, proceed directly
  else {
    proceedWithAddingFormula(); // Automatically add the formula to the selected spreadsheets
  }
};

// Disable the "Create New Column" button if either the columnName or formula is empty
const isCreateDisabled = !columnName.trim() || !formula.trim();


  const proceedWithAddingFormula = async () => {
    setShowConfirm(false); // Hide the modal
    try {
      const token = localStorage.getItem('token');
      for (const spreadsheetId of selectedSpreadsheets) {
        const spreadsheet = filteredSpreadsheets.find(sheet => sheet.id === spreadsheetId);
        const currentCustomColumns = spreadsheet.customColumns || [];

        // Skip if the column name already exists in this spreadsheet
        if (spreadsheet.editedHeaders.includes(columnName) || currentCustomColumns.some(([existingColumn]) => existingColumn === columnName)) {
          continue;
        }

        // Append the new column name and formula if the name doesn't already exist
        const updatedCustomColumns = [...currentCustomColumns, [columnName, formula]];

        // Make an API call to update the spreadsheet's customColumns
        await api.put(
          `/spreadsheets/${spreadsheetId}/customcolumns`,
          { customColumns: updatedCustomColumns },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }

      setValidationMessage('New column successfully created!');
      // Refresh the page after success
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setValidationMessage('Error updating custom columns: ' + error.message);
    }
  };

  // Callback to handle validation result from FormulaValidator
  const handleValidationResult = (error, correctedFormula) => {
    if (error) {
      setValidationMessage(error);
    } else {
      setFormula(correctedFormula); // Update formula with corrected parentheses and keyword case
      setValidationMessage('');
    }
    setRunValidation(false); // Reset validation trigger after it's done
  };

// Gather all existing custom columns and the spreadsheets they exist in
useEffect(() => {
  const allCustomColumns = {};
  
  // Collect both names and ids of spreadsheets where custom columns exist
  filteredSpreadsheets.forEach((spreadsheet) => {
    const customColumns = spreadsheet.customColumns || [];
    customColumns.forEach(([columnName, formula]) => {
      const key = `${columnName}:${formula}`;
      if (!allCustomColumns[key]) {
        allCustomColumns[key] = {
          name: columnName,
          formula: formula,
          spreadsheets: [{ name: spreadsheet.newName, id: spreadsheet.id }], // Store both name and id
        };
      } else {
        allCustomColumns[key].spreadsheets.push({ name: spreadsheet.newName, id: spreadsheet.id });
      }
    });
  });
  
  // Convert the object into an array for easier rendering
  setExistingCustomColumns(Object.values(allCustomColumns));
}, [filteredSpreadsheets]);

// Function to update column name
const handleUpdateColumnName = async (originalName, newName, formula, spreadsheetsUsedIn) => {

  if (!newName.trim()) {
    setValidationMessage('Column name cannot be empty.');
    return; // Prevent update if the new column name is empty
  }

  try {
    const token = localStorage.getItem('token');

    // Log the incoming `spreadsheetsUsedIn` array (from the row "Used in Spreadsheets")
    console.log('Incoming spreadsheets for column update:', spreadsheetsUsedIn);

    // Log the context spreadsheets to debug the full list
    console.log('Context spreadsheets:', spreadsheets);

    // Map the spreadsheet names to the actual spreadsheet objects from the context so we can get their IDs
    const spreadsheetIds = spreadsheetsUsedIn.map(sheetObj => {
      console.log('Mapping spreadsheet:', sheetObj);  // Log each spreadsheet object from the row
      const spreadsheet = filteredSpreadsheets.find(s => s.newName === sheetObj.name);
      if (spreadsheet) {
        console.log(`Found spreadsheet ID: ${spreadsheet.id} for ${sheetObj.name}`);
        return spreadsheet.id;
      } else {
        console.warn(`No spreadsheet found for name: ${sheetObj.name}`);
        return null;
      }
    }).filter(id => id !== null); // Ensure no null values

    // Log the final `spreadsheetIds` array
    console.log('Final spreadsheet IDs:', spreadsheetIds);

    // Check if `spreadsheetIds` is still empty
    if (spreadsheetIds.length === 0) {
      console.error('No spreadsheet IDs were found. Aborting update.');
      return;
    }

    // Log the data being sent in the API request
    console.log('Updating column name with data:', { originalName, newName, formula, spreadsheetIds });

    await api.put(
      `/customcolumns/update_name`,
      {
        originalName,
        newName,
        formula,
        spreadsheetIds, // Send spreadsheet IDs
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setEditingColumn(null);
    setNewColumnName('');
    console.log('Column name updated successfully!');
    window.location.reload()
  } catch (error) {
    console.error('Error updating column name:', error);
  }
};

// Function to update the formula
const handleUpdateFormula = async (columnName, newFormula, spreadsheetsUsedIn) => {

  if (!newFormula.trim()) {
    setValidationMessage('Formula cannot be empty.');
    return; // Prevent update if the new formula is empty
  }

  try {
    const token = localStorage.getItem('token');

    // Log the incoming `spreadsheetsUsedIn` array (from the row "Used in Spreadsheets")
    console.log('Incoming spreadsheets for formula update:', spreadsheetsUsedIn);

    // Log the context spreadsheets to debug the full list
    console.log('Context spreadsheets:', spreadsheets);

    // Map the spreadsheet names to the actual spreadsheet objects from the context so we can get their IDs
    const spreadsheetIds = spreadsheetsUsedIn.map(sheetObj => {
      console.log('Mapping spreadsheet:', sheetObj);  // Log each spreadsheet object from the row
      const spreadsheet = filteredSpreadsheets.find(s => s.newName === sheetObj.name);
      if (spreadsheet) {
        console.log(`Found spreadsheet ID: ${spreadsheet.id} for ${sheetObj.name}`);
        return spreadsheet.id;
      } else {
        console.warn(`No spreadsheet found for name: ${sheetObj.name}`);
        return null;
      }
    }).filter(id => id !== null); // Ensure no null values

    // Log the final `spreadsheetIds` array
    console.log('Final spreadsheet IDs for formula update:', spreadsheetIds);

    // Check if `spreadsheetIds` is still empty
    if (spreadsheetIds.length === 0) {
      console.error('No spreadsheet IDs were found. Aborting formula update.');
      return;
    }

    // Log the data being sent in the API request
    console.log('Updating formula with data:', { columnName, newFormula, spreadsheetIds });

    await api.put(
      `/customcolumns/update_formula`,
      {
        columnName,
        newFormula,
        spreadsheetIds, // Send spreadsheet IDs
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setEditingFormula(null);
    setNewFormula('');
    console.log('Formula updated successfully!');
    window.location.reload()
  } catch (error) {
    console.error('Error updating formula:', error);
  }
};

  return (
    <div className="custom-columns-page">
      {currentOrg ? (
      filteredSpreadsheets.length > 0 ? (
        <div>
      <h3>Select Spreadsheets to Create Custom Columns</h3>
      <div>
        {Object.keys(groupedSpreadsheets).map((suborg) => (
          <div key={suborg}>
            <div onClick={() => toggleSuborgExpand(suborg)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
              <FontAwesomeIcon icon={expandedSuborgs[suborg] ? faChevronDown : faChevronRight} style={{ marginRight: '8px' }} />
              {suborg}
            </div>
            {expandedSuborgs[suborg] && (
              <div style={{ marginLeft: '20px' }}>
                {groupedSpreadsheets[suborg].map((spreadsheet) => (
                  <div key={spreadsheet.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedSpreadsheets.includes(spreadsheet.id)}
                        onChange={() => handleSpreadsheetSelection(spreadsheet.id)}
                      />
                      {spreadsheet.newName}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
  
      <div>
        <h3>Overlapping Columns</h3>
        {overlappingColumns.length > 0 ? (
          <ul>
            {overlappingColumns.map((column, index) => (
              <li key={index}>{column}</li>
            ))}
          </ul>
        ) : (
          <p>No overlapping columns selected.</p>
        )}
      </div>
  
      {/* Input box for the new column name */}
      <div>
        <h3>Enter New Column Name</h3>
        <input
          type="text"
          value={columnName}
          onChange={(e) => setColumnName(e.target.value)}
          placeholder="Enter new column name here"
        />
      </div>
  
      {/* Input box for the formula */}
      <div>
        <h3>Enter Formula</h3>
        <input
          type="text"
          value={formula}
          onChange={(e) => setFormula(e.target.value)}
          placeholder="Enter formula here"
        />
        {/* Button to run the validation and create new column */}
        <button onClick={handleCreateNewColumn}>Create New Column</button>
      </div>
  
      {/* FormulaValidator Component to validate formula */}
      {runValidation && (
        <FormulaValidator
          formula={formula}
          columnData={columnData} // Pass column names and statuses
          onValidationResult={handleValidationResult}
        />
      )}
  
      {/* Display validation result */}
      <div>
        <h3>Formula Validation Result</h3>
        <p>{validationMessage}</p>
      </div>
  
      {/* Confirmation Modal */}
      {showConfirm && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Formula Addition</h3>
            {nameConflict.length > 0 && (
              <p>The column name already exists for the following spreadsheets:</p>
            )}
            <ul>
              {nameConflict.map((spreadsheet, index) => (
                <li key={index}>{spreadsheet}</li>
              ))}
            </ul>
            {formulaAlreadyExists.length > 0 && (
              <p>The formula already exists for the following spreadsheets:</p>
            )}
            <ul>
              {formulaAlreadyExists.map((spreadsheet, index) => (
                <li key={index}>{spreadsheet}</li>
              ))}
            </ul>
            <p>It will be added to the following spreadsheets:</p>
            <ul>
              {formulaWillBeAdded.map((spreadsheet, index) => (
                <li key={index}>{spreadsheet}</li>
              ))}
            </ul>
            <button onClick={proceedWithAddingFormula}>Proceed</button>
            <button onClick={() => setShowConfirm(false)}>Cancel</button>
          </div>
        </div>
      )}
  
{/* Existing Custom Columns Table */}
<div>
  <h3>Existing Custom Columns</h3>
  <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
    <thead>
      <tr>
        <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Column Name</th>
        <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Formula</th>
        <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f2f2f2' }}>Used in Spreadsheets</th>
      </tr>
    </thead>
    <tbody>
      {existingCustomColumns.length > 0 ? (
        existingCustomColumns.map((customColumn, index) => (
          <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
  {editingColumn === `${customColumn.name}-${index}` ? (
    <>
      <input
        type="text"
        value={newColumnName}
        onChange={(e) => setNewColumnName(e.target.value)}
      />
      <FontAwesomeIcon
        icon={faCheck}
        style={{ cursor: 'pointer', color: 'green', marginLeft: '10px' }}
        onClick={() => handleUpdateColumnName(customColumn.name, newColumnName, customColumn.formula, customColumn.spreadsheets)}
      />
    </>
  ) : (
    <>
      {customColumn.name}
      <FontAwesomeIcon
        icon={faPen}
        style={{ cursor: 'pointer', marginLeft: '10px' }}
        onClick={() => {
          setEditingColumn(`${customColumn.name}-${index}`);
          setNewColumnName(customColumn.name);
        }}
      />
    </>
  )}
</td>

<td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
  {editingFormula === `${customColumn.name}-${index}` ? (
    <>
      <input
        type="text"
        value={newFormula}
        onChange={(e) => setNewFormula(e.target.value)}
      />
      <FontAwesomeIcon
        icon={faCheck}
        style={{ cursor: 'pointer', color: 'green', marginLeft: '10px' }}
        onClick={() => handleUpdateFormula(customColumn.name, newFormula, customColumn.spreadsheets)}
      />
    </>
  ) : (
    <>
      {customColumn.formula}
      <FontAwesomeIcon
        icon={faPen}
        style={{ cursor: 'pointer', marginLeft: '10px' }}
        onClick={() => {
          setEditingFormula(`${customColumn.name}-${index}`);
          setNewFormula(customColumn.formula);
        }}
      />
    </>
  )}
</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
              {/* Map over spreadsheets and display names */}
              {customColumn.spreadsheets.map((spreadsheet, i) => (
                <span key={i}>
                  {spreadsheet.name}
                  {i < customColumn.spreadsheets.length - 1 && ', '}
                </span>
              ))}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="3" style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>No custom columns available.</td>
        </tr>
      )}
    </tbody>
  </table>
</div></div>
      ) : (
        <div>
          <p>No spreadsheets available for the current organisation.</p>
          <button onClick={() => navigate('/datainput')}>
            Add Your First Spreadsheet
          </button>
        </div>
      )
    ) : (
      <p>Please select an organisation.</p>
    )}
    </div>
  );  
};

export default CustomColumnsPage;
